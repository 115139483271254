import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IAppRuleKeys {
  app: {};
}

export type IAppRules = IRuleFn<IAppRuleKeys>;

const appRuleFn: IAppRules = props => {
  const { appMaxWidth } = props.theme;

  return {
    app: {
      maxWidth: appMaxWidth,
      margin: '0 auto',
      height: 'inherit',
    },
  };
};

export const appRules = createCachedStyle(appRuleFn);
