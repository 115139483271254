import { PromiseClient } from '@connectrpc/connect/dist/cjs/promise-client';
import { PortClientService } from '@teleport/schemas-protobuf';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { createPromiseClient } from '@connectrpc/connect';
import { statusCodeInterceptor } from './interceptors/statusCodeInterceptor';
import { headersAuthInterceptor } from './interceptors/headersAuthInterceptor';
import { isValidUrl } from '../../utils/url/isValidUrl';

export interface IPortClientApi {
  (): PromiseClient<typeof PortClientService>;
  init: (baseUrl: string) => void;
}

export class PortClientApi {
  public instance: PromiseClient<typeof PortClientService>;

  public init(baseUrl: string) {
    if (!isValidUrl(baseUrl)) {
      console.error('Invalid port client api url', baseUrl);
      return null;
    }

    const transport = createGrpcWebTransport({
      baseUrl,
      useBinaryFormat: true,
      binaryOptions: {
        readUnknownFields: true,
      },
      interceptors: [headersAuthInterceptor, statusCodeInterceptor],
    });

    this.instance = createPromiseClient(PortClientService, transport);
  }

  public call(): PromiseClient<typeof PortClientService> {
    if (this.instance === undefined) {
      console.error('Port client api is not initialized');
      return null;
    }

    return this.instance;
  }

  public static create(): IPortClientApi {
    const instance = new PortClientApi();

    return <IPortClientApi>Object.assign(() => instance.call(), {
      init: (baseUrl: string) => instance.init(baseUrl),
    });
  }
}
