import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { memo, useRef, useState } from 'react';
import Modal, { ModalNames } from '../Modal/Modal';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalColorRules } from './ModalColor.style';
import { HexColorPicker } from 'react-colorful';
import { Button } from '../UI/Button/Button';
import { Input } from '../UI/Input/Input';
import { useTheme } from 'src/utils/theme/useTheme';
import { hexRegex } from 'src/utils/hexToRgb';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface ModalColorProps {
  active: boolean;
  value?: string;
  colorPickerColor: string;
  onClose: () => void;
  chooseColor: () => void;
  setColorPickerColor: Dispatch<SetStateAction<string>>;
}

export const ModalColor = memo(function ModalColor(props: PropsWithChildren<ModalColorProps>) {
  const { active, colorPickerColor, chooseColor, setColorPickerColor, onClose } = props;
  const { theme } = useTheme();
  const [inputError, setInputError] = useState('');
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  useScrollModal(modalInnerRef);
  const { css } = useStyle(ModalColorRules, { footerHeight: modalFooterRef.current?.clientHeight });

  const {t} = useTranslation()

  const changeColorPicker = (color: string) => {
    setInputError('');
    setColorPickerColor(color);
  };

  const changeHexInput = event => {
    const value = event.currentTarget.value;
    setColorPickerColor(value);

    if (hexRegex.test(value)) {
      setInputError('');
    } else {
      setInputError('Введите цвет в формате: #0500FF');
    }
  };

  return (
    <Modal
      active={active}
      name={ModalNames.Color}
      onClose={onClose}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
    >
      <div className={css.modalInner} ref={modalInnerRef}>
        <HexColorPicker
          color={hexRegex.test(colorPickerColor) ? colorPickerColor : '#ffffff'}
          onChange={changeColorPicker}
          className={css.colorPicker}
        />
        <Input
          name="hexColor"
          type="text"
          controlled={true}
          value={colorPickerColor}
          onChange={changeHexInput}
          propsStyles={{ backgroundColor: theme.background }}
          errorMessage={inputError}
        />
        <footer className={css.footer} ref={modalFooterRef}>
          <Button
            text={t('modalColor.select')}
            propsStyles={{
              width: '100%',
            }}
            disabled={!hexRegex.test(colorPickerColor)}
            onClick={chooseColor}
          />
        </footer>
      </div>
    </Modal>
  );
});
