import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { IconInBoxRules } from './IconInBox.style';
import { Icon, IconList } from '../Icon/Icon';

interface IProps {
  propsStyles: IPropsStyles;
  name: IconList;
}

interface IPropsStyles {
  width: number;
  height: number;
}

export const IconInBox: FC<IProps> = memo(function IconInBox(props) {
  const { name, propsStyles } = props;
  const { css } = useStyle(IconInBoxRules, {
    width: propsStyles.width,
    height: propsStyles.height,
  });

  // console.log(hexToRgb("#0500ff"));

  return (
    <div className={css.iconBox}>
      <Icon name={name} />
    </div>
  );
});
