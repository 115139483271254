import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IPriceRuleKeys {
  price: {};
}

interface IPriceRuleProps {
  oldPrice: boolean;
}

export type IPriceRules = IRuleFn<IPriceRuleKeys, IPriceRuleProps>;

const priceRuleFn: IPriceRules = props => {
  const { oldPrice } = props;
  const { colorDarkGrey, colorBlack } = props.theme;

  return {
    price: {
      position: 'relative',
      padding: oldPrice ? '0px 4px' : 0,
      color: oldPrice ? colorDarkGrey : colorBlack,

      ':before': {
        content: '""',
        display: oldPrice ? 'block' : 'none',
        height: 2,
        backgroundColor: colorBlack,
        width: '100%',
        position: 'absolute',
        left: 0,
        top: '50%',
        transform: 'translateY(-50%)',
      },
    },
  };
};

export const priceRules = createCachedStyle(priceRuleFn);
