import { FC, ReactNode, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { MainPageSectionRules } from './MainPageSection.style';
import Text from 'src/components/UI/Text/Text';

interface IProps {
  title?: string;
  children: ReactNode;
}

export const MainPageSection: FC<IProps> = memo(function MainPageSection(props) {
  const { children, title } = props;
  const { css } = useStyle(MainPageSectionRules);

  return (
    <section className={css.section}>
      {title && (
        <Text
          mod="title"
          text={title}
          fontWeight={800}
          fontSize={16}
          lineHeight={18}
          extend={css.title}
        />
      )}
      <div className={css.wrapper}>{children}</div>
    </section>
  );
});
