import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from '../UI/Text/Text';
import { priceRules } from './price.style';
import { telegramStarsString } from 'src/utils/constants';
import useCurrencySymbol from 'src/hooks/useCurrencySymbol';
import { useAppSelector } from 'src/hooks/redux';
import { getWizardState } from 'src/redux/slices/wizardSlice';

interface CommonProps {
  amount: number | bigint;
  extend?: string;
  oldPrice?: boolean;
  // currency: string
}

type ConditionalProps =
  | {
      mathematicalSymbol: true;
      isPositive: boolean;
    }
  | { mathematicalSymbol: false; isPositive?: never };

type IProps = CommonProps & ConditionalProps;

export const Price: FC<IProps> = memo(function Price(props) {
  const { amount, isPositive, mathematicalSymbol, extend, oldPrice } = props;
  const { css } = useStyle(priceRules, { oldPrice });

  const {
    wizard: wizardData,
  } = useAppSelector(getWizardState);

  let symbol = '';

  if (mathematicalSymbol && isPositive) {
    symbol = '+';
  } else if (mathematicalSymbol && !isPositive) {
    symbol = '-';
  }

  const currentCurrencySymbol = useCurrencySymbol(wizardData?.currency);
  return (
    <Text
      text={`${symbol} ${amount.toLocaleString('ru')} ${currentCurrencySymbol !== telegramStarsString? currentCurrencySymbol: ''}`.trim()}
      svg={currentCurrencySymbol === telegramStarsString? currentCurrencySymbol : undefined}
      mod="text"
      fontSize={oldPrice ? 12 : 14}
      lineHeight={18}
      fontWeight={oldPrice ? 700 : 800}
      extend={[css.price, extend].join(' ')}
      whiteSpace="nowrap"
    />
  );
});
