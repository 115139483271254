import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SlidesWrapperRules } from './SlidesWrapper.style';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useModalState } from 'src/hooks/useModalState';
import { SlideCard } from '../SlideCard/SlideCard';
import { ModalSettingSlider } from '../ModalSettingSlider/ModalSettingSlider';
import { ISlide } from 'src/types/slider';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { SliderState, updateSlider } from 'src/redux/slices/sliderSlice';
import { sliderUpsert } from 'src/redux/api/slider/sliderUpsert';
// import { SliderBlock_SliderItem } from '@teleport/schemas-protobuf';

// https://docs.dndkit.com/presets/sortable - документация dragAndDrop

interface IProps {
  slides: ISlide[];
}

export const SlidesWrapper: FC<IProps> = memo(function SlidesWrapper(props) {
  const { slides } = props;
  const { css } = useStyle(SlidesWrapperRules);
  const dispatch = useAppDispatch();
  const { slider } = useAppSelector(SliderState);
  const [items, setItems] = useState(slides);

  useEffect(() => {
    setItems(slides);
  }, [slides]);

  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor));
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [editableSlide, setEditableSlide] = useState<ISlide | null>(null);

  function openEditModal(event) {
    const slideId = event.currentTarget.id;
    const slide = items.find(story => story.id === slideId);
    if (!slide) return;
    setEditableSlide(slide);
    openModal();
  }

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  function handleDragStart() {
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  // Функция изменяющая порядок элементов в нашем массиве items
  function handleDragEnd(event) {
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);
    const { active, over } = event;
    let newArr: ISlide[] = [];
    if (active.id !== over.id) {
      setItems(items => {
        const dragEl = items.find(el => el.id === active.id);
        const dropEl = items.find(el => el.id === over.id);

        const oldIndex = items.indexOf(dragEl);
        const newIndex = items.indexOf(dropEl);

        newArr = arrayMove(items, oldIndex, newIndex);

        return newArr;
      });

      // reorder-request
      const updatedSlider = { ...slider, items: newArr };
      dispatch(sliderUpsert({ slider: updatedSlider })).then(() => {
        dispatch(updateSlider(updatedSlider));
      });
    }
  }

  return (
    <div className={css.wrapper}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map(slide => (
            <SlideCard slideData={slide} key={slide.id} openEditModal={openEditModal} />
          ))}
        </SortableContext>
      </DndContext>
      {renderModal && (
        <ModalSettingSlider
          active={activeModal}
          onClose={closeModal}
          editingSlide={true}
          slide={editableSlide}
        />
      )}
    </div>
  );
});
