import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Button } from '../UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { ModalConfirmRules } from './ModalConfirm.style';
import ModalCenter, { ModalCenterNames } from '../Modal/ModalCenter';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  onClose: () => void;
  active: boolean;
  confirmAction: () => void;
  title: string;
  confirmTxt?: string;
  cencelTxt?: string;
}

const ModalConfirm = function ModalConfirm(props: IProps) {
  const { active, confirmAction, onClose, title } = props;
  const { css } = useStyle(ModalConfirmRules);
  const { theme } = useTheme();

  const { t } = useTranslation()

  return (
    <ModalCenter active={active} name={ModalCenterNames.ConfirmDelete} onClose={onClose}>
      <div>
        <h4 className={css.title}>{title}</h4>
        <div className={css.btnsWrp}>
          <Button
            text={t('modalConfirm.no')}
            propsStyles={{
              background: 'transparent',
              color: '#037EE5',
            }}
            onClick={() => onClose()}
          />
          <span className={css.separator} />
          <Button
            text={t('modalConfirm.yes')}
            propsStyles={{
              background: 'transparent',
              color: theme.colorRed,
            }}
            onClick={() => confirmAction()}
          />
        </div>
      </div>
    </ModalCenter>
  );
};

export default memo(ModalConfirm);
