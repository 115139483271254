import { FC, ReactNode, memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { modalModifierTypeRules } from './ModalUniversal.style';
import { useScrollModal } from 'src/hooks/useScrollModal';

interface IProps {
  active: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
  subtitle?: string;
  deep?: number;
}

export const ModalUniversal: FC<IProps> = memo(function ModalUniversal(props) {
  const { active, onClose, children, title, subtitle, deep } = props;

  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(modalModifierTypeRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  useScrollModal(modalInnerRef);

  return (
    <Modal
      deep={deep}
      name={ModalNames.Universal}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
      // expand={true}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={title} mod="title" />
      </div>
      <div className={css.modalInner} ref={modalInnerRef}>
        {subtitle && <p>{subtitle}</p>}
        {children}
      </div>
    </Modal>
  );
});
