import { StoriesTranslator } from 'src/redux/translators/storiesTranslator';
import { IStory } from 'src/types/stories';
import { createAppAsyncThunk } from '../../../hooks/redux';

export interface IStoryData {
  story: IStory;
  file?: File;
}

export const createStory = createAppAsyncThunk(
  'stories/createStory',
  async ({ story, file }: IStoryData, thunkAPI) => {
    let imageUrls: string[] = [];
    if (file) {
      try {
        imageUrls = await thunkAPI.extra.filesUpload([file]);
      } catch (e) {
        console.info('Upload files failed: ', e);
      }
    }

    const storyUpsertRequest = StoriesTranslator.toStoryUpsertRequest(story, imageUrls[0]);

    const { storyUuid } = await thunkAPI.extra.portOwnerApi().storyUpsert(storyUpsertRequest);

    return {
      ...story,
      imageUrl: storyUpsertRequest.story.imageUrl,
      id: storyUuid,
    };
  },
);
