import { FC, ReactNode, useEffect, useRef } from 'react';
import ReactPortal from './ReactPortal';
import { useStyle } from 'src/utils/theme/useStyle';
import { modalRules } from './modal.style';
import { useScrollBlock } from 'src/hooks/useScrollBlock';

interface IProp {
  children?: ReactNode;
  active: boolean;
  setActive?: any;
  name: ModalCenterNames;
  deep?: number;
  propsStyles?: IStyles;
  noCloseBar?: boolean;
  onClose: () => void;
}

interface IStyles {
  paddingTop?: number;
  paddingRight?: number;
  paddingLeft?: number;
  paddingBottom?: number;
  backgroundColor?: string;
}

export enum ModalCenterNames {
  ConfirmDelete = 'ConfirmDelete',
}

const ModalCenter: FC<IProp> = props => {
  const { children, active, name, deep = 1, onClose, propsStyles } = props;
  const [blockScroll, allowScroll] = useScrollBlock();
  const modalRef = useRef<HTMLDivElement>(null);
  const modalTitleRef = useRef<HTMLParagraphElement>(null);

  const { css } = useStyle(modalRules, {
    active,
    paddingTop: propsStyles?.paddingTop,
    paddingRight: propsStyles?.paddingRight,
    paddingLeft: propsStyles?.paddingLeft,
    paddingBottom: propsStyles?.paddingBottom,
    backgroundColor: propsStyles?.backgroundColor,
    titleHeight: modalTitleRef.current?.clientHeight,
    deep,
  });

  // Закрытие модалки при клике по тёмной области
  useEffect(() => {
    function handleClick(e: Event) {
      if (e.target === modalRef.current) {
        onClose();
      }
    }

    if (active) {
      blockScroll();
      document.addEventListener('click', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
      allowScroll();
    };
  }, [active, onClose, allowScroll, blockScroll]);

  return (
    <ReactPortal portalID={name}>
      <div className={css.modalCenter} ref={modalRef}>
        <div className={css.modalCenterContent}>{children}</div>
      </div>
    </ReactPortal>
  );
};

export default ModalCenter;
