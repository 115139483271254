import { FC, memo, useCallback, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalWarningAsyncChangesRules } from './ModalWarningAsyncChanges.style';
import Modal, { ModalNames } from '../Modal/Modal';
import ErrorOrSuccessInfo from '../ErrorOrSuccessInfo/ErrorOrSuccessInfo';
import { Button } from '../UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  onClose: () => void;
  active: boolean;
}

export const ModalWarningAsyncChanges: FC<IProps> = memo(function ModalWarningAsyncChanges(props) {
  const { active, onClose } = props;
  const [footerHeight, setFooterHeight] = useState(0);
  const footerRef = useCallback(node => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);
  const [_, setNoShowMore] = useLocalStorage('noShowModalWarningAsyncChanges');
  const { css } = useStyle(ModalWarningAsyncChangesRules, { footerHeight });
  const { theme } = useTheme();

  const noShowMore = () => {
    setNoShowMore(true);
    onClose();
  };

  const { t } = useTranslation()

  return (
    <Modal
      active={active}
      name={ModalNames.StoreChangesSaved}
      onClose={onClose}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
    >
      <div className={css.modalInner}>
        <ErrorOrSuccessInfo
          success={true}
          title={t('modalWarningAsyncChanges.changesWillBeAppliedInAFewMinutes')}
          text={t('modalWarningAsyncChanges.theProcessUsuallyTakesAFewSecondsButSometimesUpToTwoMinutesJustAHeadsUp')}
        />
        <Button
          onClick={noShowMore}
          text={t('modalWarningAsyncChanges.doNotShowThisMessageAgain')}
          hasGradient={false}
          extend={css.buttonNoShowMore}
          propsStyles={{
            padding: '13px',
            width: '100%',
            background: theme.colorGrey,
            color: theme.colorBlack,
          }}
        />
        <footer className={css.footer} ref={footerRef}>
          <Button
            onClick={onClose}
            text={t('modalWarningAsyncChanges.okay')}
            propsStyles={{
              width: '100%',
            }}
          />
        </footer>
      </div>
    </Modal>
  );
});
