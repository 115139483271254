import { VALIDATION_ERRORS } from 'src/types/validation';
import { checkUrl } from './checkUrl';

export function validateUrl(value) {
  const isUrl = checkUrl(value);
  if (isUrl) {
    return true;
  } else {
    return VALIDATION_ERRORS.URL;
  }
}

export function corretUrlOnPaste(event) {
  let trimmedValue = event.target.value.trim().replace(/^H/, 'h');

  const incorrectProtocolPattern = /^(pttp|htttp|htpp|ttp|hptt|htpt|hptp|http):\/\//i;
  trimmedValue = trimmedValue.replace(incorrectProtocolPattern, 'https://');

  if (!/^https:\/\//i.test(trimmedValue)) {
    trimmedValue = `https://${trimmedValue}`;
  }
  return trimmedValue
}
