import { SliderBlock } from '@teleport/schemas-protobuf';
import { SliderTranslator } from 'src/redux/translators/sliderTransaltor';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const createSlider = createAppAsyncThunk('slider/createSlider', async (_, thunkAPI) => {
  const slider = new SliderBlock({ uuid: '', items: [] });
  const { sliderUuid } = await thunkAPI.extra.portOwnerApi().sliderUpsert({ slider });
  slider.uuid = sliderUuid;
  return SliderTranslator.fromSliderBlock(slider);
});
