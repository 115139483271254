import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface ISelectColorPageKeys {
  header: {};
  wrapperJustify: {};
  grid: {};
  content: {};
}

export type ISelectColorPage = IRuleFn<ISelectColorPageKeys>;

const SelectColorPageRuleFn: ISelectColorPage = () => {
  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
    grid: {
      display: 'grid',
      flexDirection: 'column',
      gap: 12,
    },
    content: {
      display: 'grid',
      flexDirection: 'column',
      gap: 14,
      paddingTop: 9,
      paddingBottom: '9px',
    },
    wrapperJustify: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
};

export const selectColorPageRules = createCachedStyle(SelectColorPageRuleFn);
