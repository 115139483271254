import { ReactNode, memo, useEffect, useState } from 'react';
import { getDocumentHeight } from 'src/utils/getDocumentHeight';
import { useStyle } from 'src/utils/theme/useStyle';
import { footerRules } from './footer.style';

interface IProps {
  children: ReactNode;
  propsStyles?: IStyles;
  staticStyle?: boolean;
  staticFooter?: boolean;
}

interface IStyles {
  height?: number;
  padding?: string;
  backgroundColor?: string;
}

const Footer = function Footer(props: IProps) {
  const [endDocument, setEndDocument] = useState(false);
  const { children, staticFooter = false, propsStyles, staticStyle } = props;
  const { css } = useStyle(footerRules, {
    height: propsStyles?.height,
    padding: propsStyles?.padding,
    backgroundColor: propsStyles?.backgroundColor,
    endDocument,
    staticFooter,
  });

  useEffect(() => {
    if (staticStyle) return null;

    const scrollHeight = getDocumentHeight();
    const windowHeight = document.documentElement.clientHeight;

    if (scrollHeight - window.scrollY - windowHeight <= 50) {
      setEndDocument(true);
    }

    const checkScrollPosition = () => {
      if (scrollHeight - window.scrollY - windowHeight <= 50) {
        setEndDocument(true);
      } else {
        setEndDocument(false);
      }
    };

    document.addEventListener('scroll', checkScrollPosition);

    return () => {
      document.removeEventListener('scroll', checkScrollPosition);
    };
  }, [staticStyle]);

  return <footer className={[css.footer, 'footer'].join(' ')}>{children}</footer>;
};

export default memo(Footer);
