import { useCallback } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { configState } from '../../../redux/slices/configSlice';
import { Language } from '../i18n.interface';

export const useTranslation = () => {
  const { config: { language }, translation } = useAppSelector(configState);

  const translate = useCallback((key: string, ...params: (number | string)[]) => {
    if (!translation[language][key]) {
      console.warn(`No translation for key "${key}" in language ${language}`);
      return key;
    }

    if (params.length > 0) {
      const translationKey = translation[language][key];

      return translationKey.replace(/\{(.d?)}/g, match => {
        const id = match[1];

        return params[id];
      });
    }

    return translation[language][key];
  }, [language, translation]);

  const translatePlural = useCallback((key: string, count: number) => {
    switch (language) {
      case Language.en: {
        if (count === 1) {
          const translationKey = translation[language][key];

          return translationKey.replace(/\{count}/, count.toString());
        } else {
          const translationKey = translation[language][`${key}_plural`];

          if (!translationKey) {
            console.warn(`No translation for key "${key}" in language ${language} for plural`);

            return key;
          }

          return translationKey.replace(/\{count}/, count.toString());
        }
      }
      case Language.ru: {
        if (count % 10 === 1 && count !== 11) {
          const translationKey = translation[language][`${key}_0`];

          if (!translationKey) {
            console.warn(`No translation for key "${key}" in language ${language} for one`);

            return key;
          }

          return translationKey.replace(/\{count}/, count.toString());
        } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count)) {
          const translationKey = translation[language][`${key}_1`];

          if (!translationKey) {
            console.warn(`No translation for key "${key}" in language ${language} for few`);

            return key;
          }

          return translationKey.replace(/\{count}/, count.toString());
        } else {
          const translationKey = translation[language][`${key}_2`];

          if (!translationKey) {
            console.warn(`No translation for key "${key}" in language ${language} for many`);

            return key;
          }

          return translationKey.replace(/\{count}/, count.toString());
        }
      }
      default: {
        console.warn(`Unsupported language pluralization, ${language}`);

        return key;
      }
    }

  }, [language, translation]);

  return { t: translate, tPlural: translatePlural };
};
