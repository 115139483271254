import { ReactNode, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { Button } from '../UI/Button/Button';
import { modalSuccessRules } from './ModalSuccess.style';
import { ReactComponent as SuccesIcon } from 'src/assets/svg/shield-tick.svg';

interface IProps {
  onClose: () => void;
  active: boolean;
  text: string;
  children?: ReactNode;
}

const ModalSuccess = (props: IProps) => {
  const { active, text, onClose } = props;

  const { css } = useStyle(modalSuccessRules);

  const closeModalFn = () => {
    onClose();
  };
  return (
    <Modal active={active} name={ModalNames.Success} onClose={onClose}>
      <div className={css.content}>
        <SuccesIcon />
        <p className={css.text}>{text}</p>
        {props.children}
      </div>
      <footer className={css.footer}>
        <Button
          onClick={closeModalFn}
          text="Хорошо"
          propsStyles={{
            width: '100%',
          }}
        />
      </footer>
    </Modal>
  );
};

export default memo(ModalSuccess);
