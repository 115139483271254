import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModifiersPageRules } from './ModifiersPage.style';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { AppRoutes, RoutePath } from 'src/providers/AppRouter/routeConfig';
import { useAppSelector } from 'src/hooks/redux';
import { cmodifiersState } from 'src/redux/slices/modifiersSlice';
import { ModifierCard } from 'src/components/ModifierCard/ModifierCard';

//Page to display all Modifiers
export const ModifiersPage: FC = memo(function ModifiersPage(props) {
  const {} = props;
  const { css } = useStyle(ModifiersPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();

  const { showModifiers, modifiers } = useAppSelector(cmodifiersState);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const [showModifires, setShowModifires] = useState(false);

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text="Модификаторы"
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
          <Toggler
            checked={showModifires}
            onChange={e => setShowModifires(e.target.checked)}
            name="show-modifires"
            value="show-modifires"
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            href={RoutePath[AppRoutes.CreateModifier]}
            text="Создать модификатор"
            propsStyles={{ width: '100%' }}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        {showModifiers ? (
          <div className={css.grid}>
            {modifiers.map(el => {
              return (
                <ModifierCard
                  onClick={() => navigate(`/edit-modifier/${el.id}`)}
                  key={el.id}
                  modifierData={el}
                />
              );
            })}
          </div>
        ) : (
          <NoSettings
            text={
              'Создайте модификаторы\n и пользователи смогут выбирать товар\n по размеру, цвету и другим\n характеристикам'
            }
            title={'Вы пока не создали\n  модифиаторов'}
            icon="data-large"
          />
        )}
      </Container>
    </Layout>
  );
});
