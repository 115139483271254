import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalColorKeys {
  modalInner: {};
  footer: {};
  colorPicker: {};
}

interface IModalColorProps {
  footerHeight: number;
}

export type IModalColor = IRuleFn<IModalColorKeys, IModalColorProps>;

const ModalColorRuleFn: IModalColor = props => {
  const { sidePadding, colorWhite } = props.theme;
  const { footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      paddingTop: sidePadding,
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: `${footerHeight}px`,
      maxHeight: '100%',
      overflow: 'auto',

      '& .react-colorful': {
        width: '100%',
        marginBottom: 16,
      },
    },

    footer: {
      position: 'fixed',
      left: 0,
      bottom: 0,
      backgroundColor: colorWhite,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    colorPicker: {},
  };
};

export const ModalColorRules = createCachedStyle(ModalColorRuleFn);
