import { IStory } from 'src/types/stories';
import { StoriesTranslator } from '../../translators/storiesTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const deleteStory = createAppAsyncThunk(
  'stories/deleteStory',
  async (story: IStory, thunkAPI) => {
    // client.storyReorder()
    // client.storyUpsert

    const storyDeleteRequest = StoriesTranslator.toStoryDeleteRequest(story);

    return await thunkAPI.extra.portOwnerApi().storyDelete(storyDeleteRequest);
  },
);
