import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IStoreNoteWorkingRuleKeys {
  app: {};
  wrapper: {};
  img: {};
  text: {};
  title: {};
  subtitle: {};
}

export type IStoreNoteWorkingRules = IRuleFn<IStoreNoteWorkingRuleKeys>;

const storeNoteWorkingRuleFn: IStoreNoteWorkingRules = props => {
  const { appFooterHeight, appMaxWidth } = props.theme;

  return {
    app: {
      maxWidth: appMaxWidth,
      margin: '0 auto',
      minHeight: '100vh',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 580,
      height: `calc(100vh - ${appFooterHeight}px)`,
    },
    img: {
      marginBottom: 27,
    },
    text: {
      textAlign: 'center',
    },
    title: {
      display: 'block',
      marginBottom: 8,
      fontSize: 20,
      lineHeight: '26px',
      fontWeight: 800,
      textTransform: 'uppercase',
    },
    subtitle: {
      fontSize: 16,
      lineHeight: '16px',
      fontWeight: 400,
    },
    footer: {
      ...padding(8, 16),
    },
  };
};

export const storeNoteWorkingRules = createCachedStyle(storeNoteWorkingRuleFn);
