import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IModifier, ModifierType } from 'src/types/modificator';
import { RootState } from '../store';

interface IInitialState {
  showModifiers: boolean;
  modifiers: IModifier[];
}

// products services courses events
const initialState: IInitialState = {
  showModifiers: false,
  modifiers: [
    {
      id: '1',
      name: 'Размер',
      type: ModifierType.VARIANTS,
      content: [
        { id: '1', text: 'xs', value: 'xs', active: false, affectPrice: 0 },
        { id: '2', text: 's', value: 's', active: false, affectPrice: 0 },
        { id: '3', text: 'm', value: 'm', active: false, affectPrice: 0 },
        { id: '4', text: 'l', value: 'l', active: false, affectPrice: 0 },
        { id: '5', text: 'xl', value: 'xl', active: false, affectPrice: 0 },
      ],
      active: false,
      affectPrice: false,
    },
    {
      id: '2',
      name: 'Подарочная твердая упаковка',
      type: ModifierType.TOGGLER,
      active: false,
      affectPrice: true,
      affectPriceValue: '0',
    },
    {
      id: '3',
      name: 'Комплектация',
      type: ModifierType.LIST,
      active: false,
      content: [
        { id: '1', text: 'Бедная', value: 'bad', active: false, affectPrice: 0 },
        { id: '2', text: 'Обычная', value: 'medium', active: false, affectPrice: 0 },
        { id: '3', text: 'Роскошная', value: 'luxury', active: false, affectPrice: 0 },
      ],
      affectPrice: true,
    },
  ],
};
export const modifiersSlice = createSlice({
  name: 'Modifiers',
  initialState,
  reducers: {
    setShowModifiers: (state, action: PayloadAction<boolean>) => {
      state.showModifiers = action.payload;
    },
  },
});

export const { setShowModifiers } = modifiersSlice.actions;

export const cmodifiersState = (state: RootState) => state[modifiersSlice.name];
