import { ITheme } from './theme.interface';

// todo dark theme variables
export const themeDark: ITheme = {
  background: '#f0f0f0',
  colorBlack: '#1c1916',
  mainColor: '#0500ff',
  colorWhite: '#ffffff',
  colorRed: '#E42222',
  colorGrey: '#dedede',
  colorDarkGrey: '#a0a0a0',
  colorGreen: '#19BD16',
  colorDarkGreen: '#226620',

  // rgb
  colorRgbMain: '5, 0, 255',
  colorRgbBlack: 'rgb(28, 25, 22)',
  colorRgbWhite: 'rgb(255, 255, 255)',
  colorRgbRed: '228, 34, 34',

  gradientBlue:
    'linear-gradient(110deg,rgba(9, 5, 171, 1) 0%,rgba(7, 3, 213, 1) 50%,rgba(78, 76, 181, 1) 75%)',

  transitionDuration: '.3s',
  appMaxWidth: 750,
  appFooterHeight: 86,
  sidePadding: 16,
  defaultFont: 'Montserrat, sans-serif',
};
