import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { StoriesWrapperRules } from './StoriesWrapper.style';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { StoryCardInSettings } from '../StoryCardInSettings/StoryCardInSettings';
import { useModalState } from 'src/hooks/useModalState';
import { ModalSettingStory } from '../ModalSettingStory/ModalSettingStory';
import { IStory } from 'src/types/stories';
import { useAppDispatch } from 'src/hooks/redux';
import { storyReorder } from 'src/redux/api/stories/storyReorder';
import { setStories } from 'src/redux/slices/storiesSlice';

// https://docs.dndkit.com/presets/sortable - документация dragAndDrop

interface IProps {
  stories: IStory[];
}

export const StoriesWrapper: FC<IProps> = memo(function StoriesWrapper(props) {
  const { stories } = props;
  const { css } = useStyle(StoriesWrapperRules);
  const sensors = useSensors(
    useSensor(TouchSensor, {
      // activationConstraint: {
      //   delay: 250,
      //   tolerance: 5,
      // },
    }),
    useSensor(PointerSensor),
  );
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [editableStory, setEditableStory] = useState<IStory | null>(null);
  const isTheFirstRender = useRef(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isTheFirstRender.current) {
      isTheFirstRender.current = false;
      return;
    }
    // need fix! reorder works every time
    dispatch(storyReorder(stories));
  }, [dispatch, stories]);

  function openEditModal(event) {
    const storyId = event.currentTarget.id;
    const story = stories.find(story => story.id === storyId);
    if (!story) return;
    setEditableStory(story);
    openModal();
  }

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  function handleDragStart() {
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  // Функция изменяющая порядок элементов в нашем массиве items
  function handleDragEnd(event) {
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);

    const { active, over } = event;

    if (active.id !== over.id) {
      const dragEl = stories.find(el => el.id === active.id);
      const dropEl = stories.find(el => el.id === over.id);

      const oldIndex = stories.indexOf(dragEl);
      const newIndex = stories.indexOf(dropEl);

      const newArr = arrayMove(stories, oldIndex, newIndex);

      dispatch(setStories(newArr));

      // setItems(items => {
      //   const dragEl = items.find(el => el.id === active.id);
      //   const dropEl = items.find(el => el.id === over.id);

      //   const oldIndex = items.indexOf(dragEl);
      //   const newIndex = items.indexOf(dropEl);

      //   const newArr = arrayMove(items, oldIndex, newIndex);

      //   return newArr;
      // });
    }
  }

  return (
    <div className={css.wrapper}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={stories} strategy={verticalListSortingStrategy}>
          {stories.map(story => (
            <StoryCardInSettings storyData={story} key={story.id} openEditModal={openEditModal} />
          ))}
        </SortableContext>
      </DndContext>
      {renderModal && (
        <ModalSettingStory
          active={activeModal}
          onClose={closeModal}
          editingStory={true}
          story={editableStory}
        />
      )}
    </div>
  );
});
