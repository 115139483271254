import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalModifierTypeKeys {
  modalInner: {};
  header: {};
  info: {};
  footer: {};
  wrapper: {};
  removeBtnWrapper: {};
  categoryHeader: {};
  subcategories: {};
  selectAllBtn: {};
  category: {};
}

interface IModalModifierTypeProps {
  headerHeight: number;
}

export type IModalModifierType = IRuleFn<IModalModifierTypeKeys, IModalModifierTypeProps>;

const ModalModifierTypeRuleFn: IModalModifierType = props => {
  const { colorRgbBlack, mainColor, sidePadding, colorWhite } = props.theme;
  const { headerHeight } = props;

  return {
    modalInner: {
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: '34px',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    info: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      color: `rgba(${colorRgbBlack}, .4)`,

      '& a': {
        color: mainColor,
      },
    },
    wrapper: {
      ...padding(12, 0),
    },
    footer: {
      position: 'sticky',
      backgroundColor: colorWhite,
      bottom: 0,
      paddingTop: 12,
      paddingBottom: 30,
    },
    removeBtnWrapper: {
      ...padding(28, 0, 8),
    },
    categoryHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 20,
    },
    selectAllBtn: {
      padding: 0,
      color: mainColor,
      background: 'transparent',
      border: 'none',
      outline: 'none',
      fontSize: 12,
      fontWeight: 700,
      lineHeight: '14px',

      ':active': {
        opacity: 0.7,
      },
    },
    category: {
      ...padding(12, 0),
    },
    subcategories: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
    },
  };
};

export const modalModifierTypeRules = createCachedStyle(ModalModifierTypeRuleFn);
