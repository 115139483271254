import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IStoryRuleKeys {
  card: {};
  text: {};
}

interface IStoryRuleProps {
  backgroundImage: string;
}

export type IStoryRules = IRuleFn<IStoryRuleKeys, IStoryRuleProps>;

const storyRuleFn: IStoryRules = props => {
  const { colorWhite, colorRgbBlack } = props.theme;
  const { backgroundImage } = props;

  return {
    card: {
      width: 100,
      height: 140,
      padding: 4,
      borderRadius: 8,
      backgroundBlendMode: 'multiply',
      backgroundImage: `url(${backgroundImage})`,
      backgroundColor: `rgba(${colorRgbBlack}, 0.5)`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      border: 'none',
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    text: {
      textAlign: 'start',
      display: 'block',
      fontWeight: 600,
      fontSize: 10,
      lineHeight: '12px',
      whiteSpace: 'pre-wrap',
      color: colorWhite,
    },
  };
};

export const storyRules = createCachedStyle(storyRuleFn);
