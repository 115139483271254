import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IShopStateKeys {
  bannerDisabled: {};
  wrapper: {};
  buttons: {};
  shareButton: {};
}

export type IShopState = IRuleFn<IShopStateKeys>;

const ShopStateRuleFn: IShopState = props => {
  const { mainColor, colorWhite } = props.theme;
  const {} = props;

  return {
    wrapper: {
      paddingTop: 32,
      paddingBottom: 12,
    },
    bannerDisabled: {
      backgroundColor: mainColor,
      paddingTop: 40,
      paddingBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
      textAlign: 'center',
      display: 'flex',
      borderRadius: 12,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 4,
      color: colorWhite,
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '12px',
    },
    shareButton: {
      flexShrink: 0,
    },
  };
};

export const ShopStateRules = createCachedStyle(ShopStateRuleFn);
