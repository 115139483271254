import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWizardShopNameRuleKeys {
  imagePicker: {};
}

export type IWizardShopNameRules = IRuleFn<IWizardShopNameRuleKeys>;

const WizardShopNameRuleFn: IWizardShopNameRules = props => {
  const {} = props.theme;

  return {
    imagePicker: {
      marginTop: 52,
    },
  };
};

export const WizardShopNameRules = createCachedStyle(WizardShopNameRuleFn);
