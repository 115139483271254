import { IStrictStyle } from './fela.interface';
import { assignStyle } from 'css-in-js-utils';

type IStrictStyleWithClassName = IStrictStyle & {
  _className?: string;
};

const resolveRule = (rule: IStrictStyleWithClassName | IStrictStyleWithClassName[]) => {
  if (Array.isArray(rule)) {
    return resolveRule(combineRules([...rule] as IStrictStyle));
  }

  return rule;
};

export function combineRules<A>(a: IStrictStyle): IStrictStyle;
export function combineRules<A, B>(a: IStrictStyle, b: IStrictStyle): IStrictStyle;
export function combineRules<A, B, C>(
  a: IStrictStyle,
  b: IStrictStyle,
  c: IStrictStyle,
): IStrictStyle;
export function combineRules<A, B, D>(
  a: IStrictStyle,
  b: IStrictStyle,
  c: IStrictStyle,
  d: IStrictStyle,
): IStrictStyle;

export function combineRules(...rules: IStrictStyle[]): IStrictStyle {
  if (rules.length === 1) {
    return resolveRule(rules[0] as IStrictStyleWithClassName | IStrictStyleWithClassName[]);
  }

  let style: { _className?: string } = {
    _className: undefined,
  };

  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];

    const resolvedRule = resolveRule(
      rule as IStrictStyleWithClassName | IStrictStyleWithClassName[],
    );

    // special combination of our special _className key
    if (resolvedRule && style._className) {
      const resolvedClassName = resolvedRule._className ? ' ' + resolvedRule._className : '';

      resolvedRule._className = style._className + resolvedClassName;
    }

    style = assignStyle(style, resolvedRule);
  }

  return style;
}
