import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IOptionRuleKeys {
  option: {};
  input: {};
  text: {};
  icon: {};
  check: {};
  optionText: {};
}

export type IOptionRules = IRuleFn<IOptionRuleKeys>;

const optionRuleFn: IOptionRules = props => {
  const { mainColor, colorGrey, colorWhite } = props.theme;

  return {
    option: {
      columnGap: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...padding(10, 16),
      borderBottom: `1px solid ${colorGrey}`,

      ':last-child': {
        border: 'none',
      },
    },
    optionText: {
      columnGap: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    icon: {},
    input: {
      position: 'absolute',
      zIndex: '-1',
      opacity: 0,
    },
    text: {},
    check: {
      width: '20px',
      height: 20,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: mainColor,

      '& svg': {
        width: 12,
        height: 12,
        fill: colorWhite,
      },
    },
  };
};

export const optionRules = createCachedStyle(optionRuleFn);
