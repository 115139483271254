import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IStoreNumbersKeys {
  card: {};
  text: {};
  wrapper: {};
  statistic: {};
  title: {};
}

export type IStoreNumbers = IRuleFn<IStoreNumbersKeys>;

const StoreNumbersRuleFn: IStoreNumbers = props => {
  const { colorWhite } = props.theme;
  const {} = props;

  return {
    wrapper: {
      ...padding(12, 0),
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    card: {
      flexGrow: 1,
      ...padding(16, 12),
      backgroundColor: colorWhite,
      borderRadius: 12,
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      columnGap: 8,
      flexBasis: '50%',
    },
    title: {
      marginBottom: 8,
    },
    text: {},
    statistic: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 4,
    },
  };
};

export const StoreNumbersRules = createCachedStyle(StoreNumbersRuleFn);
