import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface ISubscriptionPageKeys {
  title: {};
  grid: {};
  txtBlockTitle: {};
  profileActions: {};
  btn: {};
  cancelColor: {};
  success_row: {};
  success_rows: {};
}

export type ISubscriptionPages = IRuleFn<ISubscriptionPageKeys>;

const SubscriptionPageRuleFn: ISubscriptionPages = props => {
  const { mainColor, colorRed, colorRgbBlack } = props.theme;

  return {
    title: {
      ...padding(16, 0),
      fontWeight: '800',
      marginBottom: 20,
      letterSpacing: '-0.4px',
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    txtBlockTitle: {
      marginBottom: 4,
    },
    profileActions: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      marginTop: 40,
      marginBottom: 40,
    },
    btn: {
      fontSize: 10,
      lineHeight: '12px',
      fontWeight: 700,
      color: mainColor,
      textAlign: 'left',
      border: 'none',
      textDecoration: 'none',
    },
    cancelColor: {
      color: `${colorRed}!important`,
    },
    success_row: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
      paddingBottom: '4px',
      borderBottom: `1px solid rgba(${colorRgbBlack}, 0.1)`,
    },
    success_rows: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
  };
};

export const SubscriptionPageRules = createCachedStyle(SubscriptionPageRuleFn);
