import { useCallback } from 'react';
import { checkUrl } from '../checkUrl';
import { useTranslation } from '../i18n/hooks/useTranslation';

export const useValidateUrl = () => {
  const { t } = useTranslation();

  return useCallback((value: string) => {
    const isUrl = checkUrl(value);
    if (isUrl) {
      return true;
    } else {
      return t('modalSettingSlider.pleaseCheckIfTheLinkIsCorrect');
    }
  }, [t])
};
