import { useMemo } from 'react';
import { useTranslation } from '../i18n/hooks/useTranslation';
import { IListWithCardOption } from '../../components/UI/Lists/ListWithCards/ListWithCards';
import { Language } from '@teleport/schemas-protobuf';

export const useLanguageData = (): IListWithCardOption[] => {
  const { t } = useTranslation();

  return useMemo(() => [
    {
      title: t('wizardStepEnd.russian'),
      value: String(Language.RU),
      subtitle: t('wizardStepEnd.russian'),
    },
    {
      title: 'English',
      subtitle: t('wizardStepEnd.english'),
      value: String(Language.EN),
    },
    {
      title: 'Español',
      value: String(Language.ES),
      subtitle: t('wizardStepEnd.spanish'),
      disabled: true,
      soon: true,
    },
    {
      title: 'Português',
      value: String(Language.PT),
      subtitle: t('wizardStepEnd.portuguese'),
      disabled: true,
      soon: true,
    },
    {
      title: 'Українська',
      value: String(Language.UK),
      subtitle: t('wizardStepEnd.ukrainian'),
      disabled: true,
      soon: true,
    },
    {
      title: 'Türkçe',
      value: String(Language.TR),
      subtitle: t('wizardStepEnd.turkish'),
      disabled: true,
      soon: true,
    },
    {
      title: 'Қазақша',
      value: String(Language.KK),
      subtitle: t('wizardStepEnd.kazakh'),
      disabled: true,
      soon: true,
    },
  ], [t]);
};
