import { SliderTranslator } from 'src/redux/translators/sliderTransaltor';
import { ISlider } from 'src/types/slider';
import { createAppAsyncThunk } from '../../../hooks/redux';

interface ISliderData {
  slider: ISlider;
  slideUpdateData?: {
    slideId: string;
    file: File;
  };
}

export const sliderUpsert = createAppAsyncThunk(
  'slider/sliderUpsert',
  async ({ slider, slideUpdateData }: ISliderData, thunkAPI) => {
    let imageUrls: string[] = [];
    if (slideUpdateData) {
      try {
        imageUrls = await thunkAPI.extra.filesUpload([slideUpdateData.file]);
      } catch (e) {
        console.info('Upload files failed: ', e);
      }
    }

    const sliderBlock = SliderTranslator.fromISlider(slider, {
      slideId: slideUpdateData?.slideId,
      newImageUrl: imageUrls[0],
    });

    const result = await thunkAPI.extra.portOwnerApi().sliderUpsert({ slider: sliderBlock });
    return {
      result: result,
      imgUrl: imageUrls
    }
    
  },
);
