import { Filter } from '@teleport/schemas-protobuf';
import { ProductsTranslator } from 'src/redux/translators/productsTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const getProductsBySearch = createAppAsyncThunk(
  'products/getProductsBySearch',
  async (query: string, thunkAPI) => {
    const filter = new Filter({
      filterDefaultState: { case: 'searchFilter', value: { query } },
    });

    const result = await thunkAPI.extra
      .portOwnerApi()
      .ownerCatalogProductList({ filters: [filter] });

    return ProductsTranslator.fromProductListResponse(result);
  },
);
