import { LegacyRef, forwardRef, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { textRules } from './text.style';
import { addPx } from 'src/utils/applyCSSProperty ';

interface IProps {
  extend?: string;
  text: string;
  mod: 'title' | 'text';
  fontSize?: number;
  letterSpacing?: number;
  lineHeight?: number;
  textTransform?: string;
  fontWeight?: number;
  whiteSpace?: 'pre-wrap' | 'nowrap';
  svg?: 'stars'
}

const Text = forwardRef(function Text(props: IProps, ref: LegacyRef<HTMLParagraphElement>) {
  const {
    extend,
    text,
    mod,
    fontSize,
    letterSpacing,
    lineHeight,
    textTransform,
    fontWeight,
    whiteSpace,
    svg
  } = props;
  const { css } = useStyle(textRules, {
    mod,
    fontSize,
    letterSpacing,
    lineHeight: addPx(lineHeight),
    textTransform,
    fontWeight,
    whiteSpace,
    display: svg? 'inline-flex' : undefined
  });

  return (
    <p className={[css.text, extend].join(' ')} ref={ref}>
      {text}
      {svg && (
        <img className={css.img} src={`/images/currencies/${svg}.svg`} alt='' />
      )}

    </p>
  );
});

export default memo(Text);
