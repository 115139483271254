import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../UI/Button/Button';
import Text from '../UI/Text/Text';
import { PersonalDevelopmentRules } from './PersonalDevelopment.style';
import { useTelegram } from 'src/utils/telegramProvider';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';
import { padding } from 'src/utils/applyCSSProperty ';

interface IProps {
  propsStyles?: IStyles;
}

export interface IStyles {
  marginTop?: number;
  marginBottom?: number;
}

const PersonalDevelopment = function PersonalDevelopment(props: IProps) {
  const { propsStyles } = props;
  const { css } = useStyle(PersonalDevelopmentRules, {
    marginBottom: propsStyles?.marginBottom,
    marginTop: propsStyles?.marginTop,
  });
  const { theme } = useTheme();
  const Telegram = useTelegram();
  const { t } = useTranslation();

  const toKnowAboutDevelopment = () => {
    if (!Telegram.webApp.initData) return;
    Telegram.webApp.openTelegramLink('https://t.me/TeleportPlusBot');
  };

  return (
    <div className={css.wrapper}>
      <img src="images/development.png" className={css.image} />
      <Text
        text={t('personalDevelopment.weCanCreateAUniqueAppForYourNeeds')}
        fontWeight={700}
        lineHeight={22}
        extend={css.title}
        mod="title"
      />
      <Text
        text={t('personalDevelopment.ifOurOutOfTheBoxSolutionDoesntFitYouCanHireUsToBuildATelegramAppWhichWillMeetYourPersonalRequirements')}
        fontSize={12}
        fontWeight={500}
        lineHeight={16}
        extend={css.text}
        mod="text"
      />
      <Button
        text={t('personalDevelopment.findOutMore')}
        propsStyles={{
          width: '100%',
          height: 42,
          background: theme.colorWhite,
          ...padding(12),
          color: theme.colorBlack,
        }}
        onClick={toKnowAboutDevelopment}
        hasGradient={false}
        extend={css.button}
      />
    </div>
  );
};

export default memo(PersonalDevelopment);
