import { FC, ReactNode, memo, useEffect, useState } from 'react';
import { ThemeProvider } from 'react-fela';
import { useAppSelector } from 'src/hooks/redux';
import { getTheme } from 'src/utils/theme/getTheme';
import { ThemeName } from 'src/utils/theme/theme.interface';

interface IProps {
  children: ReactNode;
}

import { createContext } from 'react';
import { hexToRgb } from 'src/utils/hexToRgb';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import { configState } from 'src/redux/slices/configSlice';

export const ThemeManagementContext = createContext(null);

export const Theme: FC<IProps> = memo(function Theme(props) {
  const { wizard } = useAppSelector(getWizardState);
  const { config } = useAppSelector(configState);
  const { children } = props;
  const [theme, setTheme] = useState(getTheme(ThemeName.Light));

  useEffect(() => {
    const accentColor = wizard.accentColor ? wizard.accentColor : config.accentColor;
    const rgbObj = hexToRgb(accentColor);
    const colorRgbMain = `${rgbObj?.r}, ${rgbObj?.g}, ${rgbObj?.b}`;
    setTheme({ ...theme, mainColor: accentColor, colorRgbMain });
    // eslint-disable-next-line
  }, [wizard.accentColor, config.accentColor]);

  return (
    <ThemeManagementContext.Provider value={{ setTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeManagementContext.Provider>
  );
});
