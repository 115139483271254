import { useRenderer } from './useRenderer';
import { shallowEqual } from 'react-redux';
import {
  ICachedStyle,
  AllowedProps,
  IClasses,
  IExtend,
  IParametricCachedStyle,
} from './fela.interface';
import { useTheme } from './useTheme';
import { useMemoWith } from '../react-use/useMemoWith';

export function useStyle<R extends object>(
  styleFunc: ICachedStyle<R>,
): { rules: IExtend<R>; css: IClasses<R> };

export function useStyle<P extends AllowedProps, R extends object>(
  styleFunc: IParametricCachedStyle<R, P>,
  props: P,
): { rules: IExtend<R>; css: IClasses<R> };

export function useStyle(cachedStyle: any, props?: any) {
  const { theme } = useTheme();
  const { renderer } = useRenderer();

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const rules = cachedStyle.getRules(Object.assign(props ?? {}, { theme }), renderer);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const { css } = cachedStyle.getStyle(Object.assign(props ?? {}, { theme }), renderer);

  const memoizedRules = useMemoWith(rules, shallowEqual);
  const memoizedCss = useMemoWith(css, shallowEqual);

  return {
    rules: memoizedRules,
    css: memoizedCss,
  };
}
