import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalProductSuccessfulCreatedKeys {
  modalInner: {};
  header: {};
  footer: {};
  banner: {};
  iconWrapper: {};
  guard: {};
  check: {};
}

// interface IModalProductSuccessfulCreatedProps {
//   headerHeight: number;
// }

export type IModalProductSuccessfulCreated = IRuleFn<IModalProductSuccessfulCreatedKeys>;
// IModalProductSuccessfulCreatedProps

const ModalProductSuccessfulCreatedRuleFn: IModalProductSuccessfulCreated = props => {
  const { sidePadding, colorWhite } = props.theme;
  const {} = props;

  return {
    modalInner: {
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      maxHeight: '100%',
      overflow: 'auto',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    footer: {
      position: 'sticky',
      backgroundColor: colorWhite,
      bottom: 0,
      paddingTop: 12,
      paddingBottom: 30,
    },
    banner: {
      paddingTop: 48,
      paddingBottom: 24,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      rowGap: 28,
      textAlign: 'center',
    },

    iconWrapper: {
      position: 'relative',
      width: 66,
      height: 83,
    },
    guard: {
      position: 'absolute',
      left: 0,
      top: 0,
    },
    check: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
};

export const ModalProductSuccessfulCreatedRules = createCachedStyle(
  ModalProductSuccessfulCreatedRuleFn,
);
