import { SelectionsTranslator } from 'src/redux/translators/selectionsTranslator';
import { ISelection } from 'src/types/selection';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const productToSelectionBind = createAppAsyncThunk(
  'selections/productToSelection',
  async (
    { selection, productUuids }: { selection: ISelection; productUuids: string[] },
    thunkAPI,
  ) => {
    const requestData = SelectionsTranslator.toProductToSelectionRequest({
      selection,
      productUuids,
    });

    return await thunkAPI.extra.portOwnerApi().productToSelectionBind(requestData);
  },
);
