import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IIconInBoxKeys {
  iconBox: {};
}

interface IIconInBoxProps {
  width: number;
  height: number;
}

export type IIconInBox = IRuleFn<IIconInBoxKeys, IIconInBoxProps>;

const IconInBoxRuleFn: IIconInBox = props => {
  const { mainColor, colorRgbMain } = props.theme;
  const { width, height } = props;

  return {
    iconBox: {
      width,
      height,
      borderRadius: 8,
      backgroundColor: `rgba(${colorRgbMain}, .1)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '& svg': {
        width: '60%',
        fill: mainColor,
      },
    },
  };
};

export const IconInBoxRules = createCachedStyle(IconInBoxRuleFn);
