import { FC, memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalAccentBgRules } from './ModalAccentBg.style';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  active: boolean;
  onClose: () => void;
}

export const ModalAccentBg: FC<IProps> = memo(function ModalAccentBg(props) {
  const { active, onClose } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(ModalAccentBgRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });
  const { t } = useTranslation();

  return (
    <Modal
      deep={2}
      name={ModalNames.AccentBackground}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalAccentBg.accentBackground')} mod="title" />
      </div>
      <div className={css.modalInner}>
        <Text
          mod="text"
          text={t('modalAccentBg.displayTheTextBlockOnAColoredBackground')}
          fontSize={16}
          fontWeight={500}
          lineHeight={22}
        />
        <div className={css.templateWrapper}>
          <img src="images/phone-background.png" className={css.phoheImg} />
          <div className={css.templateTextBlock}>
            <div>
              <Text
                text={t('modalAccentBg.textBlockTitle')}
                mod="title"
                fontSize={13}
                fontWeight={700}
                lineHeight={15}
                extend={css.templateTitle}
              />
              <Text
                text={t('modalAccentBg.thisCanBeAnySizeItCanBeVeryShortOrQuiteLong')}
                mod="text"
                fontSize={8}
                fontWeight={500}
                lineHeight={10}
              />
            </div>
            <div className={css.templateButton}>
              <Text
                text={t('modalAccentBg.buttonNameWithLink')}
                mod="text"
                fontSize={11}
                fontWeight={700}
                lineHeight={12}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});
