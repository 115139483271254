import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from '../../utils/network/network.constant';
import { getCatalogMetadata } from '../api/catalogMetadata/getCatalogMetadata';
import { ICategoryMetadata } from 'src/types/categories';
import { catalogMetadataTranslator } from '../translators/catalogMetadataTranslator';

interface ICategorySlice {
  categories: ICategoryMetadata[];
  networkStatus: NetworkStatus;
}

const initialState: ICategorySlice = {
  categories: [],
  networkStatus: NetworkStatus.None,
};

export const catalogMetadataSlice = createSlice({
  name: 'CatalogMetadata',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCatalogMetadata.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getCatalogMetadata.fulfilled, (state, action) => {
      state.categories = catalogMetadataTranslator.fromCatalogMetadataResponse(action.payload);
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getCatalogMetadata.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
  },
});

export const {} = catalogMetadataSlice.actions;
export const getCatalogMetadataState = (state: RootState) => state[catalogMetadataSlice.name];
