import { createAppAsyncThunk } from '../../../hooks/redux';

export const deleteProduct = createAppAsyncThunk(
  'products/deleteProduct',
  async (productUuid: string, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().catalogProductDelete({ productUuid });

    return productUuid;
  },
);
