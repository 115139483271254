import { ProductsTranslator } from 'src/redux/translators/productsTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { IProduct } from 'src/types/product';

export const upsertProduct = createAppAsyncThunk(
  'products/upsertProduct',
  async (data: { productData: IProduct; files: File[] }, thunkAPI) => {
    const { productData, files } = data;

    let imageUrls: string[] = [];
    try {
      imageUrls = await thunkAPI.extra.filesUpload(files);
    } catch (e) {
      console.info('Upload files failed: ', e);
    }

    const state = thunkAPI.getState();
    const deliveryType = state.config.config.deliveryType;

    const product = ProductsTranslator.toOwnerCatalogProductFull(
      productData,
      deliveryType,
      imageUrls,
    );

    try {
      await thunkAPI.extra.portOwnerApi().catalogProductUpsert({ product });

      const resultProduct: IProduct = {
        id: product.uuid,
        productName: product.title,
        imageUrls: product.imageUrls,
        price: {
          amount: Number(product.price.amount),
          discountEnabled: product.price.discountEnabled,
          discountInPercent: Number(product.price.discountInPercent),
          oldAmount: Number(product.price.oldAmount),
        },
        enabled: product.enabled,
        category: productData.category,
        subCategory: productData.subCategory,
        sold: 0,
      };

      return resultProduct;
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue('Не удалось изменить товар');
    }
  },
);
