import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IConnectSupportKeys {
  helpText: {};
  wrapper: {};
}

export type IConnectSupport = IRuleFn<IConnectSupportKeys>;

const ConnectSupportRuleFn: IConnectSupport = props => {
  const {} = props.theme;
  const {} = props;

  return {
    wrapper: {
      ...padding(8, 0),
    },
    helpText: {
      marginTop: 8,
      textAlign: 'center',
    },
  };
};

export const ConnectSupportRules = createCachedStyle(ConnectSupportRuleFn);
