import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingStoriesPageKeys {
  header: {};
  container: {};
}

export type ISettingStoriesPage = IRuleFn<ISettingStoriesPageKeys>;

const SettingStoriesPageRuleFn: ISettingStoriesPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {...padding(16), },
    container: {
      height: '100%',
      overflow: 'hidden',
    },
  };
};

export const SettingStoriesPageRules = createCachedStyle(SettingStoriesPageRuleFn);
