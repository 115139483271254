import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { Button } from '../UI/Button/Button';
import { modalErrorRules } from './ModalError.style';
import { useTheme } from 'src/utils/theme/useTheme';
import { useTelegram } from 'src/utils/telegramProvider';
import ErrorOrSuccessInfo from '../ErrorOrSuccessInfo/ErrorOrSuccessInfo';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  onClose: () => void;
  active: boolean;
  title: string;
  text?: string;
  btntxt?: string;
  btnFn?: () => void;
  deep?: number
}

const ModalError = (props: IProps) => {
  const { active, title, onClose, btntxt, btnFn, text, deep } = props;
  const { theme } = useTheme();
  const { css } = useStyle(modalErrorRules);
  const Telegram = useTelegram();

  const { t } = useTranslation()

  const closeModalFn = () => {
    if (btnFn) {
      btnFn();
    }else {
      Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
    }
    onClose();
  };
  return (
    <Modal active={active} deep={deep} name={ModalNames.Error} onClose={onClose}>
      <div className={css.content}>
        <ErrorOrSuccessInfo 
          success={false}
          title={title}
          text={text}
        />
      </div>
      <footer className={css.footer}>
        <Button
          onClick={closeModalFn}
          text={btntxt ?? t('modalError.contactSupport')}
          propsStyles={{
            width: '100%',
            background: theme.colorGrey,
            color: theme.colorBlack
          }}
        />
      </footer>
    </Modal>
  );
};

export default memo(ModalError);
