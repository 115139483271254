import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MainPageHeader } from 'src/blocks/MainPageHeader/MainPageHeader';
import { MainPageSection } from 'src/blocks/MainPageSection/MainPageSection';
import { ShopState } from 'src/blocks/ShopState/ShopState';
import { StoreNumbers } from 'src/blocks/StoreNumbers/StoreNumbers';
import { Stories } from 'src/blocks/Stories/Stories';
import Container from 'src/components/Container/Container';
import { Layout } from 'src/components/Layout/Layout';
import { SettingButton } from 'src/components/UI/SettingButton/SettingButton';
import useLoader from 'src/hooks/useLoader';
import { ConnectSupport } from 'src/blocks/ConnectSupport/ConnectSupport';
import { AppRoutes, RoutePath } from 'src/providers/AppRouter/routeConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { categoryState, getRootCategoryList } from '../../redux/slices/categorySlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { getRootTextBlockList, textBlockState } from 'src/redux/slices/textBlockSlice';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import PersonalDevelopment from 'src/components/PersonalDevelopment/PersonalDevelopment';
import { storiesState } from 'src/redux/slices/storiesSlice';
import { getStories } from 'src/redux/api/stories/getStories';
import { selectionsState } from 'src/redux/slices/selectionsSlice';
import { getSelections } from 'src/redux/api/selections/getSelections';
import { SliderState } from 'src/redux/slices/sliderSlice';
import { getSliderList } from 'src/redux/api/slider/getSliderList';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { InfraProvisioningStatus } from '@teleport/schemas-protobuf';
import { useModalState } from 'src/hooks/useModalState';
import ModalStoreCreatedResult from 'src/components/ModalStoreCreatedResult/ModalStoreCreatedResult';
import useBackButton from 'src/hooks/useBackButton';
import { productsState } from 'src/redux/slices/productsSlice';
import { recomendationState } from 'src/redux/slices/recomendationSlice';
import { getRecomendations } from 'src/redux/api/recomendation/getRecomendations';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export const MainPage = memo(function MainPage() {
  const { categories, networkStatus: categoryNetworkStatus } = useAppSelector(categoryState);
  const { textBlocks, networkStatus: textBlockNetworkStatus } = useAppSelector(textBlockState);
  const { stories, networkStatus: storiesNetworkStatus } = useAppSelector(storiesState);
  const { selections, networkStatus: selectionsNetworkStatus } = useAppSelector(selectionsState);
  const { slider, networkStatus: sliderNetworkStatus } = useAppSelector(SliderState);
  const { products, networkStatus: productsNetworkStatus } = useAppSelector(productsState);
  const { recomendations, networkStatus: recomendationNetworkStatus } =
    useAppSelector(recomendationState);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    complete: wizardIsComplete,
    infraProvisioningStatus,
    wizard,
    networkStatus,
  } = useAppSelector(getWizardState);
  const { hideLoader } = useLoader();

  const [seenCongratulationsStoreModal] = useLocalStorage('seenCongratulationsStoreModal');
  const [
    renderCongratulationsModal,
    activeCongratulationsModal,
    openCongratulationsModal,
    closeCongratulationsModal,
  ] = useModalState();

  const { t } = useTranslation();

  useEffect(() => {
    BackButton.hide();
  }, [BackButton]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.Done) {
      if (
        !wizardIsComplete ||
        infraProvisioningStatus === InfraProvisioningStatus.CREATE_IN_PROGRESS ||
        infraProvisioningStatus === InfraProvisioningStatus.UNSPECIFIED ||
        infraProvisioningStatus === InfraProvisioningStatus.CREATE_ERROR
      ) {
        navigate('/wizard');
      }
    }
    if (networkStatus === NetworkStatus.Done && productsNetworkStatus === NetworkStatus.Done) {
      hideLoader();
    }
    if (
      wizardIsComplete &&
      infraProvisioningStatus >= InfraProvisioningStatus.CREATE_SUCCESS &&
      !seenCongratulationsStoreModal
    ) {
      openCongratulationsModal();
    }
  }, [
    networkStatus,
    wizardIsComplete,
    seenCongratulationsStoreModal,
    infraProvisioningStatus,
    openCongratulationsModal,
    dispatch,
    hideLoader,
    navigate,
    productsNetworkStatus,
  ]);

  // fixme remove this requests
  useEffect(() => {
    if (!wizardIsComplete || infraProvisioningStatus < InfraProvisioningStatus.CREATE_SUCCESS) {
      return undefined;
    }
    if (categoryNetworkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
    if (textBlockNetworkStatus === NetworkStatus.None) {
      dispatch(getRootTextBlockList());
    }
    if (storiesNetworkStatus === NetworkStatus.None) {
      dispatch(getStories());
    }
    if (selectionsNetworkStatus === NetworkStatus.None) {
      dispatch(getSelections());
    }
    if (sliderNetworkStatus === NetworkStatus.None) {
      dispatch(getSliderList());
    }
    if (recomendationNetworkStatus === NetworkStatus.None) {
      dispatch(getRecomendations());
    }
  }, [
    dispatch,
    infraProvisioningStatus,
    wizardIsComplete,
    categoryNetworkStatus,
    textBlockNetworkStatus,
    storiesNetworkStatus,
    selectionsNetworkStatus,
    sliderNetworkStatus,
    recomendationNetworkStatus,
  ]);

  const isCategoryListLoading =
    categoryNetworkStatus === NetworkStatus.None || categoryNetworkStatus === NetworkStatus.Loading;
  const isTextBlockListLoading =
    textBlockNetworkStatus === NetworkStatus.None ||
    textBlockNetworkStatus === NetworkStatus.Loading;
  const isStoriesLoading =
    storiesNetworkStatus === NetworkStatus.None || storiesNetworkStatus === NetworkStatus.Loading;
  const isSelectionsLoading =
    selectionsNetworkStatus === NetworkStatus.None ||
    selectionsNetworkStatus === NetworkStatus.Loading;
  const isSliderLoading =
    sliderNetworkStatus === NetworkStatus.None || sliderNetworkStatus === NetworkStatus.Loading;
  const isRecomendationLoading =
    recomendationNetworkStatus === NetworkStatus.None ||
    recomendationNetworkStatus === NetworkStatus.Loading;

  if (!wizardIsComplete || infraProvisioningStatus === InfraProvisioningStatus.CREATE_IN_PROGRESS) {
    return null;
  }

  return (
    <Layout padding="0 0 42px">
      <Container>
        <MainPageHeader />
      </Container>
      <Stories propsStyles={{ paddingTop: 10 }} />
      <Container>
        {networkStatus === NetworkStatus.Done && productsNetworkStatus === NetworkStatus.Done && (
          <ShopState
            isMaintenanceEnabled={wizard.maintenanceEnabled}
            isShopHasNoProducts={!products?.length}
          />
        )}
        <StoreNumbers />
        <MainPageSection>
          <SettingButton
            title={t('mainPage.mainPageBlocksOrder')}
            href={'/setting-blocks-order'} // todo move url to enum
            icon={'block-order'}
          />
          <SettingButton
            title={t('mainPage.stories')}
            href={RoutePath[AppRoutes.SettingsStories]}
            icon={'stories'}
            count={stories.length}
            isCountLoading={isStoriesLoading}
          />
          <SettingButton
            title={t('mainPage.textBlocks')}
            href={RoutePath[AppRoutes.SettingsTextBlocks]}
            icon={'text-block'}
            count={textBlocks.length}
            isCountLoading={isTextBlockListLoading}
          />
          <SettingButton
            title={t('mainPage.slider')}
            href={RoutePath[AppRoutes.SettingsSlider]}
            icon={'slider'}
            count={slider?.items.length}
            isCountLoading={isSliderLoading}
          />
          <SettingButton
            title={t('mainPage.selections')}
            href={RoutePath[AppRoutes.SettingsSelections]}
            icon={'selections'}
            count={selections.length}
            isCountLoading={isSelectionsLoading}
          />
        </MainPageSection>
        <MainPageSection>
          <SettingButton
            title={t('mainPage.productCategories')}
            href={RoutePath[AppRoutes.SettingsCategories]}
            icon={'categories'}
            count={categories.length}
            isCountLoading={isCategoryListLoading}
          />
          <SettingButton
            title={t('mainPage.modifiers')}
            href={RoutePath[AppRoutes.Modifiers]}
            icon={'modificators'}
            soon={true}
            count={1}
          />
          <SettingButton
            title={t('mainPage.recommendations')}
            icon={'recommendations'}
            href={RoutePath[AppRoutes.Recomendation]}
            isCountLoading={isRecomendationLoading}
            count={recomendations.length}
          />
          <SettingButton title={t('mainPage.mailingList')} icon={'newsletter'} soon={true} />
          <SettingButton title={t('mainPage.promoCodes')} icon={'promocodes'} soon={true} />
        </MainPageSection>
        <MainPageSection>
          <SettingButton
            title={t('mainPage.storeSettings')}
            href={RoutePath[AppRoutes.StoreSetup]}
            icon={'general'}
          />
          <SettingButton
            title={t('mainPage.orderAndDelivery')}
            href={RoutePath[AppRoutes.OrderingAndDelivery]}
            icon={'delivery'}
          />
          <SettingButton title={t('mainPage.payment')} href={''} icon={'payment'} soon={true} />
        </MainPageSection>
        <ConnectSupport />
        <PersonalDevelopment propsStyles={{ marginBottom: 12, marginTop: 12 }} />
      </Container>
      {renderCongratulationsModal && (
        <ModalStoreCreatedResult
          active={activeCongratulationsModal}
          onClose={closeCongratulationsModal}
          isSuccess={true}
        />
      )}
    </Layout>
  );
});
