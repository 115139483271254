import { ISelection } from 'src/types/selection';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const selectionDelete = createAppAsyncThunk(
  'selections/selectionDelete',
  async (selection: ISelection, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().selectionDelete({ selectionUuid: selection.uuid });

    return selection.uuid;
  },
);
