import { FC, memo, ReactNode } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ToastComponentRules } from './ToastComponent.style';

interface IProps {
  children: ReactNode;
  text: string
  inline?: boolean
  positionX: 'left' | 'right'
  positionY: 'top' | 'bottom'
}

export const ToastComponent: FC<IProps> = memo(function ToastComponent(props) {
  const { children, text, inline, positionX, positionY } = props;
  const { css} = useStyle(ToastComponentRules, {
    positionX,
    positionY,
  });
  
  return (
    <div className={css.toastWrp} style={{display: inline? 'inline' : 'block'}}>
      {text && <p className={css.toastText}>{text}</p>}
      {children}
    </div>
  );
});
