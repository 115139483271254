import { TextBlockListResponse } from '@teleport/schemas-protobuf';

export const TextBlockTranslator = {
  fromRootTextBlockListResponse(response: TextBlockListResponse) {
    const { textBlocks } = response;

    return textBlocks.map(textBlock => {
      const { uuid, title, text, accentColor, button } = textBlock;
      return {
        uuid: uuid,
        title,
        text,
        accentColor,
        button,
      };
    });
  },
};
