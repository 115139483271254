import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { borderRadius } from 'src/utils/applyCSSProperty ';

export interface IModalRuleKeys {
  modal: {};
  modalCenter: {};
  modalContent: {};
  modalCenterContent: {};
  btnClose: {};
  // modalInner: {};
  closeBar: {};
}

export interface IModalRuleProps {
  deep: number;
  active: boolean;
  paddingTop: number;
  paddingBottom: number;
  paddingLeft: number;
  paddingRight: number;
  titleHeight: number;
  expand?: boolean;
  backgroundColor: string;
}

export type IModalRules = IRuleFn<IModalRuleKeys, IModalRuleProps>;

const modalRuleFn: IModalRules = props => {
  const { colorRgbBlack, colorWhite, colorBlack, background } = props.theme;
  const {
    active,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    backgroundColor,
    expand,
    deep,
  } = props;

  const zIndex = 100 * deep;

  return {
    modal: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: zIndex,
      top: 0,
      left: 0,
      backgroundColor: `rgba(${colorRgbBlack}, .5)`,
      transitionDuration: '0.3s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'opacity, visibility',
      visibility: active ? 'visible' : 'hidden',
      opacity: active ? 1 : 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    modalCenter: {
      width: '100vw',
      height: '100vh',
      position: 'fixed',
      zIndex: zIndex,
      top: 0,
      left: 0,
      backgroundColor: `rgba(${colorRgbBlack}, .5)`,
      transitionDuration: '0.3s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'opacity, visibility',
      visibility: active ? 'visible' : 'hidden',
      opacity: active ? 1 : 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    modalContent: {
      ...borderRadius(16, 16, 0, 0),
      position: 'relative',
      paddingTop: paddingTop ?? 16,
      paddingLeft: paddingLeft ?? 16,
      paddingRight: paddingRight ?? 16,
      paddingBottom: paddingBottom ?? 16,
      transitionDuration: '0.1s',
      transitionTimingFunction: 'linear',
      transitionProperty: 'transform',
      userSelect: 'none',
      touchAction: 'none',
      opacity: 1,
      width: '100%',
      minHeight: expand ? 'calc(100vh - 120px)' : 'auto',
      maxHeight: 'calc(100vh - 120px)',
      backgroundColor: backgroundColor ? backgroundColor : colorWhite,
      transform: active ? 'translateY(0)' : 'translateY(100%)',
    },
    modalCenterContent: {
      background: backgroundColor ? backgroundColor : background,
      borderRadius: 14,
      width: '100%',
      maxWidth: 273,
      margin: '0 auto',
    },
    // modalInner: {
    //   // touchAction: "none",
    //   maxHeight: `calc(100% - ${titleHeight}px)`,
    //   overflow: "auto",
    // },
    btnClose: {},
    closeBar: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 36,
      paddingTop: '5.5px',

      '::before': {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        content: '""',
        width: 50,
        height: 5,
        backgroundColor: colorBlack,
        borderRadius: 16,
        opacity: '.5',
      },
    },
  };
};

export const modalRules = createCachedStyle(modalRuleFn);
