import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { BlocksOrderWrapperRules } from './BlocksOrderWrapper.style';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { BlockCard } from '../BlockCard/BlockCard';
import { IUiBlockWidthId, setUiBlocks } from 'src/redux/slices/uiBlocksSlice';
import { useAppDispatch } from 'src/hooks/redux';
// https://docs.dndkit.com/presets/sortable - документация dragAndDrop

interface IProps {
  blocks: IUiBlockWidthId[];
}

export const BlocksOrderWrapper: FC<IProps> = memo(function BlocksOrderWrapper(props) {
  const { blocks } = props;
  const dispatch = useAppDispatch();

  const { css } = useStyle(BlocksOrderWrapperRules);
  const [disabledItems, setDisabledItems] = useState([]);

  useEffect(() => {
    setDisabledItems(blocks.filter(block => block.blockData.case === 'storiesBlock'));
  }, [blocks]);

  const sensors = useSensors(
    useSensor(TouchSensor, {
      // activationConstraint: {
      //   delay: 250,
      //   tolerance: 5,
      // },
    }),
    useSensor(PointerSensor),
  );

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  function handleDragStart() {
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  // Функция изменяющая порядок элементов в нашем массиве items
  function handleDragEnd(event) {
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);

    const { active, over } = event;

    if (active.id !== over.id) {
      const dragEl = blocks.find(el => el.id === active.id);
      const dropEl = blocks.find(el => el.id === over.id);

      const oldIndex = blocks.indexOf(dragEl);
      const newIndex = blocks.indexOf(dropEl);

      let newArr = arrayMove(blocks, oldIndex, newIndex);

      // После каждого перетаскивания
      // ставим заблокированные элементы на прежние места.
      const disabledItemsIndexes: Record<string, IUiBlockWidthId> = {};
      disabledItems.map(el => (disabledItemsIndexes[blocks.indexOf(el)] = el));

      newArr = newArr.filter(block => block.blockData.case !== 'storiesBlock');
      Object.entries(disabledItemsIndexes).forEach(disabledItem => {
        newArr.splice(Number(disabledItem[0]), 0, disabledItem[1]);
      });

      dispatch(setUiBlocks(newArr));
    }
  }

  return (
    <div className={css.wrapper}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={blocks} strategy={verticalListSortingStrategy}>
          {blocks.map(item => (
            <BlockCard
              key={item.id}
              blockDataInProps={item}
              disabled={item.blockData.case === 'storiesBlock'}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
});
