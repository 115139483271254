import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalTypeModifiersRuleKeys {
  header: {};
  footer: {};
  modalInner: {};
  buttonModifier: {};
  noModifiers: {};
}

interface IModalTypeModifiersRuleProps {
  headerHeight?: number;
}

export type IModalTypeModifiersRules = IRuleFn<
  IModalTypeModifiersRuleKeys,
  IModalTypeModifiersRuleProps
>;

const ModalTypeModifiersRuleFn: IModalTypeModifiersRules = props => {
  const { background, colorWhite, colorDarkGrey, mainColor } = props.theme;
  const { headerHeight } = props;

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 20,
      paddingBottom: 12,
    },
    modalInner: {
      overflow: 'auto',
      maxHeight: `calc(100% - ${headerHeight}px)`,
      display: 'flex',
      paddingBottom: 16,
      flexDirection: 'column',
    },

    buttonModifier: {
      border: 'none',
      outline: 'none',
      borderRadius: 12,
      backgroundColor: background,
      ...padding(25, 16),
      display: 'flex',
      alignItems: 'center',
    },

    noModifiers: {
      ...padding(82, 0),
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      color: colorDarkGrey,

      '& svg': {
        fill: mainColor,
        marginBottom: 12,
      },
    },

    footer: {
      position: 'sticky',
      backgroundColor: colorWhite,
      bottom: 0,
      paddingTop: 12,
      paddingBottom: 30,
    },
  };
};

export const ModalTypeModifiersRules = createCachedStyle(ModalTypeModifiersRuleFn);
