import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IStoreCreationScreenRuleKeys {
  wrapper: {};
  gif: {};
  text: {};
}

export type IStoreCreationScreenRules = IRuleFn<IStoreCreationScreenRuleKeys>;

const StoreCreationScreenRuleFn: IStoreCreationScreenRules = props => {
  const { background, colorDarkGrey } = props.theme;
  return {
    wrapper: {
      zIndex: 100,
      position: 'fixed',
      inset: 0,
      backgroundColor: background,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: 12,
      flexDirection: 'column',
      textAlign: 'center',
    },
    gif: {
      width: 130,
      height: 130,

      '& video': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    text: {
      color: colorDarkGrey,
    },
  };
};

export const StoreCreationScreenRules = createCachedStyle(StoreCreationScreenRuleFn);
