import {
  Category,
  ProductToCategoryBindRequest,
  RootCategory,
  RootCategoryListResponse,
} from '@teleport/schemas-protobuf';
import { ICategory } from '../slices/categorySlice';

export const CategoryTranslator = {
  fromRootCategoryListResponse(response: RootCategoryListResponse) {
    const { rootCategories } = response;

    return rootCategories.map(category => {
      const { uuid, title } = category;

      return {
        uuid: uuid,
        title,
      };
    });
  },

  //Add products to category
  toProductToCategoryRequest({
    category,
    productUuids,
  }: {
    category: ICategory;
    productUuids: string[];
  }) {
    const categoryValue = new RootCategory({
      uuid: `${category.uuid}`,
      title: category.title,
    });

    const categoryEntity = new Category({
      type: {
        case: 'rootCategory',
        value: categoryValue,
      },
    });

    const requestData = new ProductToCategoryBindRequest({
      category: categoryEntity,
      productUuids,
    });

    return requestData;
  },
};
