import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from '../Modal/Modal';
import { modalRatesRules } from './ModalRates.styles';
import Text from 'src/components/UI/Text/Text';
import RateCard from '../RateCard/RateCard';

interface IProps {
  onClose: () => void;
  active: boolean;
  onRateSelect: () => void;
}

const ModalRates = (props: IProps) => {
  const { active, onClose, onRateSelect } = props;

  const { css } = useStyle(modalRatesRules);

  return (
    <Modal active={active} name={ModalNames.Rates} onClose={onClose}>
      <div className={css.grid}>
        <Text text={'Тариф'} mod="title" fontWeight={800} fontSize={20} lineHeight={26} />
        <RateCard
          name={'Ежемесячный'}
          paymentFrequency={'Оплата раз в месяц'}
          pricePeerMonth={2000}
          pricePeeryear={24000}
          disabled={true}
          onButtonClick={onRateSelect}
        />
        <RateCard
          name={'Ежегодный'}
          paymentFrequency={'Оплата раз в год'}
          pricePeerMonth={1800}
          pricePeeryear={21600}
          disabled={false}
          onButtonClick={onRateSelect}
          discount={10}
        />
      </div>
    </Modal>
  );
};

export default memo(ModalRates);
