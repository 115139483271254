import { FC, memo, useEffect, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { RecomendationWrapperRules } from './RecomendationWrapper.style';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useAppDispatch } from 'src/hooks/redux';
import { SortableRecomendationCard } from '../SortableRecomendationCard/SortableRecomendationCard';
import { IRecomendation, setRecomendation } from 'src/redux/slices/recomendationSlice';

// https://docs.dndkit.com/presets/sortable - документация dragAndDrop

interface IProps {
  recomendations: IRecomendation[];
}

export const RecomendationWrapper: FC<IProps> = memo(function RecomendationWrapper(props) {
  const { recomendations } = props;
  const { css } = useStyle(RecomendationWrapperRules);
  const sensors = useSensors(
    useSensor(TouchSensor, {
      // activationConstraint: {
      //   delay: 250,
      //   tolerance: 5,
      // },
    }),
    useSensor(PointerSensor),
  );
  const isTheFirstRender = useRef(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isTheFirstRender.current) {
      isTheFirstRender.current = false;
      return;
    }
    // need fix! reorder works every time
    // dispatch(storyReorder(stories));
  }, [dispatch]);

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  function handleDragStart() {
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  // Функция изменяющая порядок элементов в нашем массиве items
  function handleDragEnd(event) {
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);

    const { active, over } = event;

    if (active.id !== over.id) {
      const dragEl = recomendations.find(el => el.id === active.id);
      const dropEl = recomendations.find(el => el.id === over.id);

      const oldIndex = recomendations.indexOf(dragEl);
      const newIndex = recomendations.indexOf(dropEl);

      const newArr = arrayMove(recomendations, oldIndex, newIndex);

      dispatch(setRecomendation(newArr));
    }
  }

  return (
    <div className={css.wrapper}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={recomendations} strategy={verticalListSortingStrategy}>
          {recomendations.map(el => (
            <SortableRecomendationCard key={el.id} data={el} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
});
