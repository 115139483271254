import { FC, memo, useEffect } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { StoreNumbersRules } from './StoreNumbers.style';
import Text from 'src/components/UI/Text/Text';
import { IconInBox } from 'src/components/UI/IconInBox/IconInBox';
import { Icon } from 'src/components/UI/Icon/Icon';
import { Link } from 'react-router-dom';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getProducts, productsState } from '../../redux/slices/productsSlice';
import { NetworkStatus } from '../../utils/network/network.constant';
import { PillSoon } from 'src/components/UI/PillSoon/PillSoon';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export const StoreNumbers: FC = memo(function StoreNumbers() {
  const { css } = useStyle(StoreNumbersRules);
  const dispatch = useAppDispatch();
  const { products, networkStatus } = useAppSelector(productsState);
  const { t } = useTranslation();

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getProducts());
    }
  }, [dispatch, networkStatus]);

  const isProductListLoading =
    networkStatus === NetworkStatus.None || networkStatus === NetworkStatus.Loading;

  return (
    <div className={css.wrapper}>
      <Link className={css.card} to={RoutePath.Products}>
        <div className={css.text}>
          <Text
            mod="title"
            text={t('storeNumbers.products')}
            fontWeight={800}
            fontSize={14}
            lineHeight={18}
            extend={css.title}
          />
          {isProductListLoading ? (
            <Text
              mod="title"
              text={'loading...'}
              fontWeight={500}
              fontSize={14}
              lineHeight={14}
            />
          ) : (
            <Text
              mod="title"
              text={String(products.length)}
              fontWeight={500}
              fontSize={24}
              lineHeight={24}
            />
          )}
        </div>
        <IconInBox name="bag" propsStyles={{ width: 40, height: 40 }} />
      </Link>
      <div className={css.card}>
        <div className={css.text}>
          <Text
            mod="title"
            text={t('storeNumbers.statistics')}
            fontWeight={800}
            fontSize={14}
            lineHeight={18}
            extend={css.title}
          />
          <div className={css.statistic}>
            <Text mod="title" text="0" fontWeight={500} fontSize={24} lineHeight={24} />
            <Icon name="people" />
          </div>
        </div>
        <PillSoon />
        {/* <IconInBox name="business" propsStyles={{ width: 40, height: 40 }} /> */}
      </div>
    </div>
  );
});
