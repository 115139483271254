import { FC, memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { modalModifierTypeRules } from './ModalModifierVariants.style';
import { Input } from '../UI/Input/Input';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../UI/Button/Button';

interface IProps {
  active: boolean;
  onClose: () => void;
  variantsArr: { id: number; txt: string }[];
  setVariantsArr: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        txt: string;
      }[]
    >
  >;
}

export const ModalModifierVariants: FC<IProps> = memo(function ModalModifierVariants(props) {
  const { active, onClose, variantsArr, setVariantsArr } = props;
  const { theme } = useTheme();

  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(modalModifierTypeRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  return (
    <Modal
      name={ModalNames.ModifierVariants}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
      // expand={true}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text="Варианты" mod="title" />
      </div>
      <div className={css.modalInner}>
        {variantsArr.map((el, i) => {
          return (
            <div key={el.id} className={css.inputWrp}>
              <Input
                controlled={true}
                iconBtn={i > 1 ? 'delete' : undefined}
                onIconBtnClick={
                  i > 1
                    ? () => {
                      setVariantsArr(prev => prev.filter(v => v.id !== el.id));
                    }
                    : undefined
                }
                name="variant"
                type="text"
                placeholder="Введите значение"
                maxLength={70}
                value={el.txt}
                onChange={e => {
                  setVariantsArr(prev =>
                    prev.map(variant => {
                      if (variant.id === el.id) {
                        return {
                          ...variant,
                          txt: e.target.value,
                        };
                      } else {
                        return variant;
                      }
                    }),
                  );
                }}
                propsStyles={{ backgroundColor: theme.background }}
              />
            </div>
          );
        })}
        <div
          onClick={() => {
            setVariantsArr(prev => [...prev, { id: Date.now(), txt: '' }]);
          }}
          className={css.inputWrp}
        >
          <p className={css.add_modifier}>Добавить еще</p>
        </div>
      </div>
      <footer className={css.footer}>
        <div className={css.inputWrp}>
          <Button
            text="Сохранить"
            propsStyles={{
              width: '100%',
            }}
            onClick={onClose}
          />
        </div>
      </footer>
    </Modal>
  );
});
