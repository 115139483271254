import { WizardTranslator } from '../../translators/wizardTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';
// import { IWizardData } from '../slices/wizardSlice';

export const wizardGetState = createAppAsyncThunk('wizard/wizardGetState', async (_, thunkAPI) => {
  const wizardState = await thunkAPI.extra.hubApi.wizardGetState({});
  const state = thunkAPI.getState();
  const config = state.config.config;

  if (wizardState.infra.portApiUrl && wizardState.infra.portApiUrl !== config.portApiUrl) {
    thunkAPI.extra.portClientApi.init(wizardState.infra.portApiUrl);
    thunkAPI.extra.portOwnerApi.init(wizardState.infra.portApiUrl);
  }

  return WizardTranslator.fromWizardStateResponse(wizardState);
});
