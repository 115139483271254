import { FC, ReactNode, memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { modalModifierTypeRules } from './ModalModifierType.style';

interface IProps {
  active: boolean;
  onClose: () => void;
  children: ReactNode;
}

export const ModalModifierType: FC<IProps> = memo(function ModalModifierType(props) {
  const { active, onClose, children } = props;

  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const { css } = useStyle(modalModifierTypeRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });

  return (
    <Modal
      name={ModalNames.ModifierType}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
      // expand={true}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text="Тип модификатора" mod="title" />
      </div>
      <div className={css.modalInner}>{children}</div>
    </Modal>
  );
});
