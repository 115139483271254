import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IStepRuleKeys {
  step: {};
  stepHeader: {};
  stepNumber: {};
  title: {};
}

export type IStepRules = IRuleFn<IStepRuleKeys>;

const stepRulesFn: IStepRules = props => {
  const {} = props.theme;

  return {
    step: {
      paddingBottom: 20,
    },
    stepHeader: { ...padding(20, 0) },
    stepNumber: {
      marginBottom: 12,
    },
    title: {
      marginBottom: 8,
    },
  };
};

export const stepRules = createCachedStyle(stepRulesFn);
