import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IModalSuccessRuleKeys {
  content: {};
  text: {};
  footer: {};
}

export type IModalSuccessRules = IRuleFn<IModalSuccessRuleKeys>;

const modalSuccessRuleFn: IModalSuccessRules = () => {
  return {
    content: {
      ...padding(48, 0),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 28,
      paddingBottom: 16,
      alignItems: 'center',
    },
    footer: {
      display: 'flex',
      columnGap: '12px',
    },
    text: {
      fontWeight: '800',
      fontSize: 20,
      lineHeight: '26px',
    },
  };
};

export const modalSuccessRules = createCachedStyle(modalSuccessRuleFn);
