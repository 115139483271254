import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { TextBlockCardRules } from './TextBlockCard.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { ITextBlock } from 'src/redux/slices/textBlockSlice';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  cardData: ITextBlock;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

// export interface ITextBlock {
//   id: string;
//   title: string;
//   text: string;
//   accentColor: boolean;
//   hasButton: boolean;
//   link?: string;
//   textButton?: string;
//   linkButton?: string;
// }

export const TextBlockCard: FC<IProps> = memo(function TextBlockCard(props) {
  const { cardData, onClick } = props;
  const { uuid, text, title, button, accentColor } = cardData;
  const { css } = useStyle(TextBlockCardRules);

  const { t } = useTranslation()

  return (
    <div className={css.card} id={uuid} onClick={onClick}>
      {accentColor && (
        <Text
          text={t('textBlockCard.accentBackgroundOfTheBlock')}
          mod="text"
          fontWeight={700}
          fontSize={12}
          lineHeight={18}
          extend={css.accentColorText}
        />
      )}
      <Text mod="title" text={title} extend={css.title} fontSize={14} lineHeight={16} />
      <Text mod="text" text={text} fontSize={12} lineHeight={16} fontWeight={500} />
      {button?.clickUrl && (
        <div className={css.linkWrap}>
          <Icon name="link" properties={{ className: css.iconLink }} />
          <Text
            mod="text"
            text={button?.clickUrl}
            fontSize={12}
            lineHeight={16}
            fontWeight={500}
            extend={css.link}
          />
        </div>
      )}
    </div>
  );
});
