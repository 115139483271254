import { ChangeEvent, FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { optionRules } from './option.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { PillSoon } from '../PillSoon/PillSoon';
import { telegramStarsString } from 'src/utils/constants';

interface IProps {
  type: 'radio' | 'checkbox';
  value: string | number;
  title: string;
  subtitle?: string;
  symbol?: string;
  disabled?: boolean;
  checked: boolean;
  bg?: string;
  onChange: (value: string) => void;
  soon?: boolean
}

export const Option: FC<IProps> = memo(function Option(props) {
  const { title, type, value, disabled, symbol, checked, bg, onChange, subtitle, soon } = props;
  const { css } = useStyle(optionRules, { disabled, bg, soon });

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    const value = event.target.value;
    onChange(value);
  };

  return (
    <label className={css.option}>
      <input
        className={css.input}
        onChange={changeValue}
        type={type}
        value={value}
        checked={checked}
      />
      <div>
        <Text text={title} mod="text" fontSize={16} fontWeight={700} lineHeight={18} />
        {subtitle && <Text text={subtitle} mod="text" fontSize={12} lineHeight={14} />}
      </div>
      {
        soon
          ? <PillSoon />
          : (
            <>
              {disabled && <Icon name="lock" />}
              {!disabled && symbol && !(telegramStarsString ===  symbol) && (
                <Text text={symbol} mod="text" fontSize={16} fontWeight={700} lineHeight={18} />
              )}
              {
                !disabled && telegramStarsString ===  symbol && (
                  <img src={`/images/currencies/${symbol}.svg`} alt='' />
                )
              }
            </>
          )
      }

    </label>
  );
});
