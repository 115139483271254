import { useCallback, useState } from 'react';

export const useModalState = (): [boolean, boolean, () => void, () => void] => {
  const [render, setRender] = useState(false);
  const [active, setActive] = useState(false);

  const openModal = useCallback(() => {
    setRender(true);
    setTimeout(() => {
      setActive(true);
    }, 100);
  }, []);

  const closeModal = useCallback(() => {
    setActive(false);
    setTimeout(() => {
      setRender(false);
    }, 300);
  }, []);

  return [render, active, openModal, closeModal];
};
