import { ChangeEvent, FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { subcategoryCardRules } from './SubcategoryCard.style';
import Text from 'src/components/UI/Text/Text';

interface IProps {
  categoryId: string;
  name: string;
  value: string;
  amountProducts?: number;
  isChecked?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SubcategoryCard: FC<IProps> = memo(function StepsIndicator(props) {
  const { name, value, amountProducts = 0, categoryId, isChecked, onChange } = props;

  const { css } = useStyle(subcategoryCardRules);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <label className={css.card}>
      <input
        type={'checkbox'}
        name={name}
        onChange={handleChange}
        data-category-id={categoryId}
        value={value}
        checked={isChecked}
      />
      <Text text={name} fontWeight={700} fontSize={16} lineHeight={18} mod="title" />
      {amountProducts && (
        <Text text={`${amountProducts} товаров`} mod="text" fontSize={12} lineHeight={14} />
      )}

    </label>
  );
});
