import { StoriesTranslator } from 'src/redux/translators/storiesTranslator';
import { IStory } from 'src/types/stories';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const storyReorder = createAppAsyncThunk(
  'stories/storyReorder',
  async (stories: IStory[], thunkAPI) => {
    const reorderData = StoriesTranslator.toStoryReorderRequest(stories);

    return thunkAPI.extra.portOwnerApi().storyReorder({ storyUuids: reorderData });
  },
);
