import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IOptionRuleKeys {
  option: {};
  input: {};
  text: {};
  icon: {};
  check: {};
}

interface IOptionRuleProps {
  disabled: boolean;
  bg?: string;
  soon?: boolean;
}

export type IOptionRules = IRuleFn<IOptionRuleKeys, IOptionRuleProps>;

const optionRuleFn: IOptionRules = props => {
  const { mainColor, background, colorWhite } = props.theme;
  const { disabled, soon, bg } = props;

  return {
    option: {
      columnGap: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '68px',
      ...padding(16),
      borderRadius: 12,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'transparent',
      backgroundColor: bg ? bg : background,

      opacity: disabled  && !soon ? 0.5 : 1,
      pointerEvents: disabled || soon ? 'none' : 'auto',

      ':has(input:checked)': {
        borderColor: mainColor,
      },
    },
    icon: {},
    input: {
      position: 'absolute',
      zIndex: '-1',
      opacity: 0,
    },
    text: {},
    check: {
      width: 20,
      height: 20,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: mainColor,

      '& svg': {
        width: 12,
        height: 12,
        fill: colorWhite,
      },
    },
  };
};

export const optionRules = createCachedStyle(optionRuleFn);
