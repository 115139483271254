import { memo, useRef } from 'react';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalTypeModifiersRules } from './ModalTypeModifiers.style';
import Text from 'src/components/UI/Text/Text';
import { IModifier } from 'src/types/modificator';
import { Icon } from 'src/components/UI/Icon/Icon';
import { Button } from 'src/components/UI/Button/Button';
import { useTheme } from 'src/utils/theme/useTheme';

interface IProps {
  modifiers: IModifier[];
  active: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose: () => void;
}

const ModalTypeModifiers = function ModalTypeModifiers(props: IProps) {
  const { active, modifiers, onClick, onClose } = props;
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  // const [modifiers] = useState([
  //   { id: '1', name: 'Размер' },
  //   { id: '2', name: 'Подарочная упаковка' },
  //   { id: '3', name: 'Комплектация' },
  // ]);
  const { css } = useStyle(ModalTypeModifiersRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
  });
  const { theme } = useTheme();

  return (
    <Modal
      active={active}
      name={ModalNames.List}
      onClose={onClose}
      propsStyles={{
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 0,
      }}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text="Тип модификатора" mod="title" textTransform={'uppercase'} />
      </div>
      <div className={css.modalInner}>
        {modifiers.length > 0 ? (
          modifiers.map(el => (
            <button onClick={onClick} className={css.buttonModifier} key={el.id} id={el.id}>
              <Text mod="title" text={el.name} fontSize={16} lineHeight={18} fontWeight={700} />
            </button>
          ))
        ) : (
          <>
            <div className={css.noModifiers}>
              <Icon name="modifier-large" />
              <Text
                mod="text"
                text="Создайте модификаторы, чтобы их добавить"
                fontSize={14}
                lineHeight={16}
                fontWeight={600}
              />
            </div>
            <div className={css.footer}>
              <Button
                text="Перейти к модификаторам"
                href="/modifiers"
                propsStyles={{ padding: '12px', background: theme.colorBlack }}
                hasGradient={false}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default memo(ModalTypeModifiers);
