import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { stepRules } from './step.style';
import Text from '../UI/Text/Text';
import Container from '../Container/Container';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

interface IProps {
  stepNumber: number;
  title: string;
  subtitle?: string;
  children;
}

export const Step: FC<IProps> = memo(function Step(props) {
  const { css } = useStyle(stepRules);
  const { title, subtitle, stepNumber, children } = props;

  const {t} = useTranslation();

  return (
    <Container>
      <div className={css.step}>
        <div className={css.stepHeader}>
          <Text
            text={t('wizardPage.step', stepNumber)}
            mod="title"
            fontSize={16}
            lineHeight={24}
            extend={css.stepNumber}
          />
          <Text text={title} mod="title" fontWeight={600} fontSize={24} extend={css.title} />
          {subtitle && (
            <Text text={subtitle} mod="text" fontWeight={500} fontSize={14} lineHeight={18} />
          )}
        </div>
        {children}
      </div>
    </Container>
  );
});
