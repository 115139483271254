import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWizardDeliveryTypeRuleKeys {
  wrapper: {};
}

export type IWizardDeliveryTypeRules = IRuleFn<IWizardDeliveryTypeRuleKeys>;

const WizardDeliveryTypeRuleFn: IWizardDeliveryTypeRules = props => {
  const {} = props.theme;

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
    },
  };
};

export const WizardDeliveryTypeRules = createCachedStyle(WizardDeliveryTypeRuleFn);
