import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
// import Footer from 'src/components/Footer/Footer';
// import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { CreateProductPageRules } from './CreateProductPage.style';
import useLoader from 'src/hooks/useLoader';
import { RoutePath } from 'src/providers/AppRouter/routeConfig';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/redux';
import { ModifiersForProduct } from 'src/blocks/create-product/ModifiersForProduct/ModifiersForProduct';
import { CategoriesForProduct } from 'src/blocks/create-product/CategoriesForProduct/CategoriesForProduct';
import { ProductForm } from 'src/blocks/create-product/ProductForm/ProductForm';
import { useScrollViewport } from 'src/hooks/useScrollViewport';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export const CreateProductPage: FC = memo(function CreateProductPage(props) {
  const {} = props;
  const { css } = useStyle(CreateProductPageRules);
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { installingModifiers, installingCategories } = useAppSelector(state => state.Product);
  const { t } = useTranslation();

  useEffect(() => {
    BackButton.onClickCustom(goBack);

    function goBack() {
      navigate(RoutePath.Products);
    }

    if (installingCategories) {
      BackButton?.offClickCustom(goBack);
    }

    return () => {
      BackButton?.offClick(goBack);
    };
  }, [BackButton, installingCategories, navigate]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const [layoutEl, setLayoutEl] = useState({ current: null });

  const layoutRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setLayoutEl({ current: node });
    }
  }, []);

  useScrollViewport(layoutEl);

  if (installingModifiers) {
    return <ModifiersForProduct />;
  }

  return (
    <>
      {installingCategories && <CategoriesForProduct />}
      <Layout
        header={
          <header className={`${css.header} header`}>
            <Text
              text={t('createProductPage.product')}
              mod="title"
              fontWeight={800}
              fontSize={20}
              lineHeight={22}
            />
          </header>
        }
        ref={layoutRef}
      >
        <Container extend={css.container}>
          <ProductForm editingProduct={false} />
        </Container>
      </Layout>
    </>
  );
});
