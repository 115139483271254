import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { Layout } from 'src/components/Layout/Layout';
import Container from 'src/components/Container/Container';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useModalState } from 'src/hooks/useModalState';
import { TextBlockCard } from 'src/blocks/settings-main/setting-text-blocks/TextBlockCard/TextBlockCard';
import { SettingTextBlocksPageRules } from './SettingTextBlocksPage.style';
import { ModalSettingTextBlocks } from 'src/blocks/settings-main/setting-text-blocks/ModalSettingTextBlocks/ModalSettingTextBlocks';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { ITextBlock, getRootTextBlockList, textBlockState } from 'src/redux/slices/textBlockSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  test?: string;
}

export const SettingTextBlocksPage: FC<IProps> = memo(function SettingTextBlocksPage(props) {
  const { textBlocks, networkStatus: textBlockNetworkStatus } = useAppSelector(textBlockState);
  const {} = props;
  const { css } = useStyle(SettingTextBlocksPageRules);
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [editBlock, setEditBlock] = useState<ITextBlock | null>(null);
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (textBlockNetworkStatus === NetworkStatus.None) {
      dispatch(getRootTextBlockList());
    }
    if (textBlockNetworkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [hideLoader, dispatch, textBlockNetworkStatus]);

  function openEditModal(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const blockId = event.currentTarget.id;
    const block = textBlocks.find(el => el.uuid === blockId);
    if (!blockId) return;
    setEditBlock(block);
    openModal();
  }

  const openCreateModal = () => {
    setEditBlock(null);
    openModal();
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('settingTextBlocksPage.textBlocks')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('settingTextBlocksPage.createBlock')}
            propsStyles={{ width: '100%' }}
            onClick={openCreateModal}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        {textBlocks.length > 0 ? (
          <div className={css.textBlocksWrapper}>
            {textBlocks.map(el => (
              <TextBlockCard cardData={el} key={el.uuid} onClick={openEditModal} />
            ))}
          </div>
        ) : (
          <NoSettings
            title={t('settingTextBlocksPage.youHaventCreatedAnyTextBlocksYet')}
            text={t('settingTextBlocksPage.createATextBlockForTheHomepage')}
            icon="text-blocks-large"
          />
        )}
      </Container>
      {renderModal && (
        <ModalSettingTextBlocks
          active={activeModal}
          onClose={closeModal}
          editTextBlock={editBlock}
        />
      )}
    </Layout>
  );
});
