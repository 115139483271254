import { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { buttonRules } from './button.styles';
import { Icon, IconList } from '../Icon/Icon';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface ButtonProps {
  text?: string;
  mod?: 'icon';
  icon?: IconList;
  href?: string;
  onClick?: () => void;
  children?: ReactNode;
  disabled?: boolean;
  active?: boolean;
  hasGradient?: boolean;
  type?: 'submit' | 'button';
  successfully?: boolean;
  transparencyInDisabledState?: boolean;
  // styles
  extend?: any;
  propsStyles?: IStyles;
}

export interface IStyles {
  width?: string | number;
  height?: string | number;
  padding?: string | number;
  background?: string;
  color?: string;
}

export const Button = memo(function Button(props: PropsWithChildren<ButtonProps>) {
  const {
    text,
    propsStyles,
    icon,
    href,
    type = 'button',
    disabled,
    extend,
    successfully,
    onClick,
    children,
    active,
    hasGradient = true,
    transparencyInDisabledState,
  } = props;

  const { css } = useStyle(buttonRules, {
    width: propsStyles?.width,
    height: propsStyles?.height,
    padding: propsStyles?.padding,
    background: propsStyles?.background,
    color: propsStyles?.color,
    successfully,
    disabled,
    hasGradient,
    active,
    transparencyInDisabledState,
  });

  const { t } = useTranslation();

  if (href) {
    return (
      <Link className={[css.button, extend].join(' ')} to={href}>
        {icon && <Icon name={icon} />}
        {text && <span className={css.text}>{text}</span>}
        {children}
      </Link>
    );
  } else {
    if (successfully) {
      return (
        <button className={[css.button, extend].join(' ')} onClick={onClick} type={type}>
          <Icon name={'check'} properties={{ className: css.checkIcon }} />
          <span className={css.text}>{t('button.saved')}</span>
        </button>
      );
    }
    return (
      <button className={[css.button, extend].join(' ')} onClick={onClick} type={type}>
        {icon && <Icon name={icon} />}
        {text && <span className={css.text}>{text}</span>}
        {children}
      </button>
    );
  }
});
