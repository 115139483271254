import { margin, padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ICreateModifierPageKeys {
  header: {};
  container: {};
  justifyWrapper: {};
  grid: {};
  templateWrapper: {};
  inputNotation: {};
}

export type ICreateModifierPage = IRuleFn<ICreateModifierPageKeys>;

const CreateModifierPageRuleFn: ICreateModifierPage = props => {
  const { mainColor, colorBlack, colorRgbBlack } = props.theme;
  const {} = props;

  return {
    templateWrapper: {
      ...margin(40, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      borderWidth: '1px',
      borderRadius: 8,
      borderStyle: 'solid',
      borderColor: colorBlack,
      backgroundColor: mainColor,
    },
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
      alignItems: 'center',
    },
    container: {
      overflow: 'hidden',
      paddingTop: 8,
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      ...padding(12, 0),
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    inputNotation: {
      marginTop: '4px',
      fontWeight: '500',
      fontSize: 12,
      lineHeight: '16px',
      letterSpacing: '-0.4px',
      color: `rgba(${colorRgbBlack}, .4)`,
      '& span': {
        color: mainColor,
      },
    },
  };
};

export const CreateModifierPageRules = createCachedStyle(CreateModifierPageRuleFn);
