import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { RateCardsRules } from './RateCard.style';
import Text from 'src/components/UI/Text/Text';
import { Button } from '../UI/Button/Button';

interface IProps {
  name: string;
  paymentFrequency: string;
  pricePeerMonth: number;
  pricePeeryear: number;
  disabled: boolean;
  discount?: number;
  onButtonClick: () => void;
}

const ModalRates = (props: IProps) => {
  const {
    name,
    paymentFrequency,
    pricePeerMonth,
    pricePeeryear,
    disabled,
    onButtonClick,
    discount,
  } = props;

  const { css } = useStyle(RateCardsRules);

  return (
    <div className={css.card}>
      <div className={css.row_wrp}>
        <div className={css.row}>
          <Text mod="text" text={name} fontSize={12} fontWeight={700} lineHeight={18} />
          <Text
            mod="text"
            text={`${pricePeerMonth} ₽ / месяц`}
            fontSize={15}
            fontWeight={800}
            lineHeight={18}
          />
        </div>
        <div className={css.row}>
          <Text
            mod="text"
            text={paymentFrequency}
            fontSize={12}
            fontWeight={500}
            lineHeight={18}
          />
          <div className={css.discount_wrp}>
            {discount && <span className={css.discount}>- {discount} %</span>}

            <Text
              mod="text"
              text={`${pricePeeryear} ₽ / год`}
              fontSize={12}
              fontWeight={600}
              lineHeight={18}
            />
          </div>
        </div>
      </div>
      <Button
        disabled={disabled}
        text="Удалить"
        propsStyles={{ width: '100%', padding: '11px 20px' }}
        hasGradient={false}
        onClick={onButtonClick}
      />
    </div>
  );
};

export default memo(ModalRates);
