import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ICreateProductPageKeys {
  header: {};
  container: {};
  wrapperJustify: {};
  wrapper: {};
}

export type ICreateProductPage = IRuleFn<ICreateProductPageKeys>;

const CreateProductPageRuleFn: ICreateProductPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {...padding(16), },
    container: {
      overflow: 'hidden',
    },
    wrapperJustify: {
      ...padding(12, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    wrapper: {
      ...padding(12, 0),
    },
  };
};

export const CreateProductPageRules = createCachedStyle(CreateProductPageRuleFn);
