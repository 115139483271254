import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingSelectionPageKeys {
  wrapper: {};
  header: {};
  container: {};
  textBlocksWrapper: {};
}

// interface ISettingSelectionPageProps {}

export type ISettingSelectionPage = IRuleFn<ISettingSelectionPageKeys>;

const SettingSelectionPageRuleFn: ISettingSelectionPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    wrapper: {},
    container: {
      overflow: 'hidden',
    },
    textBlocksWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: 8,
    },
  };
};

export const SettingSelectionPageRules = createCachedStyle(SettingSelectionPageRuleFn);
