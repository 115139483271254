import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IRateCardsRuleKeys {
  info: {};
  card: {};
  row: {};
  row_wrp: {};
  discount: {};
  discount_wrp: {};
}

export type IRateCardsRules = IRuleFn<IRateCardsRuleKeys>;

const RateCardsRuleFn: IRateCardsRules = props => {
  const { colorWhite, mainColor } = props.theme;
  return {
    card: {
      borderRadius: 16,
      boxShadow: '0px 10px 30px 0px #00000026',
      background: colorWhite,
      ...padding(24, 20),
    },
    info: {
      marginBottom: '20px',
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 20,
    },
    row_wrp: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      marginBottom: '20px',
    },
    discount: {
      ...padding(3, 8),
      borderRadius: 8,
      fontSize: '10px',
      color: colorWhite,
      background: mainColor,
      marginRight: '4px',
      fontWeight: 700,
    },
    discount_wrp: {
      display: 'flex',
    },
  };
};

export const RateCardsRules = createCachedStyle(RateCardsRuleFn);
