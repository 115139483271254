import { ISelectionWithProducts } from 'src/types/selection';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const editSelection = createAppAsyncThunk(
  'selections/editSelection',
  async (selection: ISelectionWithProducts, thunkAPI) => {
    await thunkAPI.extra.portOwnerApi().selectionUpsert({ selection });

    return selection;
  },
);
