import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Story } from './components/Story/Story';
import { storiesRules } from './stories.style';
import { useSlidesData } from './hooks/useSlidesData';

interface IProps {
  className?: string;
  propsStyles?: IStyles;
}

interface IStyles {
  paddingTop?: number;
  paddingBottom?: number;
}

export const Stories: FC<IProps> = memo(function Stories(props) {
  const { propsStyles } = props;
  const { css } = useStyle(storiesRules, {
    paddingTop: propsStyles?.paddingTop,
    paddingBottom: propsStyles?.paddingBottom,
  });

  const slides = useSlidesData();

  return (
    <div className={css.wrapper}>
      <swiper-container
        // class={css(styles.slider)}
        slides-per-view="auto"
        space-between="8"
        slides-offset-before="16"
        slides-offset-after="16"
      >
        {slides.map(slide => (
          // @ts-ignore:next-line
          <swiper-slide class={css.slide} key={slide.text}>
            <Story
              url={slide.href}
              propsStyles={{
                backgroundImage: slide.img,
              }}
              text={slide.text}
            />
          </swiper-slide>
        ))}
      </swiper-container>
    </div>
  );
});
