import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ConnectSupportRules } from './ConnectSupport.style';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useTheme } from 'src/utils/theme/useTheme';
import { useTelegram } from 'src/utils/telegramProvider';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

export const ConnectSupport: FC = memo(function ConnectSupport(props) {
  const {} = props;
  const { css } = useStyle(ConnectSupportRules);
  const { theme } = useTheme();
  const Telegram = useTelegram();
  const { t } = useTranslation();

  const connectToSupport = () => {
    if (!Telegram.webApp.initData) return;
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  return (
    <div className={css.wrapper}>
      <Button
        text={t('connectSupport.contactSupport')}
        propsStyles={{
          background: theme.colorBlack,
          width: '100%',
          height: 62,
        }}
        hasGradient={false}
        onClick={connectToSupport}
      />
      <Text
        text={t('connectSupport.inCaseYouNeedAnyHelpSettingUpYourStoreOrWantToReportABugOrYouHaveAFeatureRequest')}
        mod="text"
        fontSize={12}
        lineHeight={14}
        fontWeight={400}
        extend={css.helpText}
      />
    </div>
  );
});
