import { ChangeEvent, FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from '../UI/Text/Text';
import { optionCardRules } from './OptionCard.style';
import { PillSoon } from '../UI/PillSoon/PillSoon';

interface IProps {
  title: string;
  text: string;
  name: string;
  value: string;
  type: 'radio' | 'checkbox';
  checked?: boolean;
  disabled?: boolean;
  disabledMessage?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  soon?: boolean
  bg?: string
}

export const OptionCard: FC<IProps> = memo(function StepsIndicator(props) {
  const { title, text, name, type, value, checked, disabledMessage, disabled, onChange, soon, bg } = props;

  const { css } = useStyle(optionCardRules, {
    disabled,
    soon,
    hasDisabledMessage: Boolean(disabledMessage),
    bg: bg
  });

  return (
    <div className={css.cardWrapper}>
      {disabledMessage && (
        <Text
          text={disabledMessage}
          mod="text"
          extend={css.disabledMessage}
          fontSize={10}
          lineHeight={14}
          fontWeight={500}
        />
      )}
      <div className={css.labelWrp}>
        <label className={css.card}>
          <input type={type} name={name} onChange={onChange} value={value} checked={checked} />
          <Text text={title} fontWeight={700} fontSize={16} lineHeight={18} mod="title" />
          <Text text={text} mod="text" fontSize={12} lineHeight={14} />
        </label>
        {soon && <PillSoon />}
      </div>
    </div>
  );
});
