import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISelectionCardKeys {
  card: {};
  productsGrid: {};
  productImg: {};
  fallbackSvg: {};
  emptyBlock: {};
}

export type ISelectionCard = IRuleFn<ISelectionCardKeys>;

const SelectionCardRuleFn: ISelectionCard = props => {
  const { colorWhite, colorGrey } = props.theme;
  const {} = props;

  return {
    card: {
      padding: 12,
      borderRadius: 12,
      backgroundColor: colorWhite,
      display: 'flex',
      alignItems: 'center',
      columnGap: 16,
    },
    productsGrid: {
      minWidth: 100,
      flexShrink: 0,
      width: '31.35%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '1fr 1fr',
      gap: 4,
    },
    productImg: {
      position: 'relative',
      paddingTop: '100%',
      borderRadius: 8,
      width: 'auto',
      backgroundColor: colorGrey,
      overflow: 'hidden',

      '& img': {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: 'auto',
      },
    },
    fallbackSvg: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50%',
    },
    emptyBlock: {
      position: 'relative',
      paddingTop: '100%',
      backgroundColor: colorGrey,
      borderRadius: 8,
    },
  };
};

export const selectionCardRules = createCachedStyle(SelectionCardRuleFn);
