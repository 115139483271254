import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { addPx, padding } from 'src/utils/applyCSSProperty ';

export interface IModalErrorRuleKeys {
  content: {};
  title: {};
  text: {};
  footer: {};
}

export type IModalErrorRules = IRuleFn<IModalErrorRuleKeys>;

const modalErrorRuleFn: IModalErrorRules = () => {
  return {
    content: {
      ...padding(48, 0),
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: 28,
      paddingBottom: 16,
      alignItems: 'center',
    },
    footer: {
      display: 'flex',
      columnGap: '12px',
    },
    title: {
      fontWeight: '800',
      fontSize: 20,
      lineHeight: '26px',
    },
    text: {
      fontWeight: '500',
      fontSize: 14,
      lineHeight: addPx(18),
    },
  };
};

export const modalErrorRules = createCachedStyle(modalErrorRuleFn);
