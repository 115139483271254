import { useEffect } from 'react';
import { hasTouchSupport } from 'src/utils/hasTouchSupport';

export const useScrollViewport = (
  scrollElementRef: React.MutableRefObject<HTMLDivElement>,
  dependence1?: any,
) => {
  const scrollElement = scrollElementRef.current;

  useEffect(() => {
    if (!scrollElement) return undefined;
    const windowHeight = document.documentElement.clientHeight;
    const expanderDiv = document.createElement('div');
    expanderDiv.style.width = '100%';
    expanderDiv.style.background = 'transparent';
    const expanderStep = windowHeight;
    const distanceFromTopWindow = 40;

    function onFocus(event) {
      if (!hasTouchSupport()) return;

      const input = event.currentTarget;
      const inputWrapper: HTMLDivElement =
        event.currentTarget.closest('.inputWrapper') ||
        event.currentTarget.closest('.textareaWrapper');

      let inputCoordinates = null;
      expanderDiv.style.flexShrink = '0';

      if (inputWrapper) {
        scrollElement.appendChild(expanderDiv);
        inputCoordinates = inputWrapper.getBoundingClientRect();

        // distanceFromTopWindow + 5 - fix infinity loop

        for (let i = 1; inputCoordinates.top > distanceFromTopWindow + 5; i++) {
          expanderDiv.style.height = `${i * expanderStep}px`;
          scrollElement.scrollTop = inputWrapper.offsetTop - distanceFromTopWindow;
          inputCoordinates = inputWrapper.getBoundingClientRect();
        }
      } else {
        scrollElement.appendChild(expanderDiv);
        inputCoordinates = input.getBoundingClientRect();
        for (let i = 1; inputCoordinates.top !== distanceFromTopWindow + 5; i++) {
          expanderDiv.style.height = `${i * expanderStep}px`;
          scrollElement.scrollTop = inputWrapper.offsetTop - distanceFromTopWindow;
          inputCoordinates = input.getBoundingClientRect();
        }
      }

      setTimeout(() => {
        scrollElement.addEventListener('scroll', blockIntoViewport);
      }, 500);
    }

    function onBlur() {
      scrollElement.removeEventListener('scroll', blockIntoViewport);
      scrollElement.scrollTop = scrollElement.scrollTop + 1;
      expanderDiv.remove();
    }

    const allInputs = scrollElement.querySelectorAll('input, textarea');
    const inputs = [...allInputs].filter((el): el is HTMLInputElement => {
      if (el instanceof HTMLInputElement) {
        return el.type !== 'radio' && el.type !== 'checkbox' && el.type !== 'file';
      }
      return true;
    });

    function blockIntoViewport() {
      const activeElementCoordinates = document.activeElement.getBoundingClientRect();
      const expanderCoordinates = expanderDiv.getBoundingClientRect();
      if (
        expanderCoordinates.top < window.visualViewport.height &&
        activeElementCoordinates.top < -activeElementCoordinates.height / 2
      ) {
        expanderDiv.remove();
      }
    }

    inputs.forEach(input => {
      input.addEventListener('focus', onFocus);
      input.addEventListener('blur', onBlur);
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', onFocus);
        input.removeEventListener('blur', onBlur);
      });
      scrollElement.removeEventListener('scroll', blockIntoViewport);
    };
  }, [scrollElement, dependence1]);
};
