import { RecommendationListResponse } from '@teleport/schemas-protobuf';
import { IRecomendation } from '../slices/recomendationSlice';

export const RecomendationTranslator = {
  fromGetRecomendation(response: RecommendationListResponse): IRecomendation[] {
    const { recommendations } = response;
    return recommendations.map(el => ({
      enabled: el.enabled,
      title: el.title,
      id: el.uuid,
      visibility: el.visibility,
    }));
  },
};
