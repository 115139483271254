import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IPillSoonKeys {
  pill: {};
  text: {};
}

export type IPillSoon = IRuleFn<IPillSoonKeys>;

const PillSoonRuleFn: IPillSoon = props => {
  const { colorWhite } = props.theme;
  const {} = props;

  return {
    pill: {
      ...padding(4, 8),
      borderRadius: 8,
      background: 'linear-gradient(140deg, rgba(121,30,192,1) 0%, rgba(0,142,243,1) 100%);',
      textAlign: 'center',
    },
    text: {
      color: colorWhite,
    },
  };
};

export const PillSoonRules = createCachedStyle(PillSoonRuleFn);
