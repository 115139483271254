import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWizardHasBasketRuleKeys {
  wrapper: {};
}

export type IWizardHasBasketRules = IRuleFn<IWizardHasBasketRuleKeys>;

const wizardHasBasketRuleFn: IWizardHasBasketRules = props => {
  const {} = props.theme;

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '10px',
    },
  };
};

export const wizardHasBasketRules = createCachedStyle(wizardHasBasketRuleFn);
