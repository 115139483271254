import { FC, memo, useState } from 'react';
import { IClasses } from 'src/utils/theme/fela.interface';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { Button } from 'src/components/UI/Button/Button';
import { useModalState } from 'src/hooks/useModalState';
import ModalList from './components/ModalList/ModalList';
import { listWithCardRules } from './list.style';

interface IProps {
  extend?: IClasses;
  placeholder: string;
  title: string;
  modalTitle?: string;
  options: IListWithCardOption[];
  initialValue?: string;
  disabled?: boolean;
  propsStyles?: IStyles;
  onChange?: (value: string) => void;
}

interface IStyles {
  backgroundColor?: string;
  padding?: string;
}

export interface IListWithCardOption {
  value: string | number;
  title: string;
  subtitle?: string;
  disabled?: boolean;
  symbol?: string;
  soon?: boolean
}

export const ListWithCard: FC<IProps> = memo(function ListWithCard(props) {
  const {
    extend,
    title,
    placeholder,
    modalTitle,
    disabled,
    options,
    initialValue = null,
    propsStyles,
    onChange,
  } = props;

  const { css } = useStyle(listWithCardRules, {
    disabled,
    backgroundColor: propsStyles?.backgroundColor,
    padding: propsStyles?.padding,
  });
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [selectedEl, setSelectedEl] = useState<string | null>(initialValue);

  const changeValue = (listValue: string) => {
    setSelectedEl(listValue);
    onChange && onChange(listValue);
    closeModal()
  };

  return (
    <>
      <div className={[css.list, extend].join(' ')} onClick={openModal}>
        <div>
          <Text text={title} mod="title" fontSize={16} lineHeight={18} fontWeight={700} />
          <Text
            text={options.find(el => el.value === selectedEl)?.title || placeholder}
            mod="text"
            fontSize={12}
            lineHeight={14}
          />
        </div>
        <div className={css.rightBlock}>
          <Button
            icon="chevron-right"
            propsStyles={{
              width: 20,
              height: 20,
              padding: 0,
              background: 'transparent',
            }}
            extend={css.btnOpen}
          />
        </div>
      </div>
      {renderModal && (
        <ModalList
          options={options}
          selectedValue={selectedEl}
          active={activeModal}
          onClose={closeModal}
          onChange={changeValue}
          title={modalTitle}
        />
      )}
    </>
  );
});
