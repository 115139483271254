import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { MainPageHeaderRules } from './MainPageHeader.style';
import Text from 'src/components/UI/Text/Text';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'src/hooks/redux';
import { configState } from 'src/redux/slices/configSlice';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

// todo use real date
const date = '01.08.2024';

export const MainPageHeader: FC = memo(function MainPageHeader(props) {
  const {} = props;
  const { css } = useStyle(MainPageHeaderRules);
  const { config } = useAppSelector(configState);
  const { wizard } = useAppSelector(getWizardState);
  const { t } = useTranslation();

  return (
    <div className={css.wrapper}>
      <div className={css.generalInfo}>
        <Link className={css.shopLogoWrp} to={'/profile'}>
          <img className={css.shopLogo} src={wizard.shopLogoUrl} alt="logo" />
        </Link>
        <div>
          <Text
            mod="title"
            text={wizard.shopName}
            fontSize={14}
            fontWeight={600}
            lineHeight={16}
          />
          <Text
            mod="text"
            text={`@${config.portBotUsername}`}
            fontSize={12}
            fontWeight={500}
            lineHeight={16}
            extend={css.telegram}
          />
        </div>
      </div>
      <div className={css.subscription}>
        <Text
          mod="text"
          text={t('mainPageHeader.subscription')}
          fontSize={12}
          lineHeight={16}
          fontWeight={500}
          extend={css.subscriptionTitle}
        />
        <Text
          mod="text"
          text={t('mainPageHeader.activeUntil', date)}
          fontSize={12}
          lineHeight={16}
          fontWeight={500}
        />
      </div>
    </div>
  );
});
