import { useStyle } from 'src/utils/theme/useStyle';
import { StoreCreationScreenRules } from './StoreCreationScreen.style';
import Text from '../UI/Text/Text';
import { useTranslation } from '../../utils/i18n/hooks/useTranslation';

import { FC, memo } from 'react';

export const StoreCreationScreen: FC = memo(function StoreCreationScreen(props) {
  const {} = props;
  const { css } = useStyle(StoreCreationScreenRules);
  const { t } = useTranslation();

  return (
    <div className={css.wrapper}>
      <img src="gifs/duck.gif" className={css.gif} />
      <Text mod="title" text={t('storeCreation.weAreCreatingYourStore')} />
      <Text
        mod="text"
        text={`${t('storeCreation.ItMayTakeUpto5Minutes')}\n${t('storeCreation.InTheMeantimeYouCanCloseTheTeleport')}\n${t('storeCreation.WellSendAMessageWhenItsReady')}`}
        fontWeight={500}
        whiteSpace="pre-wrap"
        fontSize={14}
        lineHeight={18}
      />
    </div>
  );
});

export default memo(StoreCreationScreen);
