import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { storyRules } from './story.style';
import { useTelegram } from 'src/utils/telegramProvider';

interface IProps {
  className?: string;
  propsStyles: IStyles;
  url: string;
  text: string;
}

export interface IStyles {
  backgroundImage: string;
}

export const Story: FC<IProps> = memo(function Story(props) {
  const { propsStyles, url, text } = props;
  const { css } = useStyle(storyRules, {
    backgroundImage: propsStyles.backgroundImage,
  });
  const { webApp } = useTelegram();

  const openStory = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const url = event.currentTarget.dataset['url'];
    webApp.openLink(url, { try_instant_view: true });
  };

  return (
    <button data-url={url} className={css.card} onClick={openStory}>
      <span className={css.text}>{text}</span>
    </button>
  );
});
