import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IRecommendationsPageKeys {
  header: {};
  togglewrapper: {};
  wrapper: {};
}

export type IRecommendationsPage = IRuleFn<IRecommendationsPageKeys>;

const RecommendationsPageRuleFn: IRecommendationsPage = props => {
  const { sidePadding } = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(10, sidePadding, 12),
    },
    togglewrapper: {
      display: 'flex',
      padding: `12px ${sidePadding}px 12px`,
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  };
};

export const RecommendationsPageRules = createCachedStyle(RecommendationsPageRuleFn);
