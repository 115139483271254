import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IMainPageSectionKeys {
  section: {};
  wrapper: {};
  title: {};
}

export type IMainPageSection = IRuleFn<IMainPageSectionKeys>;

const MainPageSectionRuleFn: IMainPageSection = props => {
  const { colorWhite } = props.theme;

  return {
    section: {
      ...padding(12, 0),
    },
    wrapper: {
      borderRadius: 12,
      ...padding(8, 16),
      backgroundColor: colorWhite,
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      marginBottom: 8,
    },
  };
};

export const MainPageSectionRules = createCachedStyle(MainPageSectionRuleFn);
