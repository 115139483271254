import {
  Story,
  StoryDeleteRequest,
  StoryListResponse,
  StoryUpsertRequest,
} from '@teleport/schemas-protobuf';
import { IStory } from 'src/types/stories';

export const StoriesTranslator = {
  fromStoryListResponse(response: StoryListResponse) {
    const { stories } = response;

    return stories.map(product => {
      // todo support all fields
      const { uuid, clickUrl, imageUrl, text } = product;

      return {
        id: uuid,
        clickUrl,
        imageUrl,
        text,
      };
    });
  },

  toStoryDeleteRequest(story: IStory) {
    return new StoryDeleteRequest({ storyUuid: story.id });
  },

  toStoryReorderRequest(stories: IStory[]) {
    return stories.map(el => el.id);
  },

  toStoryUpsertRequest(story: IStory, newImageUrl?: string) {
    const storyEntity = new Story({
      uuid: story.id,
      clickUrl: story.clickUrl,
      imageUrl: newImageUrl ? newImageUrl : story.imageUrl,
      text: story.text,
    });

    return new StoryUpsertRequest({ story: storyEntity });
  },
};
