import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductFormRules } from './ProductForm.style';
import Toggler from 'src/components/UI/Toggler/Toggler';
import Text from 'src/components/UI/Text/Text';
import { ProductImagesLoader } from '../ProductImagesLoader/ProductImagesLoader';
import { Textarea } from 'src/components/UI/Textarea/Textarea';
import { Input } from 'src/components/UI/Input/Input';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  resetNetworkStatus,
  setInstallingCategories,
  setProductCategory,
  setProductSubcategory,
  setSelectionsForProduct,
} from '../../../redux/slices/productSlice';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { useModalState } from 'src/hooks/useModalState';
import { ModalSelectSelection } from '../ModalSelectSelection/ModalSelectSelection';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'src/components/UI/Button/Button';
import { VALIDATION_ERRORS } from 'src/types/validation';
import { configState } from 'src/redux/slices/configSlice';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { createProduct } from 'src/redux/api/products/createProduct';
import { useTheme } from 'src/utils/theme/useTheme';
import { ModalProductSuccessfulCreated } from '../ModalProductSuccessfulCreated/ModalProductSuccessfulCreated';
import { OwnerCatalogProductFull } from '@teleport/schemas-protobuf';
import { upsertProduct } from 'src/redux/api/products/upsertProduct';
import { useNavigate } from 'react-router-dom';
import { deleteProduct } from 'src/redux/api/products/deleteProduct';
import { useTelegram } from 'src/utils/telegramProvider';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { IProduct } from 'src/types/product';
import { getDiscountedPrice } from 'src/redux/translators/productsTranslator';
import ModalConfirm from 'src/components/ModalConfirm/ModalConfirm';
import { corretUrlOnPaste, validateUrl } from 'src/utils/validateUrl';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useFirstRender } from 'src/hooks/useFirstRender';
import useCurrencySymbol from 'src/hooks/useCurrencySymbol';
import { getWizardState } from 'src/redux/slices/wizardSlice';
import { linkMaxLength } from 'src/utils/constants';
import ModalError from 'src/components/ModalError/ModalError';

interface IProductFormProps {
  // fixme product type
  product?: OwnerCatalogProductFull;
  editingProduct: boolean;
}

interface IFormProduct {
  showInCatalog: boolean;
  hasDiscount: boolean;
  productName: string;
  productPrice: number;
  productDescription: string;
  linkForOrder?: string;
  linkOrCode?: string;
  percentDiscount?: number;
  files?: File[];
}

const minimumResolution = '1125×1125';

export const ProductForm: FC<IProductFormProps> = memo(function ProductForm(props) {
  const { editingProduct, product } = props;

  const { config } = useAppSelector(configState);

  const { selections, category, subcategory, createNetworkStatus, upsertNetworkStatus } =
    useAppSelector(state => state.Product); // todo create product selector

  const Telegram = useTelegram();
  const dispatch = useAppDispatch();

  const { theme } = useTheme();

  const navigate = useNavigate();

  const [footerHeight, setFooterHeight] = useState(0);

  const { css } = useStyle(ProductFormRules, { footerHeight });

  const { t, tPlural } = useTranslation();

  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  
  const {
    wizard: wizardData,
  } = useAppSelector(getWizardState);

  const [defaultValues] = useState<Partial<IFormProduct>>({
    showInCatalog: product ? Boolean(product?.enabled) : true,
    productName: product?.title || '',
    productPrice:
      (product?.price.discountEnabled
        ? Number(product?.price.oldAmount)
        : Number(product?.price.amount)) || null,
    productDescription: product?.description || '',
    hasDiscount: Boolean(product?.price.discountEnabled),
    percentDiscount: product?.price.discountInPercent || null,
    linkForOrder:
      product?.deliveryAction.case === 'deliveryLink' ? product.deliveryAction.value : '',
    linkOrCode:
      product?.deliveryAction.case === 'deliveryRedeemCode' ? product.deliveryAction.value : '',
    files: [],
  });

  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  const { colorBlack, colorGrey } = theme;

  const [renderSuccessModal, activeSuccessModal, openSuccessModal, closeSuccessModal] =
    useModalState();
  const [renderDeleteModal, activeDeleteModal, openDeleteModal, closeDeleteModal] = useModalState();

  useEffect(() => {
    return () => {
      dispatch(setProductSubcategory(null));
      dispatch(setProductCategory(null));
      dispatch(setInstallingCategories(false));
      dispatch(setSelectionsForProduct([]));
    };
  }, [dispatch]);

  useEffect(() => {
    if (editingProduct && upsertNetworkStatus === NetworkStatus.Done) {
      // todo refactor me
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
        navigate('/products');
        dispatch(resetNetworkStatus('upsertNetworkStatus'));
      }, 1500);
    } else if (createNetworkStatus === NetworkStatus.Done) {
      openSuccessModal();
      dispatch(resetNetworkStatus('createNetworkStatus'));
    }
  }, [
    upsertNetworkStatus,
    createNetworkStatus,
    editingProduct,
    dispatch,
    navigate,
    openSuccessModal,
  ]);

  const modalFooterRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setFooterHeight(node.clientHeight);
    }
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
    control,
  } = useForm<IFormProduct>({
    defaultValues: defaultValues,
  });

  const onSubmit: SubmitHandler<IFormProduct> = data => {
    const {
      hasDiscount,
      productDescription,
      productName,
      productPrice,
      showInCatalog,
      linkForOrder,
      linkOrCode,
      percentDiscount,
      files,
    } = data;

    const requestData: { productData: IProduct; deliveryType: DeliveryType; files: File[] } = {
      productData: {
        id: '',
        enabled: showInCatalog,
        price: {
          amount: productPrice,
          discountEnabled: hasDiscount,
          discountInPercent: percentDiscount,
          oldAmount: 0,
        },
        productName,
        productDescription,
        linkForOrder,
        linkOrCode,
        category,
        subCategory: subcategory,
        selections,
        imageUrls: product?.imageUrls ?? [],
      },
      deliveryType: config.deliveryType,
      files,
    };

    if (editingProduct) {
      requestData.productData.id = product.uuid;
      dispatch(upsertProduct(requestData));
    } else {
      dispatch(createProduct(requestData));
    }
  };

  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const hasDiscount = watch('hasDiscount');

  useEffect(() => {
    if (!hasDiscount) {
      setValue('percentDiscount', null);
    }
  }, [hasDiscount, setValue]);

  // const setProductModifiers = () => {
  //   dispatch(setInstallingModifiers(true));
  // };

  const setProductCategories = () => dispatch(setInstallingCategories(true));

  const deleteProductFn = () => {
    dispatch(deleteProduct(product.uuid)).then(() => {
      navigate('/products');
    });
  };

  const closeConfirmModal = (isClickOk: boolean) => {
    if (isClickOk) {
      deleteProductFn();
    }
  };

  const onClickDelete = () => {
    if (Telegram.webApp.initData) {
      Telegram.webApp.showConfirm('Удалить товар?', closeConfirmModal);
    } else {
      openDeleteModal();
      // deleteProductFn();
    }
  };

  const handleFilesChange = (files: File[]) => {
    setValue('files', files);
  };

  const handleChangeUrlsOrder = (imageUrls: string[]) => {
    if (product) {
      product.imageUrls = imageUrls;
    }
  };

  const deleteImageUrls = url => {
    if (!product?.imageUrls) return;
    product.imageUrls = product.imageUrls.filter(el => el !== url);
  };

  const isFirstRender = useFirstRender();

  const currentCurrencySymbol = useCurrencySymbol(wizardData?.currency);

  useEffect(() => {
    if (createNetworkStatus === NetworkStatus.None && upsertNetworkStatus === NetworkStatus.None) return
    if (createNetworkStatus === NetworkStatus.Failed || upsertNetworkStatus === NetworkStatus.Failed) {
      openErrorModal();
    }
  }, [createNetworkStatus, upsertNetworkStatus, dispatch, openErrorModal])
  
  const closeErrorModalFunction = () => {
    closeErrorModal()
    dispatch(resetNetworkStatus('createNetworkStatus'))
    dispatch(resetNetworkStatus('upsertNetworkStatus'))
  }

  const onChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { inputType } = event.nativeEvent as InputEvent;
    if (inputType === 'insertFromPaste') {
      const newVal = corretUrlOnPaste(event)
      setValue('linkForOrder', newVal);
    }else {
      setValue('linkForOrder', event.target.value.trim().replace(/^H/, 'h'));
    }
  }

  return (
    <>
      <form className={css.wrapper} onSubmit={handleSubmit(onSubmit)}>
        <div className={css.wrapperJustify}>
          <Text
            text={t('productForm.showInCatalog')}
            mod="title"
            fontSize={12}
          />
          <Controller
            name="showInCatalog"
            control={control}
            render={({ field: { name, onChange } }) => (
              <Toggler
                name={name}
                checked={watch('showInCatalog')}
                value="show-in-catalog"
                onChange={onChange}
              />
            )}
          />
        </div>
        <ProductImagesLoader
          name="product-images"
          message={t(
            'productForm.minimumResolutionIsNumberFirstImageIsMainOrderCanBeChangedByDragging',
            minimumResolution,
          )}
          deleteImageUrls={deleteImageUrls}
          onChangeFile={handleFilesChange}
          onChangeUrlsOrder={handleChangeUrlsOrder}
          initialImages={product?.imageUrls}
        />
        <Textarea
          {...register('productName', { required: VALIDATION_ERRORS.REQUIRED })}
          controlled={false}
          label={t('productForm.name')}
          placeholder={t('productForm.enterProductName')}
          maxLength={70}
          letterCounter={true}
          errorMessage={errors.productName?.message}
        />
        <Input
          {...register('productPrice', {
            required: VALIDATION_ERRORS.REQUIRED,
            valueAsNumber: true,
          })}
          controlled={false}
          label={t('productForm.price')}
          type="number"
          placeholder={t('productForm.enterProductPrice')}
          maxLength={70}
          symbol={currentCurrencySymbol}
          errorMessage={errors.productPrice?.message}
        />
        <div className={css.wrapperJustify}>
          <Text
            text={t('productForm.discount')}
            mod="title"
            fontSize={12}
          />
          <Controller
            name="hasDiscount"
            defaultValue={product?.price.discountEnabled}
            control={control}
            render={({ field: { name, onChange } }) => (
              <Toggler
                name={name}
                value="discount"
                checked={Boolean(watch('hasDiscount'))}
                onChange={onChange}
              />
            )}
          />
        </div>
        {watch('hasDiscount') && (
          <Input
            {...register('percentDiscount', {
              valueAsNumber: true,
              max: {
                value: 100,
                message: t('productForm.cannotBeMoreThan100'),
              },
              required: VALIDATION_ERRORS.REQUIRED,
            })}
            placeholder={t('productForm.enterDiscountPercent')}
            autoFocus={isFirstRender ? false : true}
            type="number"
            controlled={false}
            symbol="%"
            errorMessage={errors.percentDiscount?.message}
            message={t('productForm.discountedPrice', getDiscountedPrice(watch('productPrice'), watch('percentDiscount')), currentCurrencySymbol)}
          />
        )}
        <Textarea
          {...register('productDescription')}
          controlled={false}
          label={t('productForm.description')}
          placeholder={t('productForm.enterProductDescription')}
          maxLength={1000}
        />

        {/* This StoreType will appear in the future */}
        {/* {storeType === StoreType.ELECTRONIC && (
        <>
          <div className={css.wrapperJustify}>
            <Text
              text="Многоразовый код или ссылка"
              mod="title"

              fontSize={12}
            />
            <Toggler name="code-or-link" value="code-or-link" onChange={onChangeHasCode} />
          </div>
          {hasCode && (
            <Input
              controlled={true}
              name="percent-discount"
              type="string"
              placeholder="Вставьте код или ссылку"
              value={codeValue}
              onChange={onChangeCode}
            />
          )}
        </>
      )} */}

        {config.deliveryType === DeliveryType.SHOWCASE && (
          <Input
            {...register('linkForOrder', {
              required: VALIDATION_ERRORS.REQUIRED,
              validate: {
                checkUrl: validateUrl,
              },
              maxLength: {
                value: linkMaxLength,
                message: tPlural('productForm.linkShouldBeLessThanCharacters', linkMaxLength)
              },
            })}
            controlled={false}
            label={t('productForm.linkToOrderTheProduct')}
            type="string"
            placeholder={t('productForm.enterLink')}
            errorMessage={errors.linkForOrder?.message}
            maxLength={2000}
            onChange={onChangeFunction}
          />
        )}
        {config.deliveryType === DeliveryType.DIGITAL && (
          <Input
            {...register('linkOrCode', { required: VALIDATION_ERRORS.REQUIRED })}
            controlled={false}
            label={t('productForm.reusableCodeOrLink')}
            type="string"
            placeholder={t('productForm.enterCodeOrLink')}
            errorMessage={errors.linkOrCode?.message}
          />
        )}

        {/* // Если включены подкатегории, то товары хранятся в них !!!
      // Получается при включенных подкатегориях, нельзя создать товар без подкатегории? */}
        <ListButton
          title={t('productForm.categories')}
          onClick={setProductCategories}
          bg="transparent"
          placeholder={category ? category.title : t('productForm.chooseFromTheList')}
        />
        {/* {storeType === StoreType.PHYSCIAL && (
        <ListButton
          title="Модификатор товара"
          onClick={setProductModifiers}
          bg="transparent"
          placeholder={'Выберите из списка и заполните'}
        />
      )} */}

        <ListButton
          title={t('productForm.addToSelection')}
          onClick={openModal}
          bg="transparent"
          placeholder={selections.length > 0
            ? tPlural('productForm.countSelections', selections.length)
            : t('productForm.chooseFromList')
          }
        />
        {renderModal && <ModalSelectSelection active={activeModal} onClose={closeModal} />}
        {editingProduct && (
          <div className={css.removeBtnWrapper}>
            <Button
              text="Удалить"
              propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
              hasGradient={false}
              onClick={onClickDelete}
            />
          </div>
        )}
        <div className={css.footer} ref={modalFooterRef}>
          <Button
            disabled={createNetworkStatus === NetworkStatus.Loading}
            text={editingProduct ? t('productForm.save') : t('productForm.createProduct')}
            successfully={savedSuccessfully}
            propsStyles={{ width: '100%' }}
            type="submit"
          />
        </div>
      </form>
      {renderSuccessModal && (
        <ModalProductSuccessfulCreated
          showInCatalog={getValues('showInCatalog')}
          active={activeSuccessModal}
          onClose={closeSuccessModal}
        />
      )}
      {renderDeleteModal && (
        <ModalConfirm
          active={activeDeleteModal}
          onClose={closeDeleteModal}
          title="Удалить товар?"
          confirmAction={deleteProductFn}
        />
      )}
      {renderErrorModal && (
        <ModalError
          deep={2}
          onClose={closeErrorModalFunction}
          title={t('productForm.error')}
          active={activeErrorModal}
        />
      )}
    </>
  );
});
