import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { ModifiersForProductRules } from './ModifiersForProduct.style';
import { setInstallingModifiers } from '../../../redux/slices/productSlice';
import { useAppDispatch } from 'src/hooks/redux';
import { useModalState } from 'src/hooks/useModalState';
import { SettingProductModifier } from './components/SettingProductModifier/SettingProductModifier';
import ModalTypeModifiers from './components/ModalTypeModifiers/ModalTypeModifiers';
import { IModifier, ModifierType, getModifierTypeName } from 'src/types/modificator';

export const ModifiersForProduct: FC = memo(function ModifiersForProduct(props) {
  const {} = props;
  const { css } = useStyle(ModifiersForProductRules);
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const [productModifiers] = useState<IModifier[]>([
    {
      id: '1',
      name: 'Размер',
      type: ModifierType.VARIANTS,
      content: [
        { id: '1', text: 'xs', value: 'xs', active: false, affectPrice: 0 },
        { id: '2', text: 's', value: 's', active: false, affectPrice: 0 },
        { id: '3', text: 'm', value: 'm', active: false, affectPrice: 0 },
        { id: '4', text: 'l', value: 'l', active: false, affectPrice: 0 },
        { id: '5', text: 'xl', value: 'xl', active: false, affectPrice: 0 },
      ],
      active: false,
      affectPrice: false,
    },
    {
      id: '2',
      name: 'Подарочная твердая упаковка',
      type: ModifierType.TOGGLER,
      active: false,
      affectPrice: true,
      affectPriceValue: '0',
    },
    {
      id: '3',
      name: 'Комплектация',
      type: ModifierType.LIST,
      active: false,
      content: [
        { id: '1', text: 'Бедная', value: 'bad', active: false, affectPrice: 0 },
        { id: '2', text: 'Обычная', value: 'medium', active: false, affectPrice: 0 },
        { id: '3', text: 'Роскошная', value: 'luxury', active: false, affectPrice: 0 },
      ],
      affectPrice: true,
    },
  ]);
  const [storeModifiers] = useState<IModifier[]>([
    {
      id: '1',
      name: 'Размер',
      type: ModifierType.VARIANTS,
      content: [
        { id: '1', text: 'xs', value: 'xs', active: false, affectPrice: 0 },
        { id: '2', text: 's', value: 's', active: false, affectPrice: 0 },
        { id: '3', text: 'm', value: 'm', active: false, affectPrice: 0 },
        { id: '4', text: 'l', value: 'l', active: false, affectPrice: 0 },
        { id: '5', text: 'xl', value: 'xl', active: false, affectPrice: 0 },
      ],
      active: false,
      affectPrice: false,
    },
    {
      id: '2',
      name: 'Подарочная твердая упаковка',
      type: ModifierType.TOGGLER,
      active: false,
      affectPriceValue: '0',
      affectPrice: false,
    },
    {
      id: '3',
      name: 'Комплектация',
      type: ModifierType.LIST,
      active: false,
      content: [
        { id: '1', text: 'Бедная', value: 'bad', active: false, affectPrice: 0 },
        { id: '2', text: 'Обычная', value: 'medium', active: false, affectPrice: 0 },
        { id: '3', text: 'Роскошная', value: 'luxury', active: false, affectPrice: 0 },
      ],
      affectPrice: true,
    },
  ]);

  const dispatch = useAppDispatch();
  const setProductModifiers = useCallback(
    (state: boolean) => {
      dispatch(setInstallingModifiers(state));
    },
    [dispatch],
  );
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [settingModifier, setSettingModifcator] = useState<null | IModifier>(null);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      setProductModifiers(false);
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, setProductModifiers]);

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const selectModifier = event => {
    const modifierId = event.currentTarget.id;
    const modifier = productModifiers.find(el => el.id === modifierId);
    if (!modifier) return;
    setSettingModifcator(modifier);
  };

  const endSettingModifier = () => {
    setSettingModifcator(null);
  };

  if (settingModifier) {
    return <SettingProductModifier modifier={settingModifier} endSetting={endSettingModifier} />;
  }

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text="Модификаторы товара"
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button text="Добавить модификатор" propsStyles={{ width: '100%' }} onClick={openModal} />
        </Footer>
      }
    >
      {/* <button
        onClick={() => setProductModifiers(false)}
        style={{ position: 'absolute', left: '40px', top: '40px' }}
      >
        Назад
      </button> */}
      <Container extend={css.container}>
        {productModifiers.length > 0 ? (
          <div className={css.modifierWrapper}>
            {productModifiers.map(item => (
              <div id={item.id} key={item.id} className={css.modifier} onClick={selectModifier}>
                <Text
                  mod="title"
                  fontSize={14}
                  fontWeight={700}
                  lineHeight={18}
                  text={item.name}
                />
                <Text
                  mod="text"
                  fontSize={12}
                  fontWeight={500}
                  lineHeight={16}
                  text={`${getModifierTypeName(item.type)} ${item.affectPrice ? '· Влияет на цену' : ''}`}
                />
              </div>
            ))}
          </div>
        ) : (
          <NoSettings text={'Добавьте модификатор и заполните его'} icon="modifier-large" />
        )}
      </Container>
      {renderModal && (
        <ModalTypeModifiers
          active={activeModal}
          modifiers={storeModifiers}
          onClose={closeModal}
          onClick={selectModifier}
        />
      )}
    </Layout>
  );
});
