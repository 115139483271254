import { FC, memo, } from 'react';
import { AccentColor } from 'src/components/AccentColor/AccentColor';
import { Step } from 'src/components/Step/Step';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
interface IWizardAccentColor {
  accentColor: string;
  setAccentColor: (color: string) => void;
}

export const WizardAccentColor: FC<IWizardAccentColor> = memo(function WizardAccentColor(props) {
  const {accentColor, setAccentColor} = props;

  const {t} = useTranslation();

  return (
    <Step
      stepNumber={2}
      title={t('wizardStepAccent.chooseAccentColor')}
      subtitle={t('wizardStepAccent.willBeUsedForButtonsAndOtherElements')}
    >
      <AccentColor
        accentColor={accentColor}
        setAccentColor={setAccentColor}
      />
    </Step>
  );
});
