import { SliderTranslator } from 'src/redux/translators/sliderTransaltor';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const getSliderList = createAppAsyncThunk('slider/getSlider', async (_, thunkAPI) => {
  const result = await thunkAPI.extra.portOwnerApi().sliderList({});
  const sliders = result.sliderBlocks;

  if (sliders.length === 0) {
    // "Создать слайд при отсутсвии слайдера, будет означать что мы создаём слайдер"
    // const slider = new SliderBlock({ uuid: '', items: [] });
    // const { sliderUuid } = await client.sliderUpsert({ slider });
    // slider.uuid = sliderUuid;
    // return slider;
    return null;
  } else {
    const slider = sliders[0];
    return SliderTranslator.fromSliderBlock(slider);
  }
});
