import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalListRuleKeys {
  header: {};
  modalInner: {};
}

export type IModalListRules = IRuleFn<IModalListRuleKeys>;

const modalListRuleFn: IModalListRules = props => {
  const { appFooterHeight } = props.theme;

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 20,
      paddingBottom: 12,
    },
    modalInner: {
      overflow: 'auto',
      maxHeight: `calc(100% - ${appFooterHeight}px)`,
      display: 'flex',
      flexDirection: 'column',
    },
  };
};

export const modalListRules = createCachedStyle(modalListRuleFn);
