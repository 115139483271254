import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModifierCardKeys {
  card: {};
}

export type IModifierCard = IRuleFn<IModifierCardKeys>;

const ModifierCardRuleFn: IModifierCard = props => {
  const { colorWhite } = props.theme;

  return {
    card: {
      borderRadius: 12,
      backgroundColor: colorWhite,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
      ...padding(12),
    },
  };
};

export const ModifierCardRules = createCachedStyle(ModifierCardRuleFn);
