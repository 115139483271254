import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IStepsIndicatorRuleKeys {
  steps: {};
  stepBar: {};
  stepBarActive: {};
}

export type IStepsIndicatorRules = IRuleFn<IStepsIndicatorRuleKeys>;

const stepsIndicatorFn: IStepsIndicatorRules = props => {
  const { colorGrey, mainColor } = props.theme;

  return {
    steps: {
      // position: "sticky",
      // top: 0,
      // width: "100%",
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      columnGap: 8,
    },
    stepBar: {
      flexGrow: 1,
      borderRadius: 12,
      backgroundColor: colorGrey,
      height: 4,
    },
    stepBarActive: {
      backgroundColor: `${mainColor}!important`,
    },
  };
};

export const stepsIndicators = createCachedStyle(stepsIndicatorFn);
