import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IModalRatesRuleKeys {
  grid: {};
}

export type IModalRatesRules = IRuleFn<IModalRatesRuleKeys>;

const modalRatesRuleFn: IModalRatesRules = () => {
  return {
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      paddingTop: 20,
    },
  };
};

export const modalRatesRules = createCachedStyle(modalRatesRuleFn);
