import { Category, Filter } from '@teleport/schemas-protobuf';
import { ProductsTranslator } from 'src/redux/translators/productsTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const getProductsByCategory = createAppAsyncThunk(
  'products/getProductsByCategory',
  async (categories: Category[], thunkAPI) => {
    const filters = categories.map(
      el =>
        new Filter({
          filterDefaultState: { case: 'categoryFilter', value: { category: el } },
        }),
    );
    const result = await thunkAPI.extra.portOwnerApi().ownerCatalogProductList({ filters });

    return ProductsTranslator.fromProductListResponse(result);
  },
);
