import { useEffect } from 'react';
import { hasTouchSupport } from 'src/utils/hasTouchSupport';

// !!! modalInner must have style:
// {position: relative}

export const useScrollModal = (
  modalInnerRef: React.MutableRefObject<HTMLDivElement>,
  dependence1?: any,
) => {
  const modalInner = modalInnerRef.current;

  useEffect(() => {
    if (!modalInner) return undefined;
    const windowHeight = document.documentElement.clientHeight;
    const expanderDiv = document.createElement('div');
    expanderDiv.style.width = '100%';
    expanderDiv.style.background = 'transparent';
    const expanderStep = 50;

    function onFocus(event) {
      if (!hasTouchSupport()) return;

      const input = event.currentTarget;
      const inputWrapper: HTMLDivElement =
        event.currentTarget.closest('.inputWrapper') ||
        event.currentTarget.closest('.textareaWrapper');

      let inputCoordinates = null;

      if (inputWrapper) {
        modalInner.appendChild(expanderDiv);
        modalInner.style.overflow = 'hidden';
        inputCoordinates = inputWrapper.getBoundingClientRect();
        for (let i = 1; inputCoordinates.top > (windowHeight - 100) / 2; i++) {
          expanderDiv.style.height = `${i * expanderStep}px`;
          modalInner.scrollTop = inputWrapper.offsetTop - 40;
          inputCoordinates = inputWrapper.getBoundingClientRect();
        }
      } else {
        modalInner.appendChild(expanderDiv);
        modalInner.style.overflow = 'hidden';
        inputCoordinates = input.getBoundingClientRect();
        for (let i = 1; inputCoordinates.top > (windowHeight - 100) / 2; i++) {
          expanderDiv.style.height = `${i * expanderStep}px`;
          modalInner.scrollTop = input.offsetTop - 40;
          inputCoordinates = input.getBoundingClientRect();
        }
      }
    }

    function onBlur() {
      setTimeout(() => {
        modalInner.style.overflow = '';
        expanderDiv.remove();
        expanderDiv.style.height = '';
      }, 0);
    }

    const allInputs = modalInner.querySelectorAll('input, textarea');
    const inputs = [...allInputs].filter(el => {
      if (el instanceof HTMLInputElement) {
        return el.type !== 'radio' && el.type !== 'checkbox' && el.type !== 'file';
      }
      return true;
    });

    inputs.forEach(input => {
      input.addEventListener('focus', onFocus);
      input.addEventListener('blur', onBlur);
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener('focus', onFocus);
        input.removeEventListener('blur', onBlur);
      });
    };
  }, [modalInner, dependence1]);
};
