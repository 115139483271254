import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SlideCardRules } from './SlideCard.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ISlide } from 'src/types/slider';
import { ImageComponent } from 'src/components/ImageComponent/ImageComponent';

interface IProps {
  slideData: ISlide;
  sortableData?: {
    attributes: DraggableAttributes;
    listeners: SyntheticListenerMap;
    transform: string;
    transition: string;
    setNodeRef: (node: HTMLElement) => void;
  };
  openEditModal: (event: any) => void;
}

export const SlideCard: FC<IProps> = memo(function SlideCard(props) {
  const { slideData, openEditModal } = props;
  const { imageUrl, clickUrl, id } = slideData;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const { css } = useStyle(SlideCardRules, {
    transform: CSS.Transform.toString(transform),
    transition,
  });

  return (
    <div className={css.card} id={`${id}`} {...attributes} ref={setNodeRef} onClick={openEditModal}>
      <ImageComponent
        className={css.image}
        alt="slide-img"
        src={imageUrl}
        fallback={<Icon name="no-image" properties={{ className: css.image}} />}
      />
      <div className={css.linkWrap}>
        <Icon name="link" properties={{ className: css.iconLink }} />
        <Text
          mod="text"
          text={clickUrl}
          fontSize={12}
          lineHeight={16}
          fontWeight={500}
          extend={css.link}
        />
      </div>
      <button {...listeners} className={css.buttonDrag}>
        <Icon name="bars" properties={{ className: css.iconBars }} />
      </button>
    </div>
  );
});
