import {cloneElement, ReactElement} from 'react';

export const replaceComponents = (text: string, components: ReactElement[]) => {
  const result = [];
  let lastIndex = 0;

  for (const match of text.matchAll(/<(\d+)>(.*?)<\/\1>/g)) {
    const componentIndex = parseInt(match[1], 10);
    const Component = components[componentIndex];

    if (Component !== undefined) {
      const start = match.index;
      const end = match.index + match[0].length;
      const children = match[2];

      result.push(text.substring(lastIndex, start));
      result.push(cloneElement(Component, {key: start, children}));

      lastIndex = end;
    }
  }

  result.push(text.substring(lastIndex));

  return result;
};
