import { useSortable } from '@dnd-kit/sortable';
import { FC } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { CSS } from '@dnd-kit/utilities';
import { SortableImageRules } from './SortableImage.style';
import { Icon } from 'src/components/UI/Icon/Icon';

interface IProps {
  id: string;
  src: string;
  deleteImageFn: (fileId) => void;
}

export const SortableImage: FC<IProps> = props => {
  const { id, src, deleteImageFn } = props;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });
  const { css } = useStyle(SortableImageRules, {
    transform: CSS.Transform.toString(transform),
    transition,
  });

  const onClickDeleteButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const id = event.currentTarget.previousElementSibling.id;
    deleteImageFn && deleteImageFn(id);
  };

  return (
    <div className={css.sortableWrapper}>
      <div id={id} className={css.image} {...attributes} ref={setNodeRef} {...listeners}>
        <img src={src} alt={''} />
      </div>
      <button
        className={`${css.deleteButton} sortableDeleteButton`}
        type="button"
        onClick={onClickDeleteButton}
      >
        <Icon name="cross" />
      </button>
    </div>
  );
};
