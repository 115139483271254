import { FC, memo } from 'react';
// import { SpinnerRules } from './Spinner.style';

interface IProps {
  extend?: string;
  width?: number;
}

export const Spinner: FC<IProps> = memo(function Loader(props) {
  const { extend, width } = props;
  // const { css } = useStyle(SpinnerRules);

  return (
    <div
      className={[extend, 'lds-spinner'].join(' ')}
      style={{ width: width ? `${width}px` : '80px', height: width ? `${width}px` : '80px' }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
});
