import { SliderBlock } from '@teleport/schemas-protobuf';
import { ISlide, ISlider } from 'src/types/slider';
import { v4 } from 'uuid';

export const SliderTranslator = {
  // async fromSliderListResponse(response: SliderListResponse) {},
  fromSliderBlock(slider: SliderBlock) {
    const sliderWithIds = {
      ...slider,
      items: slider.items.map<ISlide>(item => ({
        id: v4(),
        imageUrl: item.imageUrl,
        clickUrl: item.clickUrl,
      })),
    };
    return sliderWithIds;
  },

  fromISlider(slider: ISlider, slideUpdateData?: { slideId: string; newImageUrl: string }) {
    const items = slider.items.map(el => {
      if (el.id === slideUpdateData.slideId) {
        return {
          clickUrl: el.clickUrl,
          imageUrl: slideUpdateData.newImageUrl ? slideUpdateData.newImageUrl : el.imageUrl,
        };
      }
      return {
        clickUrl: el.clickUrl,
        imageUrl: el.imageUrl,
      };
    });
    const sliderBlock = new SliderBlock({ uuid: slider.uuid, items });
    return sliderBlock;
  },
};
