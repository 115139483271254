import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISortableRecomendationCardKeys {
  card: {};
  image: {};
  info: {};
  iconBars: {};
  buttonDrag: {};
}

interface ISortableRecomendationCardProps {
  transform: any;
  transition: any;
}

export type ISortableRecomendationCard = IRuleFn<
  ISortableRecomendationCardKeys,
  ISortableRecomendationCardProps
>;

const SortableRecomendationCardRuleFn: ISortableRecomendationCard = props => {
  const { colorDarkGrey, colorWhite } = props.theme;
  const { transform, transition } = props;

  return {
    card: {
      touchAction: 'manipulation',
      // touchAction: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      ...padding(16, 12),
      display: 'grid',
      columnGap: 16,
      gridTemplateColumns: 'auto auto',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: colorWhite,
      borderRadius: 12,
      transform,
      transition,
    },
    image: {
      borderRadius: 8,
      objectFit: 'cover',
      width: 70,
      height: 100,
    },
    info: { flexShrink: 2 },
    iconBars: {
      flexShrink: 0,
      width: 20,
      height: 20,
      fill: colorDarkGrey,
    },
    buttonDrag: {
      border: 'none',
      background: 'none',
      // width: 30,
      // height: 30,
      outline: 'none',
      touchAction: 'none',
    },
  };
};

export const SortableRecomendationCardRules = createCachedStyle(SortableRecomendationCardRuleFn);
