import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IStoryCardInSettingsKeys {
  card: {};
  image: {};
  info: {};
  text: {};
  iconLink: {};
  iconBars: {};
  linkWrap: {};
  link: {};
  buttonDrag: {};
}

interface IStoryCardInSettingsProps {
  transform: any;
  transition: any;
}

export type IStoryCardInSettings = IRuleFn<IStoryCardInSettingsKeys, IStoryCardInSettingsProps>;

const StoryCardInSettingsRuleFn: IStoryCardInSettings = props => {
  const { colorRgbBlack, colorDarkGrey, colorWhite } = props.theme;
  const { transform, transition } = props;

  return {
    card: {
      touchAction: 'manipulation',
      // touchAction: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      ...padding(12),
      display: 'grid',
      columnGap: 16,
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',
      backgroundColor: colorWhite,
      borderRadius: 12,
      transform,
      transition,
    },
    image: {
      borderRadius: 8,
      objectFit: 'cover',
      width: 70,
      height: 100,
    },
    info: { flexShrink: 2 },
    text: { marginBottom: 12 },
    linkWrap: {
      display: 'flex',
      columnGap: '4px',
      color: `rgba(${colorRgbBlack}, .5)`,
    },
    iconLink: {
      width: 16,
      height: 16,
      fill: `rgba(${colorRgbBlack}, .5)`,
    },
    link: {
      wordWrap: 'anywhere',
    },
    iconBars: {
      flexShrink: 0,
      width: 20,
      height: 20,
      fill: colorDarkGrey,
    },
    buttonDrag: {
      border: 'none',
      background: 'none',
      // width: 30,
      // height: 30,
      outline: 'none',
      touchAction: 'none',
    },
  };
};

export const StoryCardInSettingsRules = createCachedStyle(StoryCardInSettingsRuleFn);
