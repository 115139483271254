import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingEditCategoryPageKeys {
  header: {};
  container: {};
  justifyWrapper: {};
  grid: {};
  deleteButtonWrapper: {};
}

export type ISettingEditCategoryPage = IRuleFn<ISettingEditCategoryPageKeys>;

const SettingEditCategoryPageRuleFn: ISettingEditCategoryPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {...padding(16), },
    container: {
      overflow: 'hidden',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    deleteButtonWrapper: {
      marginTop: 'auto',
      paddingBottom: 8,
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    grid: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
  };
};

export const SettingEditCategoryPageRules = createCachedStyle(SettingEditCategoryPageRuleFn);
