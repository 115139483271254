import { FC, memo, useEffect } from 'react';
import { ReactComponent as SvgShop } from 'src/assets/svg/shop.svg';
import { useStyle } from 'src/utils/theme/useStyle';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from '../UI/Button/Button';
import Footer from '../Footer/Footer';
import { storeNoteWorkingRules } from './storeNotWorking.style';
import { Layout } from '../Layout/Layout';
import useLoader from 'src/hooks/useLoader';
import { useTelegram } from 'src/utils/telegramProvider';

interface IProps {
  reasonText: string;
  supportLink?: string;
}

const StoreNotWorking: FC<IProps> = memo(function StoreNotWorking(props) {
  const { css } = useStyle(storeNoteWorkingRules);
  const { theme } = useTheme();
  const { hideLoader } = useLoader();
  const { reasonText } = props;
  const Telegram = useTelegram();

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  return (
    <div className={css.app}>
      <Layout>
        <div className={css.wrapper}>
          <SvgShop className={css.img} />
          <span className={[css.text, css.title].join(' ')}>Хаб временно не работает</span>
          <span className={[css.text, css.subtitle].join(' ')}>{reasonText}</span>
        </div>

        <Footer propsStyles={{ padding: '8px 16px', backgroundColor: theme.background }}>
          <Button
            text="Связаться с поддержкой"
            onClick={connectToSupport} 
            propsStyles={{
              width: '100%',
              height: 62,
              background: theme.colorBlack,
            }}
          />
        </Footer>
      </Layout>
    </div>
  );
});

export default StoreNotWorking;
