import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IListWithCardKeys {
  btnOpen: {};
  list: {};
  rightBlock: {};
}

interface IListWithCardProps {
  backgroundColor?: string;
  padding?: string;
  disabled: boolean;
}

export type IListWithCards = IRuleFn<IListWithCardKeys, IListWithCardProps>;

const listWithCardRuleFn: IListWithCards = props => {
  const { colorWhite, colorBlack } = props.theme;
  const { disabled, backgroundColor, padding } = props;

  return {
    list: {
      pointerEvents: disabled ? 'none' : 'auto',
      opacity: disabled ? 0.5 : 1,
      padding: padding ? padding : '16px',
      backgroundColor: backgroundColor ? backgroundColor : colorWhite,
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '9px',
    },
    btnOpen: {
      borderRadius: 0,

      '& svg': {
        flexShrink: 0,
        fill: colorBlack,
      },
    },
    rightBlock: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
    },
  };
};

export const listWithCardRules = createCachedStyle(listWithCardRuleFn);
