import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalListRuleKeys {
  header: {};
  modalInner: {};
}

interface IModalListRuleProps {
  headerHeight?: number;
}

export type IModalListRules = IRuleFn<IModalListRuleKeys, IModalListRuleProps>;

const modalListRuleFn: IModalListRules = props => {
  const {} = props.theme;
  const { headerHeight } = props;

  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 20,
      paddingBottom: 12,
    },
    modalInner: {
      overflow: 'auto',
      maxHeight: `calc(100% - ${headerHeight}px)`,
      display: 'flex',
      rowGap: 8,
      paddingBottom: 16,
      flexDirection: 'column',
    },
  };
};

export const modalListRules = createCachedStyle(modalListRuleFn);
