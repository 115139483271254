import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ITextRuleKeys {
  form: {};
  textBlock: {};
}

export type ITextRules = IRuleFn<ITextRuleKeys>;

const welcomeMessageRuleFn: ITextRules = () => {

  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    textBlock: {
      display: 'block'
    }
  };
};

export const welcomeMessageRules = createCachedStyle(welcomeMessageRuleFn);
