import { FC, memo } from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { OptionCard } from 'src/components/OptionCard/OptionCard';
import { wizardHasBasketRules } from './wizardHasBasket.style';
import { DeliveryType } from '@teleport/schemas-protobuf';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface IProps {
  deliveryType: DeliveryType;
  setHasBasket: (value: BASKET | null) => void;
  hasBasket: BASKET;
}

export enum BASKET {
  HAS_BASKET = 'has_basket',
  NO_BASKET = 'no_basket',
}

export const WizardHasBasket: FC<IProps> = memo(function WizardHasBasket(props) {
  const { deliveryType, hasBasket, setHasBasket } = props;
  const { css } = useStyle(wizardHasBasketRules);

  const {t} = useTranslation();

  const disabledBasket = deliveryType === DeliveryType.SHOWCASE;

  const onChangeBasket = event => {
    const value: BASKET = event.target.value;
    setHasBasket(value);
  };

  const isSelected = value => {
    return hasBasket === value;
  };

  return (
    <Step
      stepNumber={5}
      title={t('wizardStepBasket.doYouNeedCart')}
      subtitle={t('wizardStepBasket.cartEnablesOrderOfMoreThanOneProductAndPromoCodesUsage')}
    >
      <div className={css.wrapper}>
        <OptionCard
          name="order-method"
          title={t('wizardStepBasket.yesINeedCart')}
          text={t('wizardStepBasket.customerWillBeAbleToAddMultipleProductsAndCreateAnOrderFromCart')}
          type="radio"
          value={BASKET.HAS_BASKET}
          disabled={disabledBasket}
          disabledMessage={disabledBasket ? t('wizardStepBasket.cartIsIncompatibleWithShowcaseStoreType') : ''}
          checked={disabledBasket ? false : isSelected(BASKET.HAS_BASKET)}
          onChange={onChangeBasket}
        />
        <OptionCard
          name="order-method"
          title={t('wizardStepBasket.noWithoutCart')}
          text={t('wizardStepBasket.customerWillBeAbleToOrderOneProductFromProductPageAtATime')}
          type="radio"
          value={BASKET.NO_BASKET}
          checked={isSelected(BASKET.NO_BASKET)}
          onChange={onChangeBasket}
        />
      </div>
    </Step>
  );
});
