type CSSProperty = 'padding' | 'margin' | 'border-radius'; 
type CSSValues = number | string ;

type CSSPropertyObject = {
  [key: string]: CSSValues;
};
export const applyCSSProperty = (property: CSSProperty, ...values: CSSValues[]): CSSPropertyObject => {
  if (property === 'border-radius') {
    switch (values.length) {
      case 1:
        return {
          [`${property}`]: `${values[0]}px`,
        };
      case 2:
        return {
          [`${property}`]: `${values[0]}px ${values[1]}px`,
        };
      case 3:
        return {
          [`${property}`]: `${values[0]}px ${values[1]}px ${values[2]}px`,
        };
      case 4:
        return {
          [`${property}`]: `${values[0]}px ${values[1]}px ${values[2]}px ${values[3]}px`,
        };
      default:
        throw new Error('Invalid number of values provided for border-radius');
    }
  } else {
    const [top, right, bottom, left] = values;

    switch (values.length) {
      case 1:
        return {
          [`${property}-top`]: top,
          [`${property}-right`]: top,
          [`${property}-bottom`]: top,
          [`${property}-left`]: top,
        };
      case 2:
        return {
          [`${property}-top`]: top,
          [`${property}-bottom`]: top,
          [`${property}-right`]: right,
          [`${property}-left`]: right,
        };
      case 3:
        return {
          [`${property}-top`]: top,
          [`${property}-right`]: right,
          [`${property}-bottom`]: bottom,
          [`${property}-left`]: right,
        };
      case 4:
        return {
          [`${property}-top`]: top,
          [`${property}-right`]: right,
          [`${property}-bottom`]: bottom,
          [`${property}-left`]: left,
        };
      default:
        throw new Error('Invalid number of values provided');
    }
  }
};

export const addPx = (value: number) => {
  return `${value}px`
}

export const padding = (...props: CSSValues[]) => {
  return applyCSSProperty('padding', ...props);
}
export const margin = (...props: CSSValues[]) => {
  return applyCSSProperty('margin', ...props);
}
export const borderRadius = (...props: CSSValues[]) => {
  return applyCSSProperty('border-radius', ...props);
}
