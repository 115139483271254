import { StoriesTranslator } from 'src/redux/translators/storiesTranslator';
import { createAppAsyncThunk } from '../../../hooks/redux';
import { IStoryData } from './createStory';

export const editStory = createAppAsyncThunk(
  'stories/editStory',
  async ({ story, file }: IStoryData, thunkAPI) => {
    let imageUrls: string[] = [];
    if (file) {
      try {
        imageUrls = await thunkAPI.extra.filesUpload([file]);
      } catch (e) {
        console.info('Upload files failed: ', e);
      }
    }

    const storyUpsertRequest = StoriesTranslator.toStoryUpsertRequest(story, imageUrls[0]);

    await thunkAPI.extra.portOwnerApi().storyUpsert(storyUpsertRequest);

    return { ...story, imageUrl: storyUpsertRequest.story.imageUrl };
  },
);
