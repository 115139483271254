import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ITextRuleKeys {
  form: {};
  textBlock: {};
  wrapperJustify: {};
}

export type ITextRules = IRuleFn<ITextRuleKeys>;

const maintenanceEnabledFormRuleFn: ITextRules = () => {

  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    textBlock: {
      display: 'block'
    },
    wrapperJustify: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  };
};

export const maintenanceEnabledFormRules = createCachedStyle(maintenanceEnabledFormRuleFn);
