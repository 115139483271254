import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { CategoryCardRules } from './CategoryCard.style';
import Text from 'src/components/UI/Text/Text';
import { Icon } from 'src/components/UI/Icon/Icon';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ICategory } from 'src/redux/slices/categorySlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

interface IProps {
  categoryData: ICategory;
  sortableData?: {
    attributes: DraggableAttributes;
    listeners: SyntheticListenerMap;
    transform: string;
    transition: string;
    setNodeRef: (node: HTMLElement) => void;
  };
  isSubCategory?: boolean;
}

export const CategoryCard: FC<IProps> = memo(function CategoryCard(props) {
  const { categoryData, isSubCategory } = props;
  const { uuid, title, subcategories } = categoryData;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: uuid });

  const { css } = useStyle(CategoryCardRules, {
    transform: CSS.Transform.toString(transform),
    transition,
  });

  const navigate = useNavigate();

  const { t } = useTranslation()

  return (
    <div
      onClick={() => navigate(`/${isSubCategory ? 'subcategory' : 'category'}/${uuid}`)}
      className={css.card}
      id={`${uuid}`}
      {...attributes}
      ref={setNodeRef}
    >
      <div className={css.info}>
        <Text mod="title" text={title} fontSize={14} lineHeight={16} />
        <Text
          mod={'text'}
          text={subcategories ? t('categoryCard.subcategories') : t('categoryCard.products')}
          fontWeight={500}
          fontSize={12}
          lineHeight={16}
        />
      </div>
      <button {...listeners} className={css.buttonDrag} onClick={e => e.stopPropagation()}>
        <Icon name="bars" properties={{ className: css.iconBars }} />
      </button>
    </div>
  );
});
