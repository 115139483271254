import { ProductType } from '@teleport/schemas-protobuf';
import { IListStoreFocusOption } from './ListStoreFocus';
import { useMemo } from 'react';
import { useTranslation } from '../../../../utils/i18n/hooks/useTranslation';

export const useProductTypeList = (): IListStoreFocusOption[] => {
  const {t} = useTranslation();

  return useMemo(() => [
    {
      text: t('productType.electronics'),
      value: ProductType.ELECTRONICS,
      icon: 'electronics',
    },
    {
      text: t('productType.clothes'),
      value: ProductType.CLOTH,
      icon: 'clothes',
    },
    {
      text: t('productType.shoes'),
      value: ProductType.SHOES,
      icon: 'shoes',
    },
    {
      text: t('productType.homeAndGarden'),
      value: ProductType.GARDEN,
      icon: 'home',
    },
    {
      text: t('productType.kids'),
      value: ProductType.CHILDREN,
      icon: 'children',
    },
    {
      text: t('productType.healthAndBeauty'),
      value: ProductType.BEAUTY,
      icon: 'beauty',
    },
    {
      text: t('productType.homeAppliances'),
      value: ProductType.HOUSEHOLD_APPLIANCES,
      icon: 'household',
    },
    {
      text: t('productType.sportAndLeisure'),
      value: ProductType.SPORT,
      icon: 'sport',
    },
    {
      text: t('productType.homeImprovement'),
      value: ProductType.REPAIR,
      icon: 'construction',
    },
    {
      text: t('productType.food'),
      value: ProductType.FOOD,
      icon: 'food',
    },
    {
      text: t('productType.pharmacy'),
      value: ProductType.PHARMACY,
      icon: 'pharmacy',
    },
    {
      text: t('productType.pets'),
      value: ProductType.PET,
      icon: 'animals',
    },
    {
      text: t('productType.books'),
      value: ProductType.BOOK,
      icon: 'book',
    },
    {
      text: t('productType.tourismFishingHunting'),
      value: ProductType.TOURISM,
      icon: 'tourism',
    },
    {
      text: t('productType.carPartsAndAccessories'),
      value: ProductType.AUTOMOTIVE,
      icon: 'automotive',
    },
    {
      text: t('productType.furniture'),
      value: ProductType.FURNITURE,
      icon: 'furniture',
    },
    {
      text: t('productType.hobbyAndArt'),
      value: ProductType.HOBBY,
      icon: 'hobby',
    },
    {
      text: t('productType.jewellery'),
      value: ProductType.JEWELRY,
      icon: 'jewerly',
    },
    {
      text: t('productType.accessories'),
      value: ProductType.ACCESSORIES,
      icon: 'accessories',
    },
    {
      text: t('productType.gamesAndConsoles'),
      value: ProductType.GAMING,
      icon: 'gaming',
    },
    {
      text: t('productType.officeSupplies'),
      value: ProductType.STATIONERY,
      icon: 'stationery',
    },
    {
      text: t('productType.adult'),
      value: ProductType.ADULT,
      icon: 'adult',
    },
    {
      text: t('productType.antiquesAndCollections'),
      value: ProductType.ANTIQUE_COLLECTIBLES,
      icon: 'antique',
    },
    {
      text: t('productType.digital'),
      value: ProductType.DIGITAL,
      icon: 'digital',
    },
    {
      text: t('productType.cleaningAndHygiene'),
      value: ProductType.HYGIENE,
      icon: 'hygiene',
    },
    {
      text: t('productType.musicAndVideo'),
      value: ProductType.MUSIC_VIDEO,
      icon: 'music',
    },
    {
      text: t('productType.cars'),
      value: ProductType.VEHICLE,
      icon: 'vehicle',
    },
  ], [t]);
}
