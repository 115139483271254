import { CourseType, EventType, ProductType, ServiceType } from '@teleport/schemas-protobuf';

enum WIZARD_STEPS {
  NAME_AND_LOGO = 'shopName',
  COLOR = 'color',
  PRODUCT_TYPE = 'productType',
  ORDER_METHOD = 'orderMethod',
  HAS_BASKET = 'hasBasket',
}

type StoreFocus =
  | {
      value: ProductType;
      case: 'productType';
    }
  | {
      value: ServiceType;
      case: 'serviceType';
    }
  | {
      value: CourseType;
      case: 'courseType';
    }
  | {
      value: EventType;
      case: 'eventType';
    }
  | { case: undefined; value?: undefined };

type StoreFocusCases = 'productType' | 'serviceType' | 'courseType' | 'eventType';

type WizardSteps = WIZARD_STEPS | 'start' | 'end';

export { WizardSteps, WIZARD_STEPS, StoreFocus, StoreFocusCases };
