import { FC, memo } from 'react';
import Text from 'src/components/UI/Text/Text';
import { IModifier, getModifierTypeName } from 'src/types/modificator';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModifierCardRules } from './ModifierCard.style';

interface IProps {
  modifierData: IModifier;
  onClick: () => void;
}

export const ModifierCard: FC<IProps> = memo(function ModifierCard(props) {
  const { modifierData, onClick } = props;
  const { id, name } = modifierData;
  const { css } = useStyle(ModifierCardRules);

  return (
    <div className={css.card} id={`${id}`} onClick={onClick}>
      <Text mod="title" text={name} fontSize={14} lineHeight={16} />
      <Text
        mod={'text'}
        text={getModifierTypeName(modifierData.type)}
        fontWeight={500}
        fontSize={12}
        lineHeight={16}
      />
    </div>
  );
});
