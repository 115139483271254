export function getToggles(toggles: Record<string, boolean>) {
  const {
    tg_stars_enabled: tgStarsEnabled,
  } = toggles ?? {};


  return {
    tgStarsEnabled,
  };
}
