import { MainPage } from 'src/pages/MainPage/MainPage';
import { RouteObject } from 'react-router-dom';
import { NotFoundPage } from 'src/pages/NotFoundPage/NotFoundPage';
import { WizardPage } from 'src/pages/WizardPage/WizardPage';
// import { SubcategoryPage } from 'src/pages/SubcategoryPage/SubcategoryPage';
import { CategoryPage } from 'src/pages/CategoryPage/CategoryPage';
import { SettingStoriesPage } from 'src/pages/SettingStoriesPage/SettingStoriesPage';
import { SettingTextBlocksPage } from 'src/pages/SettingTextBlocksPage/SettingTextBlocksPage';
import { SettingSliderPage } from 'src/pages/SettingSliderPage/SettingSliderPage';
import { SettingSelectionPage } from 'src/pages/SettingSelectionsPage/SettingSelectionsPage';
import { SettingCategoriesPage } from 'src/pages/SettingCategoriesPage/SettingCategoriesPage';
import { SettingCreateCategoryPage } from 'src/pages/SettingCreateCategoryPage/SettingCreateCategoryPage';
import { SettingEditCategoryPage } from 'src/pages/SettingEditCategoryPage/SettingEditCategoryPage';
import { ProductsPage } from 'src/pages/ProductsPage/ProductsPage';
import { CreateProductPage } from 'src/pages/CreateProductPage/CreateProductPage';
// import { SettingCreateSubCategoryPage } from 'src/pages/SettingCreateSubCategoryPage/SettingCreateSubCategoryPage';
// import { SettingEditSubCategoryPage } from 'src/pages/SettingEditSubCategoryPage/SettingEditSubCategory';
import { ModifiersPage } from 'src/pages/ModifiersPage/ModifiersPage';
import { CreateModifierPage } from 'src/pages/CreateModifierPage/CreateModifierPage';
import { SettingBlocksOrder } from 'src/pages/SettingBlocksOrder/SettingBlocksOrder';
import { EditProductPage } from 'src/pages/EditProductPage/EditProductPage';
import { EdiModifierPage } from 'src/pages/EditModifierPage/EditModifierPage';
import { ProfilePage } from 'src/pages/ProfilePage/ProfilePage';
import { SubscriptionPage } from 'src/pages/SubscriptionPage/SubscriptionPage';
import { StoreSetupPage } from 'src/pages/StoreSetupPage/StoreSetupPage';
import { SelectColorPage } from 'src/pages/SelectColorPage/SelectColorPage';
import { OrderingAndDeliveryPage } from 'src/pages/OrderingAndDeliveryPage/OrderingAndDeliveryPage';
import { RecommendationsPage } from 'src/pages/RecommendationsPage/RecommendationsPage';

export enum AppRoutes {
  Main = 'Main',
  Wizard = 'Wizard',
  Profile = 'Profile',
  // Subcategory = 'Subcategory',
  Modifiers = 'Modifiers',
  CreateModifier = 'CreateModifier',
  Category = 'Category',
  EditCategory = 'EditCategory',
  EditModifier = 'EditModifier',
  // EditSubcategory = 'EditSubcategory',
  SettingsStories = 'SettingStories',
  SettingsCategories = 'SettingCategories',
  SettingsCreateCategory = 'SettingsCreateCategory',
  // SettingsCreateSubcategory = 'SettingsCreateSubcategory',
  SettingsTextBlocks = 'SettingTextBlocks',
  SettingsSlider = 'SettingSlider',
  SettingsBlocksOrder = 'SettingBlocksOrder',
  SettingsSelections = 'SettingSelection',
  Products = 'Products',
  CreateProduct = 'CreateProduct',
  EditProduct = 'EditProduct',
  Subscription = 'Subscription',
  StoreSetup = 'StoreSetup',
  SelectColor = 'SelectColor',
  OrderingAndDelivery = 'OrderingAndDelivery',
  Recomendation = 'Recomendation',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.Main]: '/',
  [AppRoutes.Wizard]: '/wizard',
  [AppRoutes.Profile]: '/profile',
  // [AppRoutes.Subcategory]: '/subcategory/:subcategoryId',
  [AppRoutes.Modifiers]: '/modifiers',
  [AppRoutes.CreateModifier]: '/create-modifier',
  [AppRoutes.EditModifier]: '/edit-modifier/:modifierId',
  [AppRoutes.Category]: '/category/:categoryId',
  [AppRoutes.EditCategory]: '/edit-category/:categoryId',
  // [AppRoutes.EditSubcategory]: '/edit-subcategory/:subcategoryId',
  [AppRoutes.SettingsCategories]: '/setting-сategories',
  [AppRoutes.SettingsCreateCategory]: '/create-category',
  // [AppRoutes.SettingsCreateSubcategory]: '/create-subcategory/:categoryId',
  [AppRoutes.SettingsStories]: '/setting-stories',
  [AppRoutes.SettingsTextBlocks]: '/text-blocks',
  [AppRoutes.SettingsSlider]: '/setting-slider',
  [AppRoutes.SettingsBlocksOrder]: '/setting-blocks-order',
  [AppRoutes.SettingsSelections]: '/setting-selection',
  [AppRoutes.Products]: '/products',
  [AppRoutes.CreateProduct]: '/create-product',
  [AppRoutes.EditProduct]: '/edit-product/:productId',
  [AppRoutes.Subscription]: '/subscription',
  [AppRoutes.StoreSetup]: '/store-setup',
  [AppRoutes.SelectColor]: '/select-color',
  [AppRoutes.OrderingAndDelivery]: '/ordering-and-delivery',
  [AppRoutes.Recomendation]: '/recomendation',
};

export const routeConfig: RouteObject[] = [
  {
    path: RoutePath.Main,
    element: <MainPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.Wizard,
    element: <WizardPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath.Profile,
    element: <ProfilePage />,
    errorElement: <NotFoundPage />,
  },
  // {
  //   path: RoutePath.Subcategory,
  //   element: <SubcategoryPage />,
  //   errorElement: <NotFoundPage />,
  // },
  {
    path: RoutePath[AppRoutes.Modifiers],
    element: <ModifiersPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.EditModifier],
    element: <EdiModifierPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.CreateModifier],
    element: <CreateModifierPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.Category],
    element: <CategoryPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.EditCategory],
    element: <SettingEditCategoryPage />,
    errorElement: <NotFoundPage />,
  },
  // {
  //   path: RoutePath[AppRoutes.EditSubcategory],
  //   element: <SettingEditSubCategoryPage />,
  //   errorElement: <NotFoundPage />,
  // },
  {
    path: RoutePath[AppRoutes.SettingsCategories],
    element: <SettingCategoriesPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.SettingsCreateCategory],
    element: <SettingCreateCategoryPage />,
    errorElement: <NotFoundPage />,
  },
  // {
  //   path: RoutePath[AppRoutes.SettingsCreateSubcategory],
  //   element: <SettingCreateSubCategoryPage />,
  //   errorElement: <NotFoundPage />,
  // },
  {
    path: RoutePath[AppRoutes.SettingsStories],
    element: <SettingStoriesPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.SettingsTextBlocks],
    element: <SettingTextBlocksPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.SettingsSlider],
    element: <SettingSliderPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.SettingsBlocksOrder],
    element: <SettingBlocksOrder />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.SettingsSelections],
    element: <SettingSelectionPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.Products],
    element: <ProductsPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.CreateProduct],
    element: <CreateProductPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.EditProduct],
    element: <EditProductPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.Subscription],
    element: <SubscriptionPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.StoreSetup],
    element: <StoreSetupPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.SelectColor],
    element: <SelectColorPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.OrderingAndDelivery],
    element: <OrderingAndDeliveryPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: RoutePath[AppRoutes.Recomendation],
    element: <RecommendationsPage />,
    errorElement: <NotFoundPage />,
  },
];
