import { FC, memo, useEffect, useState } from 'react';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import useLoader from 'src/hooks/useLoader';
import useBackButton from 'src/hooks/useBackButton';
import { RecommendationsPageRules } from './RecommendationsPage.style';
import { useNavigate } from 'react-router-dom';
import { RecomendationWrapper } from 'src/blocks/recomendation/RecomendationWrapper/RecomendationWrapper';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  recomendationState,
  resetNetworkStatus,
  setSimilarProductEnabled,
} from 'src/redux/slices/recomendationSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getRecomendations } from 'src/redux/api/recomendation/getRecomendations';
import { selectionSettingsGet } from 'src/redux/api/selections/selectionSettingsGet';
import { updateSimilarProductEnabled } from 'src/redux/api/recomendation/updateSimilarProductEnabled';
import { recommendationReorder } from 'src/redux/api/recomendation/recommendationReorder';
import ModalError from 'src/components/ModalError/ModalError';
import { useModalState } from 'src/hooks/useModalState';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

export const RecommendationsPage: FC = memo(function SelectColorPage() {
  const { hideLoader } = useLoader();
  const { css } = useStyle(RecommendationsPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const {
    networkStatus,
    recomendations,
    recomendationSettingsNetworkStatus,
    similarProductEnabled,
    recomendationReorderStatus,
    similarProductNetworkStatus,
  } = useAppSelector(recomendationState);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getRecomendations());
    }
    if (similarProductNetworkStatus === NetworkStatus.Done) {
      dispatch(getRecomendations());
      dispatch(resetNetworkStatus('similarProductNetworkStatus'));
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, similarProductNetworkStatus, dispatch, hideLoader]);

  useEffect(() => {
    if (recomendationSettingsNetworkStatus === NetworkStatus.None) {
      dispatch(selectionSettingsGet());
    }
  }, [recomendationSettingsNetworkStatus, dispatch]);

  useEffect(() => {
    function goBack() {
      navigate(-1);
    }

    BackButton.onClickCustom(goBack);

    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  // result requests
  useEffect(() => {
    if (
      recomendationReorderStatus === NetworkStatus.Failed ||
      similarProductNetworkStatus === NetworkStatus.Failed
    ) {
      openErrorModal();
      setDisabledSaveButton(false);
    }
    if (recomendationReorderStatus === NetworkStatus.Done) {
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
        setDisabledSaveButton(false);
        dispatch(resetNetworkStatus('recomendationReorderStatus'));
        // dispatch(resetNetworkStatus('similarProductNetworkStatus'));
      }, 1500);
    }
    if (similarProductNetworkStatus === NetworkStatus.Done) {
      setDisabledSaveButton(false);
    }
    if (
      recomendationReorderStatus === NetworkStatus.Loading ||
      similarProductNetworkStatus === NetworkStatus.Loading
    ) {
      setDisabledSaveButton(true);
    }
  }, [recomendationReorderStatus, similarProductNetworkStatus, dispatch, openErrorModal]);

  const onClickSaveButton = () => {
    // dispatch(updateSimilarProductEnabled(similarProductEnabled));
    dispatch(recommendationReorder(recomendations));
  };

  const onChangeSimilarProduct = () => {
    dispatch(setSimilarProductEnabled(!similarProductEnabled));
    dispatch(updateSimilarProductEnabled(!similarProductEnabled));
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('recommendationsPage.recommendations')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            transparencyInDisabledState={false}
            disabled={disabledSaveButton}
            successfully={savedSuccessfully}
            onClick={onClickSaveButton}
            text={t('recommendationsPage.save')}
            propsStyles={{ width: '100%' }}
          />
        </Footer>
      }
    >
      <div className={css.wrapper}>
        <div className={css.togglewrapper}>
          <div>
            <Text text={t('recommendationsPage.similarProducts')} mod="title" fontSize={12} />
            <Text
              text={t('recommendationsPage.weWillShowOtherProductsFromTheSameCategorySubcategoryOnTheProductPage')}
              mod="text"
              fontSize={12}
              lineHeight={14}
            />
          </div>
          <Toggler
            name="similar-products"
            value="similar-products"
            checked={similarProductEnabled}
            onChange={onChangeSimilarProduct}
          />
        </div>
        <RecomendationWrapper recomendations={recomendations} />
      </div>
      {renderErrorModal && (
        <ModalError onClose={closeErrorModal} title={'Ошибка'} active={activeErrorModal} />
      )}
    </Layout>
  );
});
