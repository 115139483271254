import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWrapperRuleKeys {
  wrapper: {};
}

interface IWrapperRuleProps {
  backgroundColor: string;
  borderRadius: string | number;
  padding?: string;
}

export type IWrapperRules = IRuleFn<IWrapperRuleKeys, IWrapperRuleProps>;

const wrapperRuleFn: IWrapperRules = props => {
  const { colorWhite } = props.theme;
  const { backgroundColor, padding, borderRadius } = props;

  return {
    wrapper: {
      padding: padding ? padding : '28px 0 0',
      borderRadius: borderRadius,
      backgroundColor: backgroundColor || colorWhite,
    },
  };
};

export const wrapperRules = createCachedStyle(wrapperRuleFn);
