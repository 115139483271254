import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IListRuleKeys {
  btnOpen: {};
  list: {};
  rightBlock: {};
}

interface IListRuleProps {
  disabled: boolean;
  soon?: boolean
}

export type IListRules = IRuleFn<IListRuleKeys, IListRuleProps>;

const listRuleFn: IListRules = props => {
  const { colorWhite, colorBlack } = props.theme;
  const { disabled, soon } = props;

  return {
    list: {
      pointerEvents: disabled ? 'none' : 'auto',
      opacity: disabled && !soon ? 0.5 : 1,
      ...padding(16),
      backgroundColor: colorWhite,
      borderRadius: 12,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      columnGap: '9px',
    },
    btnOpen: {
      borderRadius: 0,

      '& svg': {
        fill: colorBlack,
      },
    },
    rightBlock: {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
    },
  };
};

export const listRules = createCachedStyle(listRuleFn);
