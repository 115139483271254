import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalSettingStoryKeys {
  modalInner: {};
  header: {};
  imageLoader: {};
  info: {};
  textBlock: {};
  linkBlock: {};
  footer: {};
  removeBtnWrapper: {};
}

interface IModalSettingStoryProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalSettingStory = IRuleFn<IModalSettingStoryKeys, IModalSettingStoryProps>;

const ModalSettingStoryRuleFn: IModalSettingStory = props => {
  const { colorRgbBlack, mainColor, sidePadding, colorWhite } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: `${footerHeight}px`,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    imageLoader: {
      marginBottom: 12,
    },
    info: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      color: `rgba(${colorRgbBlack}, .4)`,

      '& a': {
        color: mainColor,
      },
    },
    textBlock: { marginBottom: 24 },
    linkBlock: { marginBottom: 32 },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    removeBtnWrapper: {
      ...padding(8, 0),
    },
  };
};

export const ModalSettingStoryRules = createCachedStyle(ModalSettingStoryRuleFn);
