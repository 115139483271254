import { DeliveryType } from '@teleport/schemas-protobuf';
import {
  Category,
  OwnerCatalogProductDetailResponse,
  OwnerCatalogProductFull,
  OwnerCatalogProductListResponse,
  Price,
  RootCategory,
  SelectionBlock,
  SubCategory,
} from '@teleport/schemas-protobuf';
import { ICategoryState } from 'src/components/ModalProductCategories/ModalProductCategories';
import { ICategory, ISubCategory } from 'src/types/categories';
import { IProduct } from 'src/types/product';

export const ProductsTranslator = {
  fromProductListResponse(response: OwnerCatalogProductListResponse): IProduct[] {
    const { products } = response;

    return products.map(product => {
      // todo support all fields
      const { uuid, title, mainImageUrl, price, soldCount, categories } = product;

      const translatedCategories = categories.map(el => {
        if (el.type.case === 'rootCategory') {
          return { title: el.type.value.title, uuid: el.type.value.uuid };
        } else {
          return {
            categoryId: el.type.value.categoryUuid,
            title: el.type.value.title,
            uuid: el.type.value.uuid,
          };
        }
      });
      const category = translatedCategories.find((el): el is ICategory => !('categoryId' in el));
      const subCategory = translatedCategories.find((el): el is ISubCategory => 'categoryId' in el);

      return {
        id: uuid,
        productName: title,
        imageUrls: [mainImageUrl],
        price: {
          amount: Number(price.amount),
          discountEnabled: price.discountEnabled,
          discountInPercent: Number(price.discountInPercent),
          oldAmount: Number(price.oldAmount),
        },
        sold: Number(soldCount),
        category: category || null,
        subCategory: subCategory || null,
        enabled: product.enabled,
      };
    });
  },

  fromGetProductDetail(response: OwnerCatalogProductDetailResponse) {
    const product = response.product;
    const selections = product.selections.map(selection => ({
      title: selection.title,
      uuid: selection.uuid,
      visibility: selection.visibility,
    }));

    const categories = product.categories.map(el => {
      if (el.type.case === 'rootCategory') {
        return { title: el.type.value.title, uuid: el.type.value.uuid };
      } else {
        return {
          categoryId: el.type.value.categoryUuid,
          title: el.type.value.title,
          uuid: el.type.value.uuid,
        };
      }
    });

    const category = categories.find((el): el is ICategory => !('categoryId' in el));
    const subCategory = categories.find((el): el is ISubCategory => 'categoryId' in el);

    return {
      ...product,
      selections: selections,
      category: category,
      subCategory: subCategory,
    };
  },

  toOwnerCatalogProductFull(
    data: IProduct,
    deliveryType: DeliveryType,
    uploadedImageUrls: string[],
  ) {
    const {
      id,
      enabled,
      price,
      category,
      subCategory,
      selections,
      productName,
      productDescription,
      linkForOrder,
      linkOrCode,
      imageUrls: productImageUrls,
    } = data;

    const categories: Category[] = [];

    if (category) {
      const productCategory = new Category({
        type: { case: 'rootCategory', value: { title: category.title, uuid: category.uuid } },
      });
      categories.push(productCategory);
    }

    if (subCategory) {
      const productSubCategory = new Category({
        type: {
          case: 'subCategory',
          value: {
            title: subCategory.title,
            uuid: subCategory.uuid,
            categoryUuid: subCategory.categoryId,
          },
        },
      });
      categories.push(productSubCategory);
    }

    let priceEntity;
    if (price.discountEnabled) {
      priceEntity = new Price({
        amount: BigInt(getDiscountedPrice(price.amount, Number(price.discountInPercent))),
        oldAmount: BigInt(price.amount),
        discountEnabled: price.discountEnabled,
        discountInPercent: Number(price.discountInPercent),
      });
    } else {
      priceEntity = new Price({
        amount: BigInt(price.amount),
        oldAmount: BigInt(0),
        discountEnabled: price.discountEnabled,
        discountInPercent: Number(price.discountInPercent),
      });
    }

    let productSelections = [];
    if (selections) {
      productSelections = selections.map(
        selection =>
          new SelectionBlock({
            title: selection.title,
            uuid: selection.uuid,
            visibility: selection.visibility,
          }),
      );
    }
    // const productSelections = selections.map(
    //   selection =>
    //     new SelectionBlock({
    //       title: selection.title,
    //       uuid: selection.uuid,
    //       visibility: selection.visibility,
    //     }),
    // );

    const deliveryActionCase = getDeliveryAction(deliveryType);

    const deliveryAction:
      | {
          value: string;
          case: 'deliveryLink';
        }
      | {
          value: string;
          case: 'deliveryRedeemCode';
        }
      | {
          value: {};
          case: 'deliveryNoop';
        }
      | { case: undefined; value?: undefined } = { case: deliveryActionCase, value: '' };

    if (deliveryActionCase === 'deliveryLink') {
      deliveryAction.value = linkForOrder;
    } else if (deliveryActionCase === 'deliveryRedeemCode') {
      deliveryAction.value = linkOrCode;
    } else if (deliveryActionCase === 'deliveryNoop') {
      deliveryAction.value = {};
    }

    // витрина - delivery_action_link
    // заявка - noop
    // электронная - redeem-code
    // физические - noop

    const product = new OwnerCatalogProductFull({
      uuid: id || '',
      enabled,
      categories: categories,
      title: productName,
      description: productDescription,
      imageUrls: combineImages(uploadedImageUrls, productImageUrls),
      price: priceEntity,
      deliveryAction,
      selections: productSelections,
    });

    return product;
  },

  toProductListByCategory(categories: ICategoryState[]) {
    const rootCategories = categories.filter(
      (el): el is { case: 'rootCategory'; value: RootCategory } => el.case === 'rootCategory',
    );
    let subCategories = categories.filter(
      (el): el is { case: 'subCategory'; value: SubCategory } => el.case === 'subCategory',
    );
    subCategories = subCategories.filter(subCategory => {
      if (
        !rootCategories.find(category => category.value.uuid === subCategory.value.categoryUuid)
      ) {
        return true;
      } else {
        return false;
      }
    });

    const requestData = [...rootCategories, ...subCategories].map(el => new Category({ type: el }));

    return requestData;
  },
};

export const getDiscountedPrice = (price, percentDiscount) => {
  if (!price || !percentDiscount) return 0;
  return Math.round(price * (1 - percentDiscount / 100));
};

function getDeliveryAction(
  deliveryType: DeliveryType,
): 'deliveryLink' | 'deliveryRedeemCode' | 'deliveryNoop' {
  switch (deliveryType) {
    case DeliveryType.DIGITAL:
      return 'deliveryRedeemCode';
    case DeliveryType.SHOWCASE:
      return 'deliveryLink';
    case DeliveryType.PHYSICAL:
      return 'deliveryNoop';
    case DeliveryType.REQUEST:
      return 'deliveryNoop';
    case DeliveryType.UNSPECIFIED:
      return 'deliveryNoop';
  }
}

function combineImages(uploadedImageUrls: string[], productImageUrls: string[]) {
  if (uploadedImageUrls.length > 0 && productImageUrls.length === 0) {
    return uploadedImageUrls;
  }

  if (productImageUrls.length > 0 && uploadedImageUrls.length === 0) {
    return productImageUrls;
  }

  if (uploadedImageUrls.length > 0 && productImageUrls.length > 0) {
    uploadedImageUrls.forEach(uploadedImageUrl => {
      const index = productImageUrls.indexOf('');
      if (index !== -1) {
        productImageUrls.splice(index, 1, uploadedImageUrl);
      }
    });
    return productImageUrls;
  }

  return [];
}
