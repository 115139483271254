import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IStoriesRuleKeys {
  wrapper: {};
  slider: {};
  slide: {};
}

interface IStoriesRuleProps {
  paddingTop;
  paddingBottom;
}

export type IStoriesRules = IRuleFn<IStoriesRuleKeys, IStoriesRuleProps>;

const storiesRuleFn: IStoriesRules = props => {
  const { paddingTop, paddingBottom } = props;

  return {
    wrapper: {
      paddingTop,
      paddingBottom,
    },
    slider: {
      '& .swiper-wrapper': {
        ...padding(0, 20),
      },
    },
    slide: {
      width: 100,
    },
  };
};

export const storiesRules = createCachedStyle(storiesRuleFn);
