import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISortableImageKeys {
  sortableWrapper: {};
  image: {};
  deleteButton: {};
}

interface ISortableImageProps {
  transform: any;
  transition: any;
}

export type ISortableImage = IRuleFn<ISortableImageKeys, ISortableImageProps>;

const SortableImageRuleFn: ISortableImage = props => {
  const { mainColor, colorWhite } = props.theme;
  const { transform, transition } = props;

  return {
    sortableWrapper: {
      position: 'relative',
      ':first-child': {
        '> div': {
          border: `2px solid ${mainColor}`,
        },
      },
    },
    image: {
      touchAction: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      width: 112,
      height: 112,
      borderRadius: 8,
      overflow: 'hidden',
      transform,
      transition,

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },

      // ':first-child': {
      //   border: `2px solid ${mainColor}`,
      // },
    },

    deleteButton: {
      borderRadius: '50%',
      border: 'none',
      outline: 'none',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      right: 4,
      top: 4,
      width: 15,
      height: 15,
      ...padding(2),
      background: colorWhite,
    },
  };
};

export const SortableImageRules = createCachedStyle(SortableImageRuleFn);
