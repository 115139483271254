import { margin, padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalAccentBgKeys {
  header: {};
  modalInner: {};
  templateWrapper: {};
  phoheImg: {};
  templateTextBlock: {};
  templateButton: {};
  templateTitle: {};
}

interface IModalAccentBgProps {
  headerHeight: number;
}

export type IModalAccentBg = IRuleFn<IModalAccentBgKeys, IModalAccentBgProps>;

const ModalAccentBgRuleFn: IModalAccentBg = props => {
  const { sidePadding, colorBlack, mainColor, colorWhite } = props.theme;
  const { headerHeight } = props;

  return {
    modalInner: {
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    templateWrapper: {
      minHeight: 150,
      ...margin(40, 0),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      borderWidth: '1px',
      borderRadius: 8,
      borderStyle: 'solid',
      borderColor: colorBlack,
      backgroundColor: mainColor,
    },
    phoheImg: {
      zIndex: 0,
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      height: '100%',
    },
    templateTextBlock: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      zIndex: 2,
      backgroundColor: mainColor,
      borderRadius: 12,
      width: '67%',
      ...padding(12),
      minHeight: '110px',
      color: colorWhite,

      '::before': {
        content: '""',
        borderRadius: 8,
        display: 'block',
        position: 'absolute',
        inset: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255, 0) 25%, rgba(255,255,255,0.2246393557422969) 50%, rgba(255,255,255,0.2046393557422969) 100%);',
      },
    },
    templateButton: {
      position: 'relative',
      borderRadius: 8,
      ...padding(8),
      textAlign: 'center',
      color: colorWhite,
      // backgroundColor: mainColor,

      '::before': {
        content: '""',
        borderRadius: 8,
        display: 'block',
        position: 'absolute',
        inset: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255, .1) 25%, rgba(255,255,255,0.2246393557422969) 50%, rgba(255,255,255,0.2046393557422969) 100%);',
      },
    },
    templateTitle: {
      marginBottom: 7,
    },
  };
};

export const ModalAccentBgRules = createCachedStyle(ModalAccentBgRuleFn);
