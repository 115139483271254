import { Currency } from '@teleport/schemas-protobuf';
import { useMemo } from 'react';
import { useCurrencyData } from 'src/utils/hooks/useCurrencyData';

const useCurrencySymbol = (selectedCurrency: Currency): string => {
  const currencies = useCurrencyData();

  return useMemo(() => {
    const filteredCurrency = currencies.find(el => `${el.value}` === `${selectedCurrency}`);
    return filteredCurrency?.symbol || '';
  }, [currencies, selectedCurrency]);
};

export default useCurrencySymbol;
