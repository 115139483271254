import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { getStories } from '../api/stories/getStories';
import { IStory } from 'src/types/stories';
import { createStory } from '../api/stories/createStory';
import { editStory } from '../api/stories/editStory';

interface IStoriesSlice {
  stories: IStory[];
  networkStatus: NetworkStatus;
}

const initialState: IStoriesSlice = {
  stories: [],
  networkStatus: NetworkStatus.None,
};

export const storiesSlice = createSlice({
  name: 'Stories',
  initialState,
  reducers: {
    setStories: (state, { payload }: PayloadAction<IStory[]>) => {
      state.stories = payload;
    },
    deleteStoryById: (state, { payload }: PayloadAction<string>) => {
      state.stories = state.stories.filter(el => el.id !== payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(getStories.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getStories.fulfilled, (state, action) => {
      state.stories = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getStories.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });
    builder.addCase(createStory.fulfilled, (state, action) => {
      state.stories.push(action.payload);
    });
    builder.addCase(editStory.fulfilled, (state, action) => {
      const story = action.payload;
      const element = state.stories.find(el => el.id === story.id);
      const index = state.stories.indexOf(element);
      if (index !== -1) {
        // const modifiedArray = [...state.stories];
        // modifiedArray.splice(index, 1, story);
        // state.stories = modifiedArray;

        state.stories.splice(index, 1, story);
      }
    });
  },
});

export const { deleteStoryById, setStories } = storiesSlice.actions;

export const storiesState = (state: RootState) => state[storiesSlice.name];
