import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

const useColorName = () => {
  const { t } = useTranslation();

  // Define a mapping of hex codes to translated color names
  const standardColorsNames: Record<string, string> = {
    '#3390ec': t('storeSetupPage.cyan'),
    '#0500FF': t('storeSetupPage.blue'),
    '#A48B66': t('storeSetupPage.brown'),
    '#962E2E': t('storeSetupPage.red'),
    '#226620': t('storeSetupPage.green'),
  };

  const getColorName = (hexCode: string): string => {
    return standardColorsNames[hexCode] || hexCode;
  };

  return getColorName;
};

export default useColorName;
