import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { SettingProductModifierRules } from './SettingProductModifier.style';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { IModifier, ModifierType } from 'src/types/modificator';
import { Input } from 'src/components/UI/Input/Input';

interface IProps {
  modifier?: IModifier;
  endSetting: () => void;
}

export const SettingProductModifier: FC<IProps> = memo(function SettingProductModifier(props) {
  const { modifier, endSetting } = props;
  const { css } = useStyle(SettingProductModifierRules);
  const BackButton = useBackButton();
  const [modifierData, setModifierData] = useState<IModifier>(modifier);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      endSetting();
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, endSetting]);

  const changeActiveModifier = event => {
    const checked = event.currentTarget.checked;
    setModifierData({ ...modifierData, active: checked });
  };

  const changeActiveModifierItem = event => {
    const checked = event.currentTarget.checked;
    const itemId = event.currentTarget.id;
    const newItemsState = modifierData.content.find(el => el.id === itemId);
    newItemsState.active = checked;
    setModifierData({ ...modifierData });
  };

  const changeAffectPriceItem = event => {
    const value = event.currentTarget.value;

    if (modifierData.type === ModifierType.TOGGLER) {
      modifierData.affectPriceValue = value;
    } else {
      const itemId = event.currentTarget.id;
      const newItemsState = modifierData.content.find(el => el.id === itemId);
      newItemsState.affectPrice = value;
    }

    setModifierData({ ...modifierData });
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={modifierData.name}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
            extend={css.modifierName}
          />
          <Toggler
            name="modificator-active"
            value="active"
            checked={modifierData.active}
            onChange={changeActiveModifier}
          />
        </header>
      }
      footer={
        <Footer>
          <Button text="Сохранить" propsStyles={{ width: '100%' }} />
        </Footer>
      }
    >
      {/* <button onClick={endSetting} style={{ position: 'absolute', left: '10px', top: '10px' }}>
        Назад
      </button> */}
      <Container extend={css.container}>
        {modifierData.affectPrice && modifierData.type !== ModifierType.TOGGLER && (
          <Text
            mod="text"
            text="Укажите, на сколько увеличится стоимость товара в выбранном варианте"
            fontSize={14}
            fontWeight={600}
            lineHeight={16}
            extend={css.supportText}
          />
        )}
        <div className={css.modifierItemsWrapper}>
          {modifierData.type === ModifierType.TOGGLER ? (
            modifierData.affectPrice ? (
              <Input
                controlled={true}
                label="Влияет на цену"
                value={modifierData.affectPriceValue}
                onChange={changeAffectPriceItem}
                type="number"
                name={`affect-${modifierData.id}`}
                symbol="₽"
              />
            ) : null
          ) : (
            modifierData.content?.map(el => (
              <div key={el.id} className={css.modifierItem}>
                <Toggler
                  name={el.id}
                  value={el.value}
                  checked={el.active}
                  id={el.id}
                  onChange={changeActiveModifierItem}
                />{' '}
                <Text mod="title" text={el.text} fontSize={18} lineHeight={18} />
                {modifierData.affectPrice && (
                  <div className={css.affectPrice}>
                    <Input
                      controlled={true}
                      value={el.affectPrice}
                      onChange={changeAffectPriceItem}
                      type="number"
                      name={`affect-${el.id}`}
                      id={el.id}
                      symbol="₽"
                    />
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      </Container>
    </Layout>
  );
});
