import { IRuleFn } from '../../utils/theme/fela.interface';
import { createCachedStyle } from '../../utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface IContainerRuleKeys {
  root: {};
}

export type IContainerRules = IRuleFn<IContainerRuleKeys>;

const containerRuleFn: IContainerRules = props => {
  const {} = props.theme;

  return {
    root: {
      width: '100%',
      ...padding(0, 16),
    },
  };
};

export const containerRules = createCachedStyle(containerRuleFn);
