import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IWizardProductTypeRuleKeys {
  wrapperForList: {};
}

export type IWizardProductTypeRules = IRuleFn<IWizardProductTypeRuleKeys>;

const WizardProductTypeRuleFn: IWizardProductTypeRules = props => {
  const {} = props.theme;

  return {
    wrapperForList: {
      display: 'flex',
      rowGap: 8,
      flexDirection: 'column',
    },
  };
};

export const WizardProductTypeRules = createCachedStyle(WizardProductTypeRuleFn);
