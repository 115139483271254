import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ITextBlockCardKeys {
  accentColorText: {};
  card: {};
  title: {};
  link: {};
  linkWrap: {};
  iconLink: {};
}

// interface ITextBlockCardProps {}

export type ITextBlockCard = IRuleFn<ITextBlockCardKeys>;

const TextBlockCardRuleFn: ITextBlockCard = props => {
  const { mainColor, colorWhite, colorRgbBlack } = props.theme;
  const {} = props;

  return {
    accentColorText: {
      color: mainColor,
      marginBottom: 8,
    },
    card: {
      ...padding(12, 16),
      backgroundColor: colorWhite,
      borderRadius: 12,
    },
    title: {
      marginBottom: '4px',
    },

    linkWrap: {
      marginTop: 8,
      display: 'flex',
      columnGap: '4px',
      color: `rgba(${colorRgbBlack}, .5)`,
    },
    iconLink: {
      width: 16,
      height: 16,
      fill: `rgba(${colorRgbBlack}, .5)`,
    },
    link: {
      wordWrap: 'anywhere',
    },
  };
};

export const TextBlockCardRules = createCachedStyle(TextBlockCardRuleFn);
