import { FC, memo } from 'react';
import { Icon } from '../UI/Icon/Icon';
import { ProductsCard } from '../ProductsCard/ProductsCard';
import { useStyle } from 'src/utils/theme/useStyle';
import { ProductsListRules } from './ProductsList.style';
import { IProduct } from 'src/types/product';

interface IProps {
  notationText?: string;
  data: IProduct[];
}

export const ProductsList: FC<IProps> = memo(function ProductsList(props) {
  const { data, notationText } = props;
  const { css } = useStyle(ProductsListRules);

  return (
    <div>
      {notationText && (
        <div className={css.notation}>
          <Icon name={'info'} />
          <p>{notationText}</p>
        </div>
      )}
      <div className={css.grid}>
        {data.map(el => {
          return (
            <ProductsCard
              // isThird={i !== 0 && (i + 1) % 3 === 0}
              isActive={false}
              key={el.id}
              product={el as unknown as IProduct} // todo fixme
            />
          );
        })}
      </div>
    </div>
  );
});
