import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface IUserSlice {
  isAuthorized: boolean;
  userName: string;
}

const initialState: IUserSlice = {
  isAuthorized: false,
  userName: '',
};

// todo Тут будет экшн для получения токена

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setIsAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    },
    setUserName: (state, action: PayloadAction<string>) => {
      state.userName = action.payload;
    },
  },
});

export const { setIsAuthorized, setUserName } = userSlice.actions;

export const userState = (state: RootState) => state[userSlice.name];
