import { createAppAsyncThunk } from 'src/hooks/redux';
import { IRecomendation } from 'src/redux/slices/recomendationSlice';

export const recommendationReorder = createAppAsyncThunk(
  'recomendation/recommendationReorder',
  async (recomendations: IRecomendation[], thunkAPI) => {
    const requestData = recomendations.map(el => el.id);
    await thunkAPI.extra.portOwnerApi().recommendationReorder({ recommendationUuids: requestData });
  },
);
