import { createAppAsyncThunk } from 'src/hooks/redux';
import { RecomendationTranslator } from 'src/redux/translators/recomendationTranslator';

export const getRecomendations = createAppAsyncThunk(
  'recomendation/getRecomendations',
  async (_, thunkAPI) => {
    const result = await thunkAPI.extra.portOwnerApi().recommendationList({});
    return RecomendationTranslator.fromGetRecomendation(result);
  },
);
