import { WizardSaveStateRequest } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const wizardSaveState = createAppAsyncThunk(
  'wizard/wizardSaveState',
  async (
    {
      requestData,
      shopLogoFile,
      welcomeImageFile,
    }: { requestData: WizardSaveStateRequest; shopLogoFile?: File; welcomeImageFile?: File },
    thunkAPI,
  ) => {
    if (shopLogoFile) {
      try {
        const imageUrls = await thunkAPI.extra.filesUpload([shopLogoFile]);
        const shopLogoUrl = imageUrls[0];
        requestData.state.step1.logo = shopLogoUrl;
      } catch (e) {
        console.info('Upload files failed: ', e);
      }
    }
    if (welcomeImageFile) {
      try {
        const imageUrls = await thunkAPI.extra.filesUpload([welcomeImageFile]);
        const welcomeImageUrl = imageUrls[0];
        requestData.state.step9.welcomeImage = welcomeImageUrl;
      } catch (e) {
        console.info('Upload files failed: ', e);
      }
    }

    const result = await thunkAPI.extra.hubApi.wizardSaveState(requestData);

    return { wizardData: requestData.state, committed: result.committed };
  },
);
