import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { addPx } from 'src/utils/applyCSSProperty ';

export interface IButtonRuleKeys {
  button: {};
  text: {};
  checkIcon: {};
}

interface IButtonRuleProps {
  active?: boolean;
  width: string | number;
  height: string | number;
  padding: string | number;
  background: string;
  color: string;
  fontSize?: string;
  disabled: boolean;
  hasGradient: boolean;
  successfully?: boolean;
  transparencyInDisabledState?: boolean;
}

export type IButtonRules = IRuleFn<IButtonRuleKeys, IButtonRuleProps>;

const buttonRuleFn: IButtonRules = props => {
  const { colorWhite, mainColor, colorGreen } = props.theme;
  const {
    width,
    height,
    background,
    padding,
    color,
    active,
    disabled,
    fontSize,
    hasGradient,
    successfully,
    transparencyInDisabledState = true,
  } = props;

  let backgroundColor;

  if (active) {
    backgroundColor = mainColor;
  } else if (successfully) {
    backgroundColor = colorGreen;
  } else if (background) {
    backgroundColor = background;
  } else {
    backgroundColor = mainColor;
  }

  // Если задано значение его нельзя будет переопределить с помощью extand
  return {
    button: {
      position: 'relative',
      padding: padding ?? '22px',
      width: width,
      height: height,
      fontSize: 20,
      display: 'flex',
      columnGap: '8px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: backgroundColor,
      color: color ?? colorWhite,
      border: 'none',
      borderRadius: 12,
      borderColor: 'transparent',
      outline: 'none',
      cursor: 'pointer',
      opacity: disabled && transparencyInDisabledState ? '0.5' : '1',
      pointerEvents: disabled ? 'none' : 'auto',
      transitionProperty: 'background',
      transitionDuration: '.2s',
      transitionTimingFunction: 'linear',

      '::before': {
        content: '""',
        borderRadius: 12,
        display: active || !hasGradient ? 'none' : 'block',
        position: 'absolute',
        inset: 0,
        background:
          'linear-gradient(90deg, rgba(255,255,255, .1) 25%, rgba(255,255,255,0.2246393557422969) 50%, rgba(255,255,255,0.2046393557422969) 100%);',
      },

      ':active': {
        opacity: 0.85,
      },
    },
    text: {
      fontSize: fontSize ? fontSize : 16,
      lineHeight: addPx(18),
      fontWeight: 700,
      letterSpacing: '-0.4px',
    },
    checkIcon: {
      width: 15,
      height: 15,
      fill: colorWhite,
    },
  };
};

export const buttonRules = createCachedStyle(buttonRuleFn);
