import { FC, memo } from 'react';
import { Step } from 'src/components/Step/Step';
import { useStyle } from 'src/utils/theme/useStyle';
import { WizardProductTypeRules } from './WizardProductType.style';
import { CourseType, EventType, ProductType, ServiceType } from '@teleport/schemas-protobuf';
import { ListStoreFocus } from 'src/components/UI/Lists/ListStoreFocus/ListStoreFocus';
import { StoreFocus } from 'src/types/wizard';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';
import { useProductTypeList } from '../../../components/UI/Lists/ListStoreFocus/useProductTypeList';

interface IProps {
  selectedValue?: ProductType | ServiceType | CourseType | EventType;
  setStoreFocus: (value: StoreFocus) => void;
}

export const WizardProductType: FC<IProps> = memo(function WizardProductType(props) {
  const { selectedValue, setStoreFocus } = props;
  const { css } = useStyle(WizardProductTypeRules);

  const {t} = useTranslation();

  const productTypeList = useProductTypeList();

  const onChangeListValue = value => {
    setStoreFocus(value);
  };

  return (
    <Step
      stepNumber={3}
      title={t('wizardStepProductType.chooseStoreType')}
    >
      <div className={css.wrapperForList}>
        <ListStoreFocus
          title={t('wizardStepProductType.products')}
          name="productType"
          options={productTypeList}
          placeholder={t('wizardStepProductType.chooseFromTheList')}
          onChange={onChangeListValue}
          selectedValue={selectedValue}
        />
        <ListStoreFocus
          title={t('wizardStepProductType.services')}
          name="serviceType"
          options={[]}
          disabled={true}
          placeholder={t('wizardStepProductType.willBeAvailableSoon')}
          soon={true}
        />
        <ListStoreFocus
          name="courseType"
          title={t('wizardStepProductType.courses')}
          options={[]}
          disabled={true}
          placeholder={t('wizardStepProductType.willBeAvailableSoon')}
          soon={true}
        />
        <ListStoreFocus
          name="eventType"
          title={t('wizardStepProductType.eventsAndActivities')}
          options={[]}
          disabled={true}
          placeholder={t('wizardStepProductType.willBeAvailableSoon')}
          soon={true}
        />
      </div>
    </Step>
  );
});
