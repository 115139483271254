import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IRecomendationWrapperKeys {
  wrapper: {};
}

// interface IRecomendationWrapperProps {}

export type IRecomendationWrapper = IRuleFn<IRecomendationWrapperKeys>;

const RecomendationWrapperRuleFn: IRecomendationWrapper = props => {
  const { sidePadding } = props.theme;
  const {} = props;

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      paddingBottom: 8,
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      overflow: 'hidden',
      flexGrow: 1,
    },
  };
};

export const RecomendationWrapperRules = createCachedStyle(RecomendationWrapperRuleFn);
