import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { CategoriesWrapperRules } from './CategoriesWrapper.style';
import {
  DndContext,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { ICategory, reorderCategories, setCategories } from 'src/redux/slices/categorySlice';
import { CategoryCard } from 'src/components/CategoryCard/CategoryCard';
import { useAppDispatch } from 'src/hooks/redux';
// https://docs.dndkit.com/presets/sortable - документация dragAndDrop

interface IProps {
  categories: ICategory[];
}

export const CategoriesWrapper: FC<IProps> = memo(function CategoriesWrapper(props) {
  const { categories } = props;
  const { css } = useStyle(CategoriesWrapperRules);
  const [items, setItems] = useState(categories);
  const isTheFirstRender = useRef(true);
  const dispatch = useAppDispatch()
  const sensors = useSensors(
    useSensor(TouchSensor, {
      // activationConstraint: {
      //   delay: 250,
      //   tolerance: 5,
      // },
    }),
    useSensor(PointerSensor),
  );

  let ts: number | undefined;
  const onTouchStart = (e: TouchEvent) => {
    ts = e.touches[0].clientY;
  };
  const onTouchMove = (e: TouchEvent) => {
    const te = e.changedTouches[0].clientY;
    if (ts < te) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (isTheFirstRender.current) {
      isTheFirstRender.current = false;
      return;
    }
    // need fix! reorder works every time
    dispatch(reorderCategories({rootCategoryUuids: categories.map(cat => `${cat.uuid}`)}));
  }, [dispatch, categories]);
  

  function handleDragStart() {
    document.documentElement.addEventListener('touchstart', onTouchStart, { passive: false });
    document.documentElement.addEventListener('touchmove', onTouchMove, { passive: false });
  }

  // Функция изменяющая порядок элементов в нашем массиве items
  function handleDragEnd(event) {
    document.documentElement.removeEventListener('touchstart', onTouchStart);
    document.documentElement.removeEventListener('touchmove', onTouchMove);
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems(items => {
        const dragEl = items.find(el => el.uuid === active.id);
        const dropEl = items.find(el => el.uuid === over.id);

        const oldIndex = items.indexOf(dragEl);
        const newIndex = items.indexOf(dropEl);

        const newArr = arrayMove(items, oldIndex, newIndex);
        dispatch(setCategories(newArr))
        return newArr;
      });
    }
  }

  const itemsForSortable = items.map(el => {
    return {id: el.uuid, ...el}
  } )

  return (
    <div className={css.wrapper}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={itemsForSortable} strategy={verticalListSortingStrategy}>
          {items.map(category => (
            <CategoryCard key={category.uuid} categoryData={category} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
});
