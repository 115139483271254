import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { SelectionBlock_Visibility } from '@teleport/schemas-protobuf';
import { getRecomendations } from '../api/recomendation/getRecomendations';
import { selectionSettingsGet } from '../api/selections/selectionSettingsGet';
import { updateSimilarProductEnabled } from '../api/recomendation/updateSimilarProductEnabled';
import { recommendationReorder } from '../api/recomendation/recommendationReorder';

export interface IRecomendation {
  id: string;
  title: string;
  visibility?: SelectionBlock_Visibility;
  enabled: boolean;
}

interface IRecomendationSlice {
  recomendations: IRecomendation[];
  similarProductEnabled: boolean;
  networkStatus: NetworkStatus;
  recomendationSettingsNetworkStatus: NetworkStatus;
  recomendationReorderStatus: NetworkStatus;
  similarProductNetworkStatus: NetworkStatus;
}

const initialState: IRecomendationSlice = {
  recomendations: [],
  similarProductEnabled: false,
  networkStatus: NetworkStatus.None,
  recomendationSettingsNetworkStatus: NetworkStatus.None,
  recomendationReorderStatus: NetworkStatus.None,
  similarProductNetworkStatus: NetworkStatus.None,
};

export const recomendationSlice = createSlice({
  name: 'Recomendation',
  initialState,
  reducers: {
    setRecomendation: (state, action: PayloadAction<IRecomendation[]>) => {
      state.recomendations = action.payload;
    },
    setSimilarProductEnabled: (state, action: PayloadAction<boolean>) => {
      state.similarProductEnabled = action.payload;
    },
    resetNetworkStatus: (
      state,
      { payload }: PayloadAction<'recomendationReorderStatus' | 'similarProductNetworkStatus'>,
    ) => {
      state[payload] = NetworkStatus.None;
    },
  },
  extraReducers: builder => {
    builder.addCase(getRecomendations.pending, state => {
      state.networkStatus = NetworkStatus.Loading;
    });
    builder.addCase(getRecomendations.fulfilled, (state, action) => {
      state.recomendations = action.payload;
      state.networkStatus = NetworkStatus.Done;
    });
    builder.addCase(getRecomendations.rejected, state => {
      state.networkStatus = NetworkStatus.Failed;
    });

    builder.addCase(selectionSettingsGet.fulfilled, (state, action) => {
      state.similarProductEnabled =
        action.payload.settings.recommendationSettings.similarProductEnabled;
      state.recomendationSettingsNetworkStatus = NetworkStatus.Done;
    });

    builder.addCase(updateSimilarProductEnabled.pending, state => {
      state.similarProductNetworkStatus = NetworkStatus.Loading;
    });
    builder.addCase(updateSimilarProductEnabled.fulfilled, state => {
      state.similarProductNetworkStatus = NetworkStatus.Done;
    });
    builder.addCase(updateSimilarProductEnabled.rejected, state => {
      state.similarProductNetworkStatus = NetworkStatus.Failed;
    });

    builder.addCase(recommendationReorder.pending, state => {
      state.recomendationReorderStatus = NetworkStatus.Loading;
    });
    builder.addCase(recommendationReorder.fulfilled, state => {
      state.recomendationReorderStatus = NetworkStatus.Done;
    });
    builder.addCase(recommendationReorder.rejected, state => {
      state.recomendationReorderStatus = NetworkStatus.Failed;
    });

    selectionSettingsGet;
  },
});

export const { setRecomendation, setSimilarProductEnabled, resetNetworkStatus } =
  recomendationSlice.actions;

export const recomendationState = (state: RootState) => state[recomendationSlice.name];
