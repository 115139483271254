import { StatusResponse } from '@teleport/schemas-protobuf';

export const UiBlocksTranslator = {
  fromRootUiBlocksListResponse(response: StatusResponse) {
    const { mainPageBlocks } = response;

    return mainPageBlocks.map(mainPageBlock => {
      const { blockData } = mainPageBlock;
      return {
        blockData: blockData
      };
    });
  },
};
