import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingBlocksOrderKeys {
  header: {};
  container: {};
  btnSaved: {};
}

export type ISettingBlocksOrder = IRuleFn<ISettingBlocksOrderKeys>;

const SettingBlocksOrderRuleFn: ISettingBlocksOrder = props => {
  const { colorWhite } = props.theme;
  const {} = props;

  return {
    header: {...padding(16), },
    container: {
      overflow: 'hidden',
      height: '100%',
    },
    btnSaved: {
      '& svg': {
        width: 18,
        height: 18,
        fill: colorWhite,
      },
    },
  };
};

export const settingBlockOrderRules = createCachedStyle(SettingBlocksOrderRuleFn);
