import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ICategoryCardKeys {
  card: {};
  image: {};
  info: {};
  iconLink: {};
  iconBars: {};
  linkWrap: {};
  buttonDrag: {};
}

interface ICategoryCardProps {
  transform: any;
  transition: any;
}

export type ICategoryCard = IRuleFn<ICategoryCardKeys, ICategoryCardProps>;

const CategoryCardRuleFn: ICategoryCard = props => {
  const { colorRgbBlack, colorDarkGrey, colorWhite } = props.theme;
  const { transform, transition } = props;

  return {
    card: {
      touchAction: 'manipulation',
      // touchAction: 'none',
      userSelect: 'none',
      WebkitUserSelect: 'none',
      ...padding(12),
      display: 'flex',
      columnGap: 16,
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colorWhite,
      borderRadius: 12,
      transform,
      transition,
    },
    image: {
      borderRadius: 8,
      objectFit: 'cover',
      width: 70,
      height: 100,
    },
    info: {
      flexShrink: 2,
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    linkWrap: {
      display: 'flex',
      columnGap: '4px',
      color: `rgba(${colorRgbBlack}, .5)`,
    },
    iconLink: {
      width: 16,
      height: 16,
      fill: `rgba(${colorRgbBlack}, .5)`,
    },
    iconBars: {
      flexShrink: 0,
      width: 20,
      height: 20,
      fill: colorDarkGrey,
    },
    buttonDrag: {
      border: 'none',
      background: 'none',
      // width: 30,
      // height: 30,
      outline: 'none',
      touchAction: 'none',
    },
  };
};

export const CategoryCardRules = createCachedStyle(CategoryCardRuleFn);
