import type { PropsWithChildren } from 'react';
import { memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { stepsIndicators } from './stepsIndicator.style';

interface StepsIndicatorProps {
  amount: number;
  activeIndex?: number;
}

const StepsIndicator = memo(function StepsIndicator(props: PropsWithChildren<StepsIndicatorProps>) {
  const { amount, activeIndex = 0 } = props;

  const { css } = useStyle(stepsIndicators);

  const StepsIndicator = [];
  for (let i = 0; i < amount; i++) {
    StepsIndicator.push(i);
  }

  return (
    <div className={css.steps}>
      {StepsIndicator.map(el => (
        <div
          key={el}
          id={`${el}`}
          className={`${css.stepBar} ${activeIndex >= el ? css.stepBarActive : ''}`}
        ></div>
      ))}
    </div>
  );
});

export default StepsIndicator;
