import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface ISettingCategoriesPageKeys {
  header: {};
  container: {};
}

export type ISettingCategoriesPage = IRuleFn<ISettingCategoriesPageKeys>;

const SettingCategoriesPageRuleFn: ISettingCategoriesPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {...padding(16), },
    container: {
      overflow: 'hidden',
      height: '100%',
    },
  };
};

export const SettingCategoriesPageRules = createCachedStyle(SettingCategoriesPageRuleFn);
