import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { CategoriesForProductRules } from './CategoriesForProduct.style';
import { Layout } from 'src/components/Layout/Layout';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import { setInstallingCategories, setProductCategory } from '../../../redux/slices/productSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import useBackButton from 'src/hooks/useBackButton';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { Spinner } from 'src/components/UI/Spinner/Spinner';
import { configState } from 'src/redux/slices/configSlice';
import { categoryState, getRootCategoryList, ICategoryMain } from 'src/redux/slices/categorySlice';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

// interface IProps {}

export const CategoriesForProduct: FC = memo(function CategoriesForProduct(props) {
  const {} = props;
  const { css } = useStyle(CategoriesForProductRules);
  const dispatch = useAppDispatch();
  const BackButton = useBackButton();
  const { categories, networkStatus } = useAppSelector(categoryState);
  const { config } = useAppSelector(configState);
  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(getRootCategoryList());
    }
  }, [dispatch, networkStatus]);

  const finishInstallingCategory = useCallback(() => {
    dispatch(setInstallingCategories(false));
  }, [dispatch]);

  const [category, setCategory] = useState<ICategoryMain | null>(null);

  const { t } = useTranslation()

  const selectCategory = event => {
    const categoryId = event.currentTarget.id;
    const category = categories.find(el => el.uuid === categoryId);

    if (!category) return;

    setCategory(category);

    dispatch(setProductCategory({ title: category.title, uuid: `${category.uuid}` }));
    finishInstallingCategory();
  };

  // const selectSubcategory = event => {
  //   const subcategoryId = event.currentTarget.id;
  //   const subcategory = category.subCategories.find(el => el.uuid === subcategoryId);
  //   dispatch(setProductCategory({ title: category.title, uuid: category.uuid }));
  //   dispatch(
  //     setProductSubcategory({
  //       categoryId: subcategory.categoryId,
  //       uuid: subcategory.uuid,
  //       title: subcategory.title,
  //     }),
  //   );
  //   finishInstallingCategory();
  // };

  useEffect(() => {
    function goBack() {
      finishInstallingCategory();
    }

    function goBackToCategory() {
      setCategory(null);
    }

    if (category) {
      BackButton.onClickCustom(goBackToCategory);
    } else {
      BackButton.onClickCustom(goBack);
    }

    return () => {
      BackButton?.offClickCustom(goBack);
      BackButton?.offClickCustom(goBackToCategory);
    };
  }, [BackButton, finishInstallingCategory, category]);

  return (
    <Layout
      position="fixed"
      top={0}
      left={0}
      zIndex={100}
      header={
        <header className={`${css.header} header`}>
          <Text
            text={
              category
                ? category.title
                : config.enableSubcategories
                  ? t('categoriesForProduct.categoriesAndSubcategories')
                  : t('categoriesForProduct.categories')
            }
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
    >
      {/* <button
        onClick={() => {
          if (category) {
            setCategory(null);
          } else {
            finishInstallingCategory();
          }
        }}
        style={{ position: 'absolute', left: '40px', top: '40px' }}
      >
        Назад
      </button> */}
      <Container>
        <div className={css.wrapper}>
          {networkStatus === NetworkStatus.Loading ? (
            <div className={css.spinnerWrapper}>
              <Spinner />
            </div>
          ) : (
            // : category ? (
            //   category.subCategories.map(el => (
            //     <p className={css.subcategory} key={el.uuid} id={el.uuid} onClick={selectSubcategory}>
            //       {el.title}
            //     </p>
            //   ))
            // )
            categories.map(el => (
              <ListButton
                id={`${el.uuid}`}
                key={`${el.uuid}`}
                title={el.title}
                onClick={selectCategory}
                bg="transparent"
                // placeholder={`${el.subCategories.length} подкатегории`}
                placeholder={''}
              />
            ))
          )}
        </div>
      </Container>
    </Layout>
  );
});
