import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IClientSupportFormKeys {
  wrapper: {};
}


export type IClientSupportForm = IRuleFn<
  IClientSupportFormKeys
>;

const ClientSupportFormRuleFn: IClientSupportForm = () => {

  return {
    wrapper: {
      paddingBottom: 12,
    },
  };
};

export const ClientSupportFormRules = createCachedStyle(ClientSupportFormRuleFn);
