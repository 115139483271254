import { ReactNode, memo } from 'react';
import { useStyle } from '../../utils/theme/useStyle';
import { containerRules } from './container.style';

interface IProps {
  extend?: string;
  children: ReactNode;
}

const Container = function Container(props: IProps) {
  const { extend, children } = props;
  const { css } = useStyle(containerRules);

  return <div className={[css.root, extend].join(' ')}>{children}</div>;
};

export default memo(Container);
