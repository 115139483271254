import { Language } from '../i18n.interface';

export function getTelegramLanguage(): Language {
  const languageCode: Language = window.Telegram.WebApp.initDataUnsafe.user
    ?.language_code as Language;

  if (
    typeof languageCode !== 'undefined' &&
    languageCode &&
    Object.values(Language).includes(languageCode as Language)
  ) {
    return languageCode;
  }
  return Language.en;
}
