import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IBlocksOrderWrapperKeys {
  wrapper: {};
}

// interface IBlocksOrderWrapperProps {}

export type IBlocksOrderWrapper = IRuleFn<IBlocksOrderWrapperKeys>;

const BlocksOrderWrapperRuleFn: IBlocksOrderWrapper = props => {
  const {} = props.theme;
  const {} = props;

  return {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 8,
      paddingBottom: 8,
    },
  };
};

export const BlocksOrderWrapperRules = createCachedStyle(BlocksOrderWrapperRuleFn);
