import { memo } from 'react';

export const NotFoundPage = memo(function NotFoundPage() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <h1 style={{ textAlign: 'center' }}>404 Error</h1>
    </div>
  );
});
