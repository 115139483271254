import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { SettingCreateCategoryPageRules } from './SettingCreateCategoryPage.style';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import useLoader from 'src/hooks/useLoader';
import { Input } from 'src/components/UI/Input/Input';
import { useTheme } from 'src/utils/theme/useTheme';
import Toggler from 'src/components/UI/Toggler/Toggler';
import { categoryState, createRootCategory } from 'src/redux/slices/categorySlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { AppRoutes, RoutePath } from 'src/providers/AppRouter/routeConfig';
import { configState } from 'src/redux/slices/configSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

//СТРАНИЦА СОЗДАНИЯ КАТЕГОРИИ ИЛИ ПОДКАТЕГОРИИ
export const SettingCreateCategoryPage: FC = memo(function SettingCreateCategoryPage(props) {
  const {} = props;
  const { css } = useStyle(SettingCreateCategoryPageRules);
  const BackButton = useBackButton();
  const navigate = useNavigate();
  const { hideLoader } = useLoader();
  const { theme } = useTheme();
  const dispatch = useAppDispatch();
  const { config } = useAppSelector(configState);
  const { networkStatus } = useAppSelector(categoryState);

  const { t } = useTranslation()

  useEffect(() => {
    hideLoader();
  }, [hideLoader]);

  useScrollToTop();
  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const [category, setCategory] = useState({
    title: '',
    subcategory: false,
  });

  const sendCategory = () => {
    if (category) {
      dispatch(
        createRootCategory({
          title: category.title,
          subCategoryEnabled: category.subcategory,
        }),
      ).then(() => {
        // console.log('res', res);

        // todo create subcategory
        // subcategories: category.subcategory ? [] : undefined,
        navigate(RoutePath[AppRoutes.SettingsCategories]);
      });
    }
  };

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text text={t('settingCreateCategoryPage.category')} mod="title" fontWeight={800} fontSize={20} lineHeight={22} />
        </header>
      }
      footer={
        <Footer>
          <Button
            disabled={!category.title || networkStatus === NetworkStatus.Loading}
            text={t('settingCreateCategoryPage.createCategory')}
            propsStyles={{ width: '100%' }}
            onClick={sendCategory}
          />
        </Footer>
      }
    >
      <Container extend={css.container}>
        <div className={css.grid}>
          <Input
            name="category-name"
            type="text"
            label={t('settingCreateCategoryPage.name')}
            controlled={true}
            value={category.title}
            placeholder={t('settingCreateCategoryPage.enterCategoryName')}
            propsStyles={{ backgroundColor: theme.colorWhite }}
            onChange={e =>
              setCategory({
                ...category,
                title: e.target.value,
              })
            }
          />
          {config.enableSubcategories && (
            <div className={css.justifyWrapper}>
              <Text text="Подкатегории" mod="title" fontSize={12} />
              <Toggler
                checked={category.subcategory}
                onChange={e => {
                  setCategory({
                    ...category,
                    subcategory: e.target.checked,
                  });
                }}
                name="show-in-recomendation"
                value="show-in-recomendation"
              />
            </div>
          )}
        </div>
      </Container>
    </Layout>
  );
});
