import { memo, useRef } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { PillRules } from './pill.style';

interface CommonProps {
  extend?: string;
  text: string;
  amount?: number;
  removeFn?: () => void;
  // onChange?: () => void;
}

type ConditionalProps =
  | {
      type: 'radio' | 'checkbox';
      name: string;
      value: string;
      checked: boolean;
      onChange: (e: any) => void;
    }
  | { type: 'block'; name?: never; value?: never; onChange?: never; checked: never };

type IProps = CommonProps & ConditionalProps;

const Pill = function Pill(props: IProps) {
  const { text, name, value, type, amount, checked = false, onChange } = props;
  const { css } = useStyle(PillRules);
  const pillRef = useRef(null);

  const handleChange = event => {
    const isChecked = event.target.checked;
    isChecked;
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <label className={css.pill} ref={pillRef}>
      <input
        type={type}
        checked={checked}
        className={css.input}
        name={name}
        value={value}
        onChange={handleChange}
      />
      <span className={css.text}>{text}</span>
      {(amount === 0 || amount > 0) && <span className={css.amount}>{amount}</span>}
    </label>
  );
};

export default memo(Pill);
