import { FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { PillSoonRules } from './PillSoon.style';
import Text from '../Text/Text';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

export const PillSoon: FC = memo(function PillSoon(props) {
  const {} = props;
  const { css } = useStyle(PillSoonRules);

  const {t} = useTranslation();

  return (
    <div className={css.pill}>
      <Text
        mod="text"
        text={t('pillSoon.soon')}
        fontSize={10}
        lineHeight={10}
        fontWeight={700}
        extend={css.text}
        textTransform='uppercase'
      />
    </div>
  );
});
