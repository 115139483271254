import { SelectionSettingsUpdateRequest } from '@teleport/schemas-protobuf';
import { createAppAsyncThunk } from '../../../hooks/redux';

export const updateSimilarProductEnabled = createAppAsyncThunk(
  'selections/updateSimilarProductEnabled',
  async (value: boolean, thunkAPI) => {
    const requestPayload = new SelectionSettingsUpdateRequest({
      settings: { recommendationSettings: { similarProductEnabled: value } },
    });
    thunkAPI.extra.portOwnerApi().selectionSettingsUpdate(requestPayload);
  },
);
