import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { padding } from 'src/utils/applyCSSProperty ';

export interface ISubcategoryCardRuleKeys {
  card: {};
}

// interface ISubCategoryCardRuleProps {
//   disabled?: boolean;
// }

export type ISubcategoryCardRules = IRuleFn<ISubcategoryCardRuleKeys>;

const SubcategoryCardRulesFn: ISubcategoryCardRules = props => {
  const { background, mainColor } = props.theme;
  const {} = props;

  return {
    card: {
      position: 'relative',
      display: 'block',
      ...padding(16),
      borderRadius: 12,
      backgroundColor: background,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'transparent',

      ':has(input:checked)': {
        borderColor: mainColor,
      },

      '& input': {
        position: 'absolute',
        opacity: 0,
        zIndex: -1,
      },
    },
  };
};

export const subcategoryCardRules = createCachedStyle(SubcategoryCardRulesFn);
