import { FC, memo, ReactElement } from 'react';
import { replaceComponents } from '../helpers/replaceComponents';

interface ITransProps {
  text: string;
  components: ReactElement[];
}

export const Trans: FC<ITransProps> = memo(function Trans(props) {
  const {text, components} = props;

  return (
    <>
      {replaceComponents(text, components)}
    </>
  );
});
