import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalSettingSelectionKeys {
  modalInner: {};
  header: {};
  footer: {};
  removeBtnWrapper: {};
  infoBtn: {};
  justifyWrapper: {};
  title: {};
  wrapper: {};
  info: {};
}

interface IModalSettingSelectionProps {
  headerHeight: number;
  footerHeight: number;
}

export type IModalSettingSelection = IRuleFn<
  IModalSettingSelectionKeys,
  IModalSettingSelectionProps
>;

const ModalSettingSelectionRuleFn: IModalSettingSelection = props => {
  const { sidePadding, colorWhite, colorBlack, colorRgbBlack, mainColor } = props.theme;
  const { headerHeight, footerHeight } = props;

  return {
    modalInner: {
      position: 'relative',
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      paddingBottom: `${footerHeight}px`,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    footer: {
      position: 'fixed',
      backgroundColor: colorWhite,
      left: 0,
      bottom: 0,
      width: '100%',
      ...padding(12,sidePadding, 30),
    },
    removeBtnWrapper: {
      ...padding(8, 0),
    },
    infoBtn: {
      '& svg': {
        width: '100%',
        height: '100%',
        fill: colorBlack,
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 8,
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    wrapper: {
      ...padding(12, 0),
    },
    info: {
      marginTop: 4,
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '16px',
      color: `rgba(${colorRgbBlack}, .4)`,

      '& a': {
        color: mainColor,
      },
    },
  };
};

export const ModalSettingSelectionRules = createCachedStyle(ModalSettingSelectionRuleFn);
