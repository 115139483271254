import { ChangeEvent, FC, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { optionRules } from './option.style';
import { Icon, IconList } from 'src/components/UI/Icon/Icon';
import Text from 'src/components/UI/Text/Text';
import { ListStoreFocusValue } from '../../ListStoreFocus';

interface IProps {
  type: 'radio' | 'checkbox';
  value: ListStoreFocusValue;
  selectedValue: ListStoreFocusValue;
  text: string;
  icon: IconList;
  onChange: (value: string) => void;
}

export const Option: FC<IProps> = memo(function Option(props) {
  const { text, icon, type, value, selectedValue, onChange } = props;
  const { css } = useStyle(optionRules);

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    onChange(value);
  };

  const isChecked = () => {
    return selectedValue === value;
  };

  return (
    <label className={css.option}>
      <input
        className={css.input}
        onChange={changeValue}
        type={type}
        value={value}
        checked={isChecked()}
      />
      <div className={css.optionText}>
        <Icon name={icon} />
        <Text text={text} mod="text" fontSize={16} fontWeight={500} lineHeight={22} />
      </div>

      {isChecked() && (
        <div className={css.check}>
          <Icon name="check" />
        </div>
      )}
    </label>
  );
});
