import { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ImageLoaderRules } from './ImageLoader.style';
import Text from '../Text/Text';
import { Icon } from '../Icon/Icon';
import { useTranslation } from '../../../utils/i18n/hooks/useTranslation';

interface IProps {
  name: string;
  imageUrl?: string;
  message?: string;
  propsStyles: IStyles;
  onChangeFile?: (file: File) => void;
  extend?: string;
  labelText?: string;
}

interface IStyles {
  width: number;
  height: number;
  borderRadius: string;
}

export const ImageLoader: FC<IProps> = memo(function ImageLoader(props) {
  const { imageUrl, name, message, propsStyles, extend, onChangeFile, labelText } = props;
  const { css } = useStyle(ImageLoaderRules, {
    width: propsStyles.width,
    height: propsStyles.height,
    borderRadius: propsStyles.borderRadius,
  });

  const [selectedImgUrl, setSelectedImgUrl] = useState(imageUrl);

  const {t} = useTranslation();

  useEffect(() => {
    setSelectedImgUrl(imageUrl);
  }, [imageUrl]);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    const img = event.target.files[0];
    if (!img) return;
    const url = URL.createObjectURL(img);
    if (onChangeFile) {
      onChangeFile(img);
    }
    setSelectedImgUrl(url);
  }

  return (
    <label htmlFor={name} className={[css.imageLoader, extend].join(' ')}>
      <input
        type="file"
        name={name}
        id={name}
        accept=".png, .jpg, .jpeg, .webp"
        onChange={onChange}
      />
      <div className={css.image} style={{ backgroundImage: `url(${selectedImgUrl})` }}>
        {!selectedImgUrl && <Icon name="plus" />}
      </div>
      <Text
        text={labelText ?? t('imageLoader.uploadImage')}
        mod="text"
        extend={css.labelText}
        fontSize={12}
        lineHeight={12}
        fontWeight={700}
      />
      {message && (
        <Text text={message} mod="text" fontSize={12} lineHeight={14} extend={css.message} />
      )}
    </label>
  );
});
