import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModifiersPageKeys {
  header: {};
  container: {};
  justifyWrapper: {};
  grid: {};
}

export type IModifiersPage = IRuleFn<IModifiersPageKeys>;

const ModifiersPageRuleFn: IModifiersPage = props => {
  const {} = props.theme;
  const {} = props;

  return {
    header: {
      ...padding(16),
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
      alignItems: 'center',
    },
    container: {
      overflow: 'hidden',
    },
    justifyWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    grid: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
  };
};

export const ModifiersPageRules = createCachedStyle(ModifiersPageRuleFn);
