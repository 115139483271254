import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface ILayoutRuleKeys {
  root: {};
  main: {};
}

interface ILayoutRuleProps {
  mainFlexColumn?: boolean;
  padding?: string;
  position?: 'fixed' | 'relative';
  top?: number;
  left?: number;
  zIndex?: number;
}

export type ILayoutRules = IRuleFn<ILayoutRuleKeys, ILayoutRuleProps>;

const layoutRuleFn: ILayoutRules = props => {
  const { appMaxWidth, background } = props.theme;
  const { mainFlexColumn, padding, position, top, left, zIndex } = props;

  return {
    root: {
      maxWidth: appMaxWidth,
      margin: '0 auto',
      background: background,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: position ? position : 'relative',
      height: '100vh',
      overflowY: 'scroll',
      top: `${top}px`,
      left: `${left}px`,
      zIndex: zIndex ? zIndex : 'auto',
      // layot задается фиксированная высота 100vh,
      // это происходит в хуке useAppHeight
    },
    main: {
      flex: '1 0 auto',
      display: mainFlexColumn ? 'flex' : 'block',
      flexDirection: mainFlexColumn ? 'column' : 'none',
      padding,
    },
  };
};

export const layoutRules = createCachedStyle(layoutRuleFn);
