import { IRuleFn } from 'src/utils/theme/fela.interface';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';

export interface IProductsListKeys {
  grid: {};
  notation: {};
}

export type IProductsLists = IRuleFn<IProductsListKeys>;

const ProductsListRuleFn: IProductsLists = props => {
  const { colorBlack, colorDarkGrey } = props.theme;
  return {
    grid: {
      display: 'grid',
      flexDirection: 'column',
      gap: 8,
    },
    notation: {
      marginBottom: '12px',
      display: 'flex',
      gap: 8,
      alignItems: 'flex-start',
      color: colorDarkGrey,
      fontSize: 14,
      lineHeight: '16px',
      fontWeight: '600',
      '& svg': {
        fill: colorBlack,
      },
    },
  };
};

export const ProductsListRules = createCachedStyle(ProductsListRuleFn);
