import { padding } from 'src/utils/applyCSSProperty ';
import { createCachedStyle } from 'src/utils/theme/createCachedStyle';
import { IRuleFn } from 'src/utils/theme/fela.interface';

export interface IModalModifierVariantsKeys {
  modalInner: {};
  header: {};
  inputWrp: {};
  add_modifier: {};
  footer: {};
}

interface IModalModifierVariantsProps {
  headerHeight: number;
}

export type IModalModifierVariants = IRuleFn<
  IModalModifierVariantsKeys,
  IModalModifierVariantsProps
>;

const ModalModifierVariantsRuleFn: IModalModifierVariants = props => {
  const { sidePadding, mainColor } = props.theme;
  const { headerHeight } = props;

  return {
    modalInner: {
      paddingLeft: sidePadding,
      paddingRight: sidePadding,
      maxHeight: `calc(100% - ${headerHeight}px)`,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      paddingBottom: '34px',
    },
    header: {
      ...padding(10, sidePadding, 12),
    },
    inputWrp: {
      ...padding(12, 0),
    },
    add_modifier: {
      fontSize: 12,
      lineHeight: '12px',
      fontWeight: 700,
      textAlign: 'center',
      color: mainColor,
    },
    footer: {
      paddingBottom: 24,
      paddingTop: 20,
    },
  };
};

export const modalModifierTypeRules = createCachedStyle(ModalModifierVariantsRuleFn);
