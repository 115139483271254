import { ReactNode, memo } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { wrapperRules } from './wrapper.style';
import { IClasses } from 'src/utils/theme/fela.interface';

interface IProps {
  propsStyles: IStyles;
  children: ReactNode;
  extend?: IClasses;
}

interface IStyles {
  backgroundColor?: string;
  padding?: string;
  borderRadius: string | number;
}

const Wrapper = function Wrapper(props: IProps) {
  const { propsStyles, children, extend } = props;

  const { css } = useStyle(wrapperRules, {
    backgroundColor: propsStyles.backgroundColor,
    padding: propsStyles.padding,
    borderRadius: propsStyles.borderRadius,
  });

  return <div className={[css.wrapper, extend].join(' ')}>{children}</div>;
};

export default memo(Wrapper);
