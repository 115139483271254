import { FC, memo, useEffect, useMemo, useState } from 'react';
import Footer from 'src/components/Footer/Footer';
import { Layout } from 'src/components/Layout/Layout';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import { useStyle } from 'src/utils/theme/useStyle';
import { storeSetupPageRules } from './StoreSetupPage.style';
import Container from 'src/components/Container/Container';
import { ListButton } from 'src/components/UI/ListButton/ListButton';
import useLoader from 'src/hooks/useLoader';
import { useModalState } from 'src/hooks/useModalState';
import { ModalUniversal } from 'src/components/ModalUniversal/ModalUniversal';
import { getWizardState, setStoreFocus } from 'src/redux/slices/wizardSlice';
import { Option } from 'src/components/UI/Option/Option';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import ModalList from 'src/components/UI/Lists/ListStoreFocus/components/ModalList/ModalList';
import { useNavigate } from 'react-router-dom';
import { ClientSupportForm } from 'src/components/ClientSupportForm/ClientSupportForm';
import { WelcomeMessage } from 'src/components/WelcomeMessage/WelcomeMessage';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { wizardGetState } from 'src/redux/api/wizard/wizardGetState';
import { WizardTranslator } from 'src/redux/translators/wizardTranslator';
import { wizardSaveState } from 'src/redux/api/wizard/wizardSaveState';
import useBackButton from 'src/hooks/useBackButton';
import { useTelegram } from 'src/utils/telegramProvider';
import { MaintenanceEnabledForm } from 'src/components/MaintenanceEnabledForm/MaintenanceEnabledForm';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useProductTypeList } from 'src/components/UI/Lists/ListStoreFocus/useProductTypeList';
import { useCurrencyData } from 'src/utils/hooks/useCurrencyData';
import { useLanguageData } from 'src/utils/hooks/useLanguageData';
import useColorName from 'src/hooks/useColorName';
import ModalError from 'src/components/ModalError/ModalError';
import { WizardSaveStateRequest } from '@teleport/schemas-protobuf';



export const StoreSetupPage: FC = memo(function StoreSetupPage() {
  const { css } = useStyle(storeSetupPageRules);
  const { hideLoader } = useLoader();
  const navigate = useNavigate();
  const BackButton = useBackButton();
  const dispatch = useAppDispatch();
  const { wizard: wizardData, networkStatus } = useAppSelector(getWizardState);
  const Telegram = useTelegram();
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();

  const { t } = useTranslation();

  const products = useProductTypeList();

  const currencies = useCurrencyData();
  
  const languages = useLanguageData();

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(wizardGetState());
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader]);

  useEffect(() => {
    BackButton.onClickCustom(goBack);
    function goBack() {
      navigate('/');
    }
    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  const [renderModalUniversal, activeModalUniversal, openModalUniversal, closeModalUniversal] =
    useModalState();
  const [renderModal, activeModal, openModal, closeModal] = useModalState();

  const closeModalStoreFocusClose = () => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest(
      // 'end',
      { ...wizardData },
      true,
    );

    dispatch(wizardSaveState({ requestData: wizardStateRequest }));
    closeModal();
  };

  const [modalUniversalContentType, setModalUniversalContentType] = useState('');

  const [options, setOptions] = useState([]);
  const [optionsTitle, setOptionsTitle] = useState('');

  const sendLanguageOrCurrency = (wizardStateRequest: WizardSaveStateRequest) => {
    dispatch(wizardSaveState({requestData: wizardStateRequest})).unwrap().then( res => {
      if (res.wizardData) {
        closeModalUniversal();
      }else {
        openErrorModal()
      }
    })
  }

  const onChangeLanguage = value => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest({...wizardData, language: Number(value)}, true);
    sendLanguageOrCurrency(wizardStateRequest)
  };
  const onChangeCurrency = value => {
    const wizardStateRequest = WizardTranslator.toWizardSaveStateRequest({...wizardData, currency: Number(value)}, true);
    sendLanguageOrCurrency(wizardStateRequest)
  };

  const onClickListButton = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    const targetParentId = target.closest('.list-button')?.id;
    if (!target.id && !targetParentId) return;
    const targetId = target.id ? target.id : targetParentId;
    switch (targetId) {
      case 'languages':
        setOptionsTitle(t('storeSetupPage.language'));
        setOptions(languages);
        setModalUniversalContentType('languages');
        openModalUniversal();
        return;
      case 'currency':
        setOptionsTitle(t('storeSetupPage.currency'));
        setOptions(currencies);
        setModalUniversalContentType('currency');
        openModalUniversal();
        return;
      case 'products':
        setOptions(products);
        openModal();
        return;
      case 'color':
        navigate('/select-color');
        return;
      case 'client-support':
        setOptionsTitle(t('storeSetupPage.customerSupport'));
        setModalUniversalContentType('users-support');
        openModalUniversal();
        return;
      case 'welcome-message':
        setOptionsTitle(t('storeSetupPage.welcomeMessage'));
        setModalUniversalContentType('welcome-message');
        openModalUniversal();
        return;
      case 'maintenance-enabled':
        setOptionsTitle(t('storeSetupPage.disablingTheStore'));
        setModalUniversalContentType('maintenance-enabled');
        openModalUniversal();
        return;
      default:
        setOptionsTitle('');
        setOptions([]);
        return;
    }
  };

  const changeStoreFocus = (listValue: string) => {
    dispatch(
      setStoreFocus({
        case: 'productType',
        value: Number(listValue),
      }),
    );
  };

  const connectToSupport = () => {
    Telegram.webApp.openTelegramLink('https://t.me/TeleportHelpBot');
  };

  const closeModalUniversalFuntion = () => {
    closeModalUniversal();
  }

  const getColorName = useColorName()

  const currentCurrency = useMemo(() => {
    const filteredCurrency = currencies.filter(el => `${el.value}` === `${wizardData.currency}`)
    if (filteredCurrency[0] && filteredCurrency[0].value) {
      if (filteredCurrency[0].symbol === 'stars') {
        return 'Stars'
      }
      return filteredCurrency[0].subtitle
    } else {
      return ''
    }
  }, [wizardData.currency, currencies])
  
  const currentLanguage = useMemo(() => {
    const filteredLanguage = languages.filter(el => `${el.value}` === `${wizardData.language}`)
    if (filteredLanguage[0] && filteredLanguage[0].value) {
      return filteredLanguage[0].title
    } else {
      return ''
    }
  }, [wizardData.language, languages])

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('storeSetupPage.storeSettings')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button text={t('storeSetupPage.contactSupport')} onClick={connectToSupport} propsStyles={{ width: '100%' }} />
        </Footer>
      }
    >
      <Container>
        <div className={css.content}>

          <div className={css.grid}>
            <ListButton
              id={'welcome-message'}
              title={t('storeSetupPage.welcomeMessage')}
              placeholder={wizardData.welcomeMessage ?? t('storeSetupPage.welcomeMessage')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={'client-support'}
              title={t('storeSetupPage.customerSupport')}
              placeholder={`${wizardData?.supportLink}`}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={'languages'}
              title={t('storeSetupPage.language')}
              placeholder={currentLanguage}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={'currency'}
              title={t('storeSetupPage.currency')}
              placeholder={currentCurrency}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={'products'}
              title={t('storeSetupPage.products')}
              placeholder={wizardData?.storeFocus?.value? `${products[wizardData?.storeFocus?.value - 1].text}` : ''}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={'color'}
              title={t('storeSetupPage.color')}
              placeholder={wizardData.accentColor ? getColorName(wizardData.accentColor) : t('storeSetupPage.color')}
              onClick={onClickListButton}
              padding="16px"
            />
            <ListButton
              id={'maintenance-enabled'}
              title={t('storeSetupPage.disablingTheStore')}
              placeholder={wizardData.maintenanceEnabled ? t('storeSetupPage.storeDisabled') : t('storeSetupPage.storeEnabled')}
              onClick={onClickListButton}
              padding="16px"
            />
          </div>
        </div>
      </Container>
      {renderModalUniversal && (
        <ModalUniversal
          title={optionsTitle}
          active={activeModalUniversal}
          onClose={closeModalUniversalFuntion}
        >
          <>
            <div className={css.grid}>
              {modalUniversalContentType === 'currency' &&
                options.map(el => (
                  <Option
                    key={el.value}
                    value={el.value}
                    title={el.title}
                    subtitle={el.subtitle}
                    checked={`${wizardData?.currency}` === `${el.value}`}
                    type="radio"
                    symbol={el.symbol}
                    onChange={onChangeCurrency}
                    soon={el.soon}
                    disabled={el.disabled}
                  />
                ))}
              {modalUniversalContentType === 'languages' &&
                options.map(el => {
                  return (
                    <Option
                      key={el.value}
                      value={el.value}
                      title={el.title}
                      subtitle={el.subtitle}
                      checked={`${wizardData?.language}` === `${el.value}`}
                      type="radio"
                      symbol={el.symbol}
                      onChange={onChangeLanguage}
                      soon={el.soon}
                      disabled={el.disabled}
                    />
                  )
                })}
            </div>

            {modalUniversalContentType === 'users-support' && (
              <ClientSupportForm sendRequest={true} closeModal={closeModalUniversal} />
            )}
            {modalUniversalContentType === 'welcome-message' && <WelcomeMessage />}
            {modalUniversalContentType === 'maintenance-enabled' && (
              <MaintenanceEnabledForm onSubmitCallback={closeModalUniversal} />
            )}
          </>
        </ModalUniversal>
      )}

      {renderModal && (
        <ModalList
          options={options}
          selectedValue={wizardData?.storeFocus?.value}
          active={activeModal}
          onClose={closeModalStoreFocusClose}
          onChange={changeStoreFocus}
        />
      )}
      {renderErrorModal && (
        <ModalError
          deep={2}
          onClose={closeErrorModal}
          title={t('storeSetupPage.error')}
          active={activeErrorModal}
        />
      )}
    </Layout>
  );
});
