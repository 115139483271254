import { FC, memo, useEffect, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { Layout } from 'src/components/Layout/Layout';
import Footer from 'src/components/Footer/Footer';
import { Button } from 'src/components/UI/Button/Button';
import Text from 'src/components/UI/Text/Text';
import Container from 'src/components/Container/Container';
import { settingBlockOrderRules } from './SettingBlocksOrder.style';
import useLoader from 'src/hooks/useLoader';
import { BlocksOrderWrapper } from 'src/blocks/settings-main/setting-blocks-order/BlocksOrderWrapper/BlocksOrderWrapper';
import useBackButton from 'src/hooks/useBackButton';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import {
  fetchUiBlocks,
  reorderUiBlocksList,
  resetNetworkStatus,
  uiBlocksState,
} from 'src/redux/slices/uiBlocksSlice';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { NoSettings } from 'src/components/NoSettings/NoSettings';
import { SelectionBlock, TextBlock } from '@teleport/schemas-protobuf';
import { useModalState } from 'src/hooks/useModalState';
import ModalError from 'src/components/ModalError/ModalError';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';

export const SettingBlocksOrder: FC = memo(function SettingsBlockOrder(props) {
  const {} = props;
  const { css } = useStyle(settingBlockOrderRules);
  const { hideLoader } = useLoader();
  const BackButton = useBackButton();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { uiBlocks, networkStatus } = useAppSelector(uiBlocksState);
  const [renderErrorModal, activeErrorModal, openErrorModal, closeErrorModal] = useModalState();
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);

  // fixme Возможно стоит менять состояние слайса uiBlock state,
  // при каком либо изменении в другом элементе настройки, вместо того чтобы каждый раз отправлять запрос
  useEffect(() => {
    return () => {
      dispatch(resetNetworkStatus());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    BackButton.onClickCustom(goBack);

    function goBack() {
      navigate('/');
    }

    return () => {
      BackButton?.offClickCustom(goBack);
    };
  }, [BackButton, navigate]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.None) {
      dispatch(fetchUiBlocks());
    }
    if (networkStatus === NetworkStatus.Done) {
      hideLoader();
    }
  }, [networkStatus, dispatch, hideLoader]);

  function saveChanges() {
    const blocksToSend = uiBlocks.map(el => {
      if (el.blockData.case === 'textBlock') {
        return {
          blockData: {
            case: el.blockData.case,
            value: {
              uuid: el.blockData.value.uuid,
              title: el.blockData.value.title ? el.blockData.value.title : 'FIX ME',
              text: el.blockData.value.text,
              accentColor: el.blockData.value.accentColor,
            } as TextBlock,
          },
        };
      } else if (el.blockData.case === 'selectionBlock') {
        return {
          blockData: {
            case: el.blockData.case,
            value: {
              uuid: el.blockData.value.uuid,
              title: el.blockData.value.title ? el.blockData.value.title : 'FIX ME',
            } as SelectionBlock,
          },
        };
      } else {
        return {
          blockData: el.blockData,
        };
      }
    });

    if (!blocksToSend.length) {
      return;
    }

    dispatch(
      reorderUiBlocksList({
        mainPageBlocks: blocksToSend,
      }),
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        setSavedSuccessfully(true);
        setTimeout(() => {
          setSavedSuccessfully(false);
        }, 3000);
      } else {
        openErrorModal();
      }
    });
  }

  return (
    <Layout
      header={
        <header className={`${css.header} header`}>
          <Text
            text={t('settingsBlocksOrder.blocksOrder')}
            mod="title"
            fontWeight={800}
            fontSize={20}
            lineHeight={22}
          />
        </header>
      }
      footer={
        <Footer>
          <Button
            text={t('settingsBlocksOrder.save')}
            onClick={saveChanges}
            propsStyles={{
              width: '100%',
            }}
            successfully={savedSuccessfully}
            extend={css.btnSaved}
          />
        </Footer>
      }
    >
      <>
        {
          networkStatus === NetworkStatus.Done && (
            <>
              <Container extend={css.container}>
                {uiBlocks.length > 0 ? (
                  <BlocksOrderWrapper blocks={uiBlocks} />
                ) : (
                  <NoSettings title={t('settingsBlocksOrder.noBlocks')} icon="stories-large" />
                )}
              </Container>
              {renderErrorModal && (
                <ModalError onClose={closeErrorModal} title={t('settingsBlocksOrder.error')} active={activeErrorModal} />
              )}
            </>

          )
        }
      </>
    </Layout>
  );
});
