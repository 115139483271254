enum ModifierType {
  TOGGLER = 'toggler',
  LIST = 'list',
  VARIANTS = 'variants',
}

const getModifierTypeName = (modifierType: ModifierType) => {
  switch (modifierType) {
    case ModifierType.LIST:
      return 'Список';
    case ModifierType.TOGGLER:
      return 'Переключатель';
    case ModifierType.VARIANTS:
      return 'Варианты';
  }
};

interface IModifier {
  id: string;
  name: string;
  content?: any[];
  type: ModifierType;
  affectPriceValue?: string;
  affectPrice: boolean;
  active: boolean;
}

export { ModifierType, IModifier, getModifierTypeName };
