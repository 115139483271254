import { FC, memo, useState } from 'react';
import { IClasses } from 'src/utils/theme/fela.interface';
import { useStyle } from 'src/utils/theme/useStyle';
import Text from 'src/components/UI/Text/Text';
import { Button } from 'src/components/UI/Button/Button';
import { useModalState } from 'src/hooks/useModalState';
import ModalList from './components/ModalList/ModalList';
import { listRules } from './list.style';
import { IconList } from '../../Icon/Icon';
import { CourseType, EventType, ProductType, ServiceType } from '@teleport/schemas-protobuf';
import { StoreFocusCases } from 'src/types/wizard';
import { PillSoon } from '../../PillSoon/PillSoon';

interface IProps {
  extend?: IClasses;
  placeholder: string;
  title: string;
  name: StoreFocusCases;
  options: IListStoreFocusOption[];
  disabled?: boolean;
  selectedValue?: ListStoreFocusValue;
  onChange?: (data: { case: StoreFocusCases; value: ListStoreFocusValue }) => void;
  soon?: boolean
}

export type ListStoreFocusValue = ProductType | ServiceType | CourseType | EventType;

export interface IListStoreFocusOption {
  value: ProductType;
  text: string;
  icon: IconList;
}

export const ListStoreFocus: FC<IProps> = memo(function List(props) {
  const {
    extend,
    title,
    name,
    placeholder,
    disabled,
    selectedValue = null,
    options,
    onChange,
    soon
  } = props;
  const { css } = useStyle(listRules, { disabled, soon });
  const [renderModal, activeModal, openModal, closeModal] = useModalState();
  const [value, setValue] = useState<ListStoreFocusValue>(selectedValue);
  const text = value
    ? options[value - 1].text // todo request backend to send enums from zero
    : placeholder;
  const changeValue = (listValue: string) => {
    const value = Number(listValue);
    setValue(value);
    onChange({ case: name, value });
  };

  return (
    <>
      <div className={[css.list, extend].join(' ')} onClick={openModal}>
        <div>
          <Text text={title} mod="title" fontSize={16} lineHeight={18} fontWeight={700} />
          <Text
            text={text}
            mod="text"
            fontSize={12}
            lineHeight={14}
          />
        </div>
        <div className={css.rightBlock}>
          {
            soon ?
              (
                <PillSoon />
              )
              :
              (
                <Button
                  icon="chevron-right"
                  propsStyles={{
                    width: 20,
                    height: 20,
                    padding: 0,
                    background: 'transparent',
                  }}
                  extend={css.btnOpen}
                />
              )
          }
        </div>
      </div>
      {renderModal && (
        <ModalList
          options={options}
          selectedValue={value}
          active={activeModal}
          onClose={closeModal}
          onChange={changeValue}
        />
      )}
    </>
  );
});
